<template>
  <div class="main">
    <div class="zhendi">
      <div class="img">
        <img class="simg" v-if="zdDetail.status==0" src="../../assets/icons/pcindex/bz10@0.png" />
        <img class="simg" v-if="zdDetail.status==1" src="../../assets/icons/pcindex/bz10@1.png" />
        <img class="simg" v-if="zdDetail.status==2" src="../../assets/icons/pcindex/bz10@2.png" />
      </div>
      <div class="title">
        <img class="fanhuiimg" @click="fanhui" src="../../assets/icons/pcindex/b3b4.png" />
        <span class="span-t1">查看备案</span>
      </div>
      <div class="detail">
        <div class="detail-t">{{zdDetail.name}}</div>
        <div class="detail-i">备案人：{{zdDetail.recorderName}}</div>
        <div class="detail-i">备案时间：{{zdDetail.recordTime}}</div>
        <div class="detail-i-m">
          <span class="span-i">*</span>
          备案类型：{{zdDetail.positionType}}
        </div>
        <!-- <div class="detail-i-m">
          <span class="span-i">*</span>
          名称：{{zdDetail.title}}
        </div>-->
        <div class="detail-i-m">
          <span class="span-i">*</span>
          所属平台{{zdDetail.mainBody}}
        </div>
        <div class="detail-i-m" v-for="item in zdDetail.fieldContent" :key="item.id">
          <span v-if="item.isRequired" class="span-i">*</span>
          {{ item.name }}:
          <span v-if="item.type !== 2">{{item.value}}</span>
          <div v-if="item.type === 2">
            <div
              v-for="i in item.value"
              :key="i.uid"
              @click="download(i.url,i.name)"
              class="upload"
            >
              <img class="img" src="../../assets/icons/pcindex/bz12.png" />
              <span>{{i.name}}</span>
            </div>
          </div>
        </div>
      </div>

      <hr class="hr" />

      <div class="shenpi">
        <div class="shenpi-t">审批流程</div>
        <div :key="index" style="display: flex;" v-for="(i,index) in zdDetail.reviewResultVos">
          <div>
            <!-- 非最后节点且状态不是驳回的节点样式-->
            <div
              style="width: 8px;height: 8px;border: 2px solid #286CFB;border-radius: 50%;margin-top: 9px;"
              v-if="i.operation!=2&&index!=(zdDetail.reviewResultVos.length-1)"
            >
              <div
                style="width: 2px;height: 42px;background: #286CFB; margin-top: 10px; margin-left: 1px"
              ></div>
            </div>
            <!-- 非最后节点且驳回的节点样式-->
            <div
              style="width: 8px;height: 8px;border: 2px solid  #F5454E;border-radius: 50%;margin-top: 9px;"
              v-if="i.operation==2&&index!=(zdDetail.reviewResultVos.length-1)"
            ></div>
            <div
              style="width: 2px;height: calc(100% - 25px);background: #286CFB; margin-top: 10px; margin-left: 3px"
              v-if="i.operation==2&&index!=(zdDetail.reviewResultVos.length-1)"
            ></div>
            <!-- 最后节点且驳回的节点样式-->
            <div
              style="width: 8px;height: 8px;border: 4px solid #F5454E;border-radius: 50%;margin-top: 9px;"
              v-if="i.operation==2&&index==(zdDetail.reviewResultVos.length-1)"
            >
              <div
                style="width: 2px;height: 42px;background: #C5C5C5; margin-top: 10px; margin-left: 1px"
              ></div>
            </div>
            <!-- 最后节点且不是驳回的节点样式-->
            <div
              style="width: 8px;height: 8px;border: 4px solid #286CFB;border-radius: 50%;margin-top: 9px;"
              v-if="i.operation!=2&&index==(zdDetail.reviewResultVos.length-1)"
            >
              <div
                style="width: 2px;height: 42px;background: #C5C5C5; margin-top: 10px; margin-left: -1px"
              ></div>
            </div>
          </div>

          <div
            style=" margin-left: 10px"
            v-if="i.operation!=2&&index!=(zdDetail.reviewResultVos.length-1)"
          >
            <div class="spenpizhuangt">{{i.status}}</div>
            <p class="spenpitime">{{ i.updatedAt}}</p>
          </div>
          <div style=" margin-left: 10px" v-if="i.operation==2">
            <div class="spenpizhuangt" style="  color: #FF4224;">{{i.status}}</div>
            <p class="spenpitime">{{ i.updatedAt}}</p>
          </div>
          <div
            style=" margin-left: 10px"
            v-if="i.operation!=2&&index==(zdDetail.reviewResultVos.length-1)"
          >
            <div class="spenpizhuangt" style="  color:#286CFB;">{{i.status}}</div>
            <p class="spenpitime">{{ i.updatedAt}}</p>
          </div>
        </div>
      </div>
      <div class="submit" v-if="zdDetail.status==0">
        <a-button
          class="sbutton"
          @click="dialogFormVisible = true"
          style="background: #FF4224 ; margin-left:264px;"
        >驳回</a-button>
        <a-button
          class="sbutton"
          @click="updateStatus (reviewResultReq,zdDetail.recordTime,1)"
          style="background:#286CFB ; margin-left:24px; "
        >通过</a-button>
      </div>
    </div>

    <el-dialog v-model="dialogFormVisible" title="审批驳回" destroy-on-close>
      <div class="bhnr">
        <span class="dialog-span">请输入驳回原因</span>
        <a-textarea
          class="input-t"
          v-model:value="reviewResultReq.reason"
          show-count
          :maxlength="300"
        />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel" style="width: 88px;height: 32px;">取消</el-button>
          <el-button
            type="primary"
            style="background: #286CFB;width: 88px;height: 32px;"
            @click="updateStatus (reviewResultReq,zdDetail.recordTime,2)"
          >保存</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { useRouter, useRoute } from "vue-router";
import myAxios from "@/api/request";
import { ElMessage } from "element-plus";
import { message } from "ant-design-vue";
import { recordManagerDetailAjax } from "@/api/zdManager";
export default {
  name: "zdBeian",
  components() {},

  setup() {
    const route = useRoute();
    const id = route.params.id;
    const router = useRouter();
    const updateStatus = async (reviewResultReq, recordTime, operation) => {
      if (reviewResultReq.reason == "" && operation == 2) {
        ElMessage({
          message: "请填写驳回原因",
          type: "warning"
        });
        return;
      }
      reviewResultReq.operation = operation;
      reviewResultReq.recordId = id;
      reviewResultReq.recordTime = recordTime;
      const res = await myAxios({
        url: "/positionRecord/updateStatus",
        data: reviewResultReq,
        method: "PUT"
      });
      if (res.data2.code == 200) {
        message.info("审批成功");
        router.back();
      } else {
        message.error(res.data2.msg);
      }
    };
    const fanhui = () => {
      router.back();
    };
    return {
      updateStatus,
      id,
      fanhui
    };
  },
  methods: {
    cancel() {
      this.dialogFormVisible = false;
      this.reviewResultReq.reason = "";
    },

    //文件下载
    download(url, name) {
      this.getBlob(url).then(blob => {
        this.saveAs(blob, name);
      });
    },
    saveAs(blob, filename) {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    },
    //通过文件下载url拿到对应的blob对象
    getBlob(url) {
      return new Promise(resolve => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "blob";
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response);
          }
        };

        xhr.send();
      });
    },
    async getDeatil() {
      const res = await recordManagerDetailAjax(this.id);
      const fieldContent = res.data.fieldContent?.map(ele => {
        if (ele.type === 2) {
          ele.value = JSON.parse(ele.value);
        }
        return ele;
      });
      this.zdDetail = { ...res.data, fieldContent };
    }
  },
  data() {
    return {
      dialogFormVisible: false,
      zdDetail: {},
      reviewResultVos: [],
      reviewResultReq: {
        operation: 0,
        reason: "",
        recordId: 0,
        recordTime: ""
      }
    };
  },
  mounted() {
    this.getDeatil();
    if (this.zdDetail.cate == 0) {
      this.zdDetail.cate = "新闻舆论阵地";
    } else if (this.zdDetail.cate == 1) {
      this.zdDetail.cate = "广告";
    } else if (this.zdDetail.cate == 2) {
      this.zdDetail.cate = "讲座、论坛、报告会、研讨会";
    } else {
      this.zdDetail.cate = "文化场馆、展览展厅、看板标语、指导手册、道旗等";
    }
  }
};
</script>
<style lang="scss" scoped>
.main {
  margin: 0 auto;
  overflow: scroll;
}

@media screen and (min-width: 820px) {
  .main {
    width: 728px;
  }
}

@media screen and (min-width: 960px) {
  .main {
    width: 728px;
  }
}

::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 1200px) {
  .main {
    width: 728px;
  }
}

::v-deep .el-dialog {
  height: 320px;
  --el-dialog-width: 440px;
}
::v-deep .el-dialog__header {
  width: 440px;
  height: 48px;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
}
::v-deep.el-dialog__footer {
  width: 440px;
  height: 56px;
  padding: var(--el-dialog-padding-primary);
  padding-top: 0px;
  text-align: right;
  box-sizing: border-box;
  border-top: 1px solid #d9d9d9;
}
::v-deep .el-dialog__body {
  height: 200px;
  padding: 0px;
}
::v-deep .el-dialog__title {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #262626;
  line-height: 24px;
}
.bhnr {
  margin-top: 20px;
  margin-left: 20px;
  .dialog-span {
    width: 98px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #262626;
    line-height: 20px;
  }
  .input-t {
    width: 400px;
    height: 120px;
    background: #ffffff;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
}

.zhendi {
  width: 728px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 16px;
  padding-top: 24px;
  margin-bottom: 23px;
  justify-content: flex-end;

  .img {
    .simg {
      float: right;
      width: 225px;
      height: 218px;
      margin-right: 72px;
    }
  }

  .title {
    height: 22px;
    display: flex;
    align-items: center;

    .fanhuiimg {
      height: 16px;
      width: 16px;
      margin-left: 16px;
      cursor: pointer;
    }

    .span-t1 {
      width: 64px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2f3238;
      line-height: 22px;
      margin-left: 10px;
    }
  }

  .detail {
    margin-top: 25px;
    margin-left: 41px;
    width: 636px;
    .detail-t {
      margin-left: 5px;
      min-height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
    }

    .detail-i-m {
      .upload {
        cursor: pointer;
        margin-top: 15px;
      }
      .span-i {
        width: 5px;
        height: 22px;
        font-size: 14px;
        font-family: Helvetica;
        color: #f5222d;
        line-height: 22px;
      }

      margin-top: 16px;

      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
    .detail-i {
      margin-left: 5px;
      margin-top: 16px;
      min-height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
  }

  .hr {
    margin-top: 30px;
    margin-left: 20px;
    width: 688px;
    border: 1px solid #dedede;
  }

  .shenpi {
    margin-top: 29px;
    margin-left: 46px;
    width: 688px;
    padding-bottom: 16px;
    .shenpi-t {
      margin-bottom: 16px;
      width: 64px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
    }

    .content {
      .span-c {
        width: 630px;
        display: block;
        min-height: 24px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
        line-height: 24px;
      }

      .span-time {
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 22px;
      }
    }
  }

  .submit {
    margin: auto;
    display: flex;
    align-items: center;

    .sbutton {
      margin-bottom: 24px;
      width: 88px;
      height: 32px;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 22px;
    }
  }
}

::v-deep .el-timeline-item__tail {
  top: 20px;
  position: absolute;
  left: 4px;
  height: calc(100% - 25px);
  border-left: 2px solid #4a75cd;
}

::v-deep .el-timeline-item__node--normal {
  top: 3px;
  left: 0.5px;
  width: 8px;
  height: 8px;
}

::v-deep .el-timeline-item:last-child {
  display: block;
}

::v-deep .ant-message-notice {
  z-index: 1000;
}
.spenpizhuangt {
  display: inline-block;
  width: 620px;
  word-wrap: break-word;
  white-space: normal;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;

  line-height: 24px;
}
.spenpitime {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 22px;
}
</style>