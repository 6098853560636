import { createApp } from "vue";
import ant from "./ant/index";
import vant from "vant";
import "vant/lib/index.css";
import "assets/font/font.scss";
 
import { ConfigProvider } from "vant";
import App from "./App.vue";
import router from "./router/index";
import store from "./store/index";
import infiniteScroll from "vue3-infinite-scroll-good";
 
import vConsole from 'vconsole'
import element from "./element/index";
 
 
import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import "assets/css/element-cover.scss";

import * as ElementPlusIconsVue from "@element-plus/icons-vue";
const origin = window.location.origin.includes('test'); // 目前higroup-ihaier.hilook.com.cn为正式环境
if (origin) {
    // 如果为测试环境
    new vConsole();
}

// new vConsole(); // 注册控制台
// 选人组件
// import JSChoose from '@/components/JSChoose/JSChoose'

// if (isDevelopment) {
//   console.log('程珍1')
//   dcm.init({
//     appId: 'U9y1FXDjeV356yiYfExkP',
//   })
//   console.log('程珍2')
//   getCode()
// } else {
//   //正式环境
//   console.log('程珍3')
//   dcm.init({
//     appId: 'lji-XcK1hOqXfosZMbe_3',
//   })
//   console.log('程珍4')
//   getCode()
// }


const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app
  .use(ElementPlus, {
    locale: zhCn,
  })
  .use(router)
  .use(ant)
  .use(element)
  .use(vant)
  .use(store)
  .use(infiniteScroll)
  .use(ConfigProvider)
 
  .mount("#app");
// 
