<template>
  <div class="main">
    <div class="search">
      <a-input
        class="search-i"
        v-model:value="title"
        style="margin-right :24px"
        placeholder="请输入备案名称"
      ></a-input>
      <div class="search-box">
        <span class="span">按阵地类型查找:&nbsp;&nbsp;</span>
        <el-cascader
          v-model="positionCate"
          ref="cascaderRef"
          popper-class="cascader-popper-class"
          style="width: 240px"
          class="cascader-i"
          :props="{value: 'id', label: 'name', lazy: true, lazyLoad:_nrzdCascaderAjax}"
          placeholder="请选择阵地类型"
        >
          <template #default="{ node, data }">
            <el-tooltip
              v-if="data.name.length > 14"
              class="box-item"
              effect="dark"
              :content="data.name"
              placement="top-start"
            >
              <span class="title">{{ data.name }}</span>
            </el-tooltip>
            <span v-else class="title">{{ data.name }}</span>
          </template>
        </el-cascader>
        <el-button class="sbutton" @click="refreshData(search)">查询</el-button>
      </div>
    </div>
    <div style="border: 1px solid #EBEBEB; width: 862px">
      <el-table :data="records" style="width: 862px">
        <el-table-column prop="title" label="备案名称" :show-overflow-tooltip="true" />
        <el-table-column prop="recorderName" label="备案人" />
        <el-table-column prop="positionType" label="阵地" width="250px" ellipsis>
          <template #default="scope">
            <el-tooltip
              class="table-column-cate"
              effect="dark"
              :content="scope.row.positionType"
              placement="top-start"
            >
              <span class="title">{{ scope.row.positionType }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="recordTime" label="备案时间" />
        <el-table-column fixed="right" label="操作" width="120px">
          <template v-slot:default="scope">
            <el-button link type="primary" @click="detail(scope.row.id)" class="tableOp">查看</el-button>
            <el-button link type="primary" class="tableOp" @click="deleteItem(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <div class="page-wrapper">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[20, 50, 100]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import myAxios from "@/api/request";
import { ElMessageBox } from "element-plus";
import { nrzdCascaderAjax, nrzdThreeCascaderAjax } from "@/api/zdManager";
export default {
  props: {
    list: {
      type: Object
    }
  },
  setup() {
    const router = useRouter();
    const detail = _item => {
      router.push("/nrDeatail/" + _item);
    };
    return {
      detail
    };
  },
  watch: {
    list(newVal) {
      this.allData = newVal;
      this.total = newVal.total;
      this.currentPage = newVal.current;
      this.size = newVal.size;
      this.records = newVal.records;
    }
  },
  data() {
    return {
      current: 1,
      size: 20,
      isAdminPage: 0,
      cate: 0,
      title: "",
      total: 0,
      records: [],
      currentPage: 1,
      positionCate: "",
      options: []
    };
  },
  mounted() {},
  methods: {
    _nrzdCascaderAjax(node, resolve) {
      if (!node.value) {
        nrzdThreeCascaderAjax().then(res => {
          resolve([
            {
              name: "全部",
              id: 0,
              leaf: true
            },
            ...res.data
          ]);
        });
      } else if (!node.data.children || !node.data.children.length) {
        nrzdCascaderAjax({ positionType: node.pathLabels.join("-") }).then(
          res => {
            if (res.data2.code === 200) {
              if (!res.data || !res.data.length) {
                message.warning("该阵地类型没有备案");
                resolve([]);
              } else {
                const data = res.data.map(ele => {
                  ele.leaf = true;
                  return ele;
                });
                resolve(data);
              }
            }
          }
        );
      }
    },
    refreshData() {
      const reg = new RegExp(" / ", "g");
      const text = this.$refs.cascaderRef
        .getCheckedNodes()[0]
        ?.text?.replace(reg, "-");
      const positionCate = text === "全部" ? "" : text;
      this.$emit("refreshData", {
        current: this.current,
        size: this.size,
        title: this.title,
        positionCate
      });
    },
    handleSizeChange(size) {
      this.size = size;
      this.refreshData();
    },
    handleCurrentChange(current) {
      this.current = current;
      this.refreshData();
    },
    deleteItem(item) {
      ElMessageBox.confirm("确认删除么?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          myAxios({
            url: "/contentRecord",
            params: { id: item },
            method: "DELETE"
          }).then(res => {
            message.success("删除成功");
            this.refreshData();
          });
        })
        .catch(() => {
          message.info("已取消删除");
        });
    },
    clearCascader() {
      // this.$refs.cascaderRef.$refs.panel.clearCheckedNodes();
      this.title = "";
      this.positionCate = [0];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/cascader.scss";

@media screen and (min-width: 960px) {
  .main {
    width: 862px;
  }
}

@media screen and (min-width: 1200px) {
  .main {
    width: 862px;
  }
}
.main {
  margin: 0 auto;
}

.search {
  margin-bottom: 20px;
  width: calc(100% - 65px);
  display: flex;
  justify-content: space-between;
  .search-box {
    display: flex;
    align-items: center;
  }
  /deep/.ant-input {
    width: 240px;
    border-radius: 3px;
  }
  /deep/.el-cascader {
    width: 240px;
  }
}

.search-i {
  width: 240px;
  height: 32px;
}

.sbutton {
  width: 60px;
  height: 32px;
  background: #286cfb;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-left: 16px;
}
::v-deep .el-button.is-link:focus,
.el-button.is-link:hover {
  color: var(--el-color-primary);
}
.span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 600;
  color: #666;
  line-height: 22px;
}

.page-wrapper {
  height: 80px;
  background: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 80px;
}
.tableOp {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #286cfb;
  line-height: 22px;
}
.tableOp:focus-visible {
  outline: none;
}
/deep/.el-table .cell .title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
/deep/.cascader-popper-class {
  .el-cascader-node.in-active-path {
    .el-icon {
      color: #286cfb;
    }
  }
  .el-cascader-node:not(.is-disabled):hover {
    background: rgba(40, 108, 251, 0.1);
  }
  .el-cascader-node.is-active {
    .el-icon {
      color: #286cfb;
    }
  }
}
</style>