<template>
  <div>
    <pcIndex />
  </div>
</template>

<script>
import pcIndex  from './pcIndex.vue'
 
 
export default {
  components:{
      pcIndex
  },
  setup(){
    
    
    return {
     
    }
  }
}
</script>

<style>
 .global-btn {
   cursor: pointer;
   position:fixed;
   right: 0;
   bottom: 100px;
   color: #1f1f1f;
   z-index: 9999;
   background-color: aqua;
 }
</style>