<template>
    <div class="zd-manage-container">
        <router-view></router-view>
    </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    setup() {
        return {}
    }
})
</script>
<style lang="scss" scoped>
.zd-manage-container {
    margin: 16px auto;
    overflow: hidden;
    width: 928px;
    background: #ffffff;
    border-radius: 10px;
    padding: 24px 20px;
}
</style>
