<template>
  <div>
    <header-title title="阵地类型管理" />
    <div class="zd-add" v-for="(first, index) in zdObj" :key="`second-${index}`">
      <div class="item">
        <div class="item-label bold">一级阵地名称</div>
        <div class="item-value">
          <!-- (!first.name && (first.change || isSubmit)) -->
          <el-input
            :class="{error: first.change}"
            v-model="first.name"
            placeholder="请输入一级阵地名称"
            maxlength="150"
            show-word-limit
            clearable
            @blur="checkName(first)"
          ></el-input>
          <div class="item-value-expand">
            <div class="btn">
              <div class="text" @click="handleAddSecond(first.id)">二级阵地</div>
              <div class="add"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-for="(second, index) in first.children" :key="`second-${index}`">
        <div class="item">
          <div class="item-label">二级阵地名称</div>
          <div class="item-value">
            <el-input
              :class="{error: second.change}"
              v-model="second.name"
              placeholder="请输入二级阵地名称"
              maxlength="150"
              show-word-limit
              clearable
              @blur="checkNameSecond(second, index)"
            ></el-input>
            <div class="item-value-expand">
              <div class="btn" @click="handleAddThird(index, second.id)">
                <div class="text">三级阵地</div>
                <div class="add"></div>
              </div>
              <div class="btn del-btn" @click="handleDeleteSecond(index, second.id)">
                <div class="text del-text">删除</div>
                <div class="reduce"></div>
              </div>
            </div>
          </div>
        </div>
        <template v-if="second.children && second.children.length">
          <div v-for="(third, idx) in second.children" :key="`thid-${idx}`" class="item">
            <div class="item-label third-item-label">三级阵地名称</div>
            <div class="item-value">
              <el-input
                :class="{error: third.change}"
                v-model="third.name"
                placeholder="请输入三级阵地名称"
                maxlength="150"
                show-word-limit
                clearable
                @blur="checkNameThree(third, index, idx)"
              ></el-input>
              <div class="item-value-expand">
                <div class="btn" @click="handleDeleteThird(index, idx, third.id)">
                  <div class="text">删除</div>
                  <div class="reduce"></div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="zd-add-submit">
      <el-button @click="handleCancel" plain>取消</el-button>
      <el-button
        @click="handleSubmit"
        :loading="loading"
        type="primary"
        style="margin-left: 24px"
      >提交</el-button>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import HeaderTitle from "components/pcComponent/headerBack/index";
import { modalMask } from "@/utils/modalMask.js";
import {
  zdManagerAddAjax,
  zdManagerDetailAjax,
  checkRecordAjax,
  checkNameAjax,
  checkRecordChildAjax
} from "@/api/zdManager";
import { message } from "ant-design-vue";

export default defineComponent({
  components: {
    HeaderTitle
  },
  setup() {
    const formRef = ref("");
    const isSubmit = ref(false);
    const route = useRoute();
    const router = useRouter();
    const id = route.query.id;
    const loading = ref(false);
    const detailList = ref([]);
    const zdObj = ref([
      {
        name: "",
        children: []
      }
    ]);

    function isRename(data) {
      data.forEach((item, index) => {
        for (let i = 0; i < index; i++) {
          if (item.name && data[i].name === item.name) {
            item.change = true;
            data[i].change = true;
          }
        }
        for (let i = index + 1; i < data.length; i++) {
          if (item.name && data[i].name === item.name) {
            item.change = true;
            data[i].change = true;
          }
        }
      });
    }

    function checkName(first) {
      checkNameAjax({
        name: first.name,
        id: route.query?.id
      }).then(res => {
        if (res.data2.code === 423) {
          first.change = true;
          message.warning({
            content: "在同层中已存在重复的阵地类型名称",
            key: "key"
          });
        } else if (first.name) {
          first.change = false;
        }
      });
    }

    function checkNameSecond(second, index) {
      const data = zdObj.value[0].children;
      if (second.name) {
        second.change = false;
      }
      for (let i = 0; i < index; i++) {
        if (second.name && data[i].name === second.name) {
          second.change = true;
          data[i].change = true;
          message.warning({
            content: "在同层中已存在重复的阵地类型名称",
            key: "key"
          });
        } else if (!(!data[i].name && data[i].change)) {
          second.change = false;
          data[i].change = false;
        }
      }
      for (let i = index + 1; i < data.length; i++) {
        if (second.name && data[i].name === second.name) {
          second.change = true;
          data[i].change = true;
          message.warning({
            content: "在同层中已存在重复的阵地类型名称",
            key: "key"
          });
        } else if (!(!data[i].name && data[i].change)) {
          second.change = false;
          data[i].change = false;
        }
      }

      isRename(data);
    }

    function checkNameThree(third, index, idx) {
      const data = zdObj.value[0].children[index].children;
      if (third.name) {
        third.change = false;
      }
      for (let i = 0; i < idx; i++) {
        if (third.name && data[i].name === third.name) {
          third.change = true;
          data[i].change = true;
          message.warning({
            content: "在同层中已存在重复的阵地类型名称",
            key: "key"
          });
        } else if (!(!data[i].name && data[i].change)) {
          third.change = false;
          data[i].change = false;
        }
      }
      for (let i = idx + 1; i < data.length; i++) {
        if (third.name && data[i].name === third.name) {
          third.change = true;
          data[i].change = true;
          message.warning({
            content: "在同层中已存在重复的阵地类型名称",
            key: "key"
          });
        } else if (!(!data[i].name && data[i].change)) {
          third.change = false;
          data[i].change = false;
        }
      }
      isRename(data);
    }

    const _checkRecordAjax = id => {
      return new Promise((resolve, reject) => {
        checkRecordAjax({ id }).then(res => {
          resolve(res);
        });
      });
    };

    //删除时校验其及子级是否有备案
    const _checkRecordChildAjax = id => {
      return new Promise((resolve, reject) => {
        checkRecordChildAjax({ id }).then(res => {
          resolve(res);
        });
      });
    };

    /**
     * 添加阵地二级
     */
    async function handleAddSecond(id) {
      if (route.query.id) {
        const res = await _checkRecordAjax(id);
        if (res.data2.code === 409) {
          modalMask(res.data2.msg);
          return;
        }
      }

      if (zdObj.value[0].children?.length >= 30) {
        modalMask("子级阵地类型数量不能超过30");
        return;
      }

      if (zdObj.value[0].children) {
        zdObj.value[0].children.push({
          name: "",
          children: []
        });
      } else {
        zdObj.value[0].children = [
          {
            name: "",
            children: []
          }
        ];
      }
    }

    /**
     * 添加阵地三级
     */
    async function handleAddThird(index, id) {
      if (route.query.id) {
        const res = await _checkRecordAjax(id);
        if (res.data2.code === 409) {
          modalMask(res.data2.msg);
          return;
        }
      }
      if (zdObj.value[0].children[index].children?.length >= 30) {
        modalMask("子级阵地类型数量不能超过30");
        return;
      }
      if (zdObj.value[0].children[index].children) {
        zdObj.value[0].children[index].children.push({
          name: "",
          children: []
        });
      } else {
        zdObj.value[0].children[index].children = [
          {
            name: "",
            children: []
          }
        ];
      }
    }

    /**
     * 删除阵地二级
     */
    async function handleDeleteSecond(index, id) {
      if (route.query.id) {
        const res = await _checkRecordChildAjax(id);
        if (res.data2.code === 409) {
          modalMask(res.data2.msg);
          return;
        }
      }
      zdObj.value[0].children.splice(index, 1);
    }

    /**
     * 删除阵地三级
     */
    async function handleDeleteThird(sIndex, tIndex, id) {
      if (route.query.id) {
        const res = await _checkRecordChildAjax(id);
        if (res.data2.code === 409) {
          modalMask(res.data2.msg);
          return;
        }
      }
      zdObj.value[0].children[sIndex].children.splice(tIndex, 1);
    }

    const getData = () => {
      zdManagerDetailAjax(id).then(res => {
        detailList.value = [JSON.parse(JSON.stringify(res.data))];
        zdObj.value = [res.data];
      });
    };

    /**
     * cancel
     */
    const handleCancel = () => {
      router.back();
    };

    /**
     * submit
     */
    //校验是否需要提交
    function isSaveSubmit(data) {
      data.forEach((item, index) => {
        if (!item.name) {
          isSubmit.value = true;
          item.change = true;
        }
        if (item.change) {
          isSubmit.value = true;
        }
        if (item.children && item.children.length) {
          isSaveSubmit(item.children);
        }
      });
    }
    const handleSubmit = () => {
      isSubmit.value = false;
      if (!zdObj.value[0].name || zdObj.value[0].change) {
        zdObj.value[0].change = true;
        isSubmit.value = true;
      }
      isSaveSubmit(zdObj.value[0].children);
      if (!isSubmit.value) {
        loading.value = true;
        const params = {
          newTypeReqList: zdObj.value,
          oldTypeReqList: id ? detailList.value : []
        };
        zdManagerAddAjax(params)
          .then(res => {
            if (res.data2.code === 200) {
              message.success("保存成功");
              router.back();
            }
            loading.value = false;
          })
          .catch(() => {
            loading.value = false;
          });
      }
    };

    onMounted(() => {
      if (id) getData();
    });
    return {
      formRef,
      zdObj,
      isSubmit,
      loading,
      getData,
      handleAddSecond,
      handleAddThird,
      handleDeleteSecond,
      handleDeleteThird,
      handleCancel,
      handleSubmit,
      checkName,
      checkNameSecond,
      checkNameThree
    };
  }
});
</script>
<style lang="scss" scoped>
.zd-add {
  margin: 30px 100px;

  .item {
    margin-bottom: 24px;
    min-height: 32px;
    display: flex;

    &-label {
      position: relative;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
      line-height: 32px;
      margin-right: 24px;

      &:before {
        content: "*";
        color: #f5222d;
        position: absolute;
        top: -4px;
        left: -12px;
      }
    }

    .bold {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
    }

    &-value {
      flex: 1;

      .error {
        /deep/ .el-input__wrapper {
          border: #f5222d 1px solid;
          box-shadow: none;
        }
      }

      &-expand {
        margin-top: 10px;
        float: right;
        display: flex;

        .btn {
          padding: 5px 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #dcdcdc;
          cursor: pointer;
          margin-left: 8px;
          border-radius: 3px;

          .text {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.4);
            margin-right: 2px;
          }

          .add {
            width: 16px;
            height: 16px;
            background: url("./images/add.png") no-repeat;
            background-size: 100% 100%;
          }

          .reduce {
            width: 16px;
            height: 16px;
            background: url("./images/reduce.png") no-repeat;
            background-size: 100% 100%;
          }

          &:hover {
            border: 1px solid #286cfb;
            .text {
              color: #286cfb;
            }
            .del-text {
              color: #ff4224;
            }
            .add {
              width: 16px;
              height: 16px;
              background: url("./images/add_hover.png") no-repeat;
              background-size: 100% 100%;
            }

            .reduce {
              width: 16px;
              height: 16px;
              background: url("./images/reduce_hover.png") no-repeat;
              background-size: 100% 100%;
            }
          }
        }
        .del-btn:hover {
          border: 1px solid #ff4224;
        }
      }
    }
  }
  .third-item-label {
    color: rgba(0, 0, 0, 0.4);
  }
}

.zd-add-submit {
  margin-top: 34px;
  display: flex;
  justify-content: center;
  .el-button {
    width: 88px;
  }
}
</style>
<style>
@import "~assets/css/public.scss";
</style>