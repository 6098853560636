<template>
  <div class="main">
    <div class="search">
      <a-input
        class="search-i"
        style="margin-right :24px"
        v-model:value="name"
        placeholder="请输入备案名称"
      ></a-input>
      <div class="search-box">
        <span class="span">按照审核状态查找:&nbsp;&nbsp;</span>
        <el-select v-model="status" popper-class="m-2" placeholder="请选择">
          <el-option value label="请选择"></el-option>
          <el-option :value="0" label="审核中"></el-option>
          <el-option :value="1" label="备案通过"></el-option>
          <el-option :value="2" label="备案驳回"></el-option>
        </el-select>
        <!-- <a-select
          class="search-i"
          ref="select"
          placeholder="请选择"
          style=" margin-right: 16px;"
          v-model:value="status"
        >
          <a-select-option value>请选择</a-select-option>
          <a-select-option :value="0">审核中</a-select-option>
          <a-select-option :value="1">备案通过</a-select-option>
          <a-select-option :value="2">备案驳回</a-select-option>
        </a-select>-->
        <span class="span">按阵地类型查找:&nbsp;&nbsp;</span>
        <el-cascader
          ref="cascaderRef"
          v-model="positionCate"
          popper-class="cascader-popper-class"
          :options="options"
          :props="{value: 'id', label: 'name'}"
          placeholder="请选择阵地类型"
        >
          <template #default="{ node, data }">
            <el-tooltip
              v-if="data.name.length > 14"
              class="box-item"
              effect="dark"
              :content="data.name"
              placement="top-start"
            >
              <span class="title">{{ data.name }}</span>
            </el-tooltip>
            <span v-else class="title">{{ data.name }}</span>
          </template>
        </el-cascader>
        <el-button class="sbutton" @click="refreshData()">查询</el-button>
      </div>
    </div>
    <div style="border: 1px solid #EBEBEB; width: 862px">
      <el-table :data="records" style="width: 862px">
        <el-table-column prop="name" label="备案名称" min-width="20%" :show-overflow-tooltip="true" />
        <el-table-column :prop="status" label="备案状态" width="90px">
          <template v-slot:default="scope">
            <div v-html="scope.row.status"></div>
          </template>
        </el-table-column>
        <el-table-column
          prop="mainBody"
          label="所属平台"
          min-width="20%"
          :show-overflow-tooltip="true"
        />
        <el-table-column prop="recorderName" label="备案人" min-width="20%" />
        <el-table-column prop="positionType" label="阵地" min-width="20%">
          <template #default="scope">
            <el-tooltip
              class="table-column-cate"
              effect="dark"
              :content="scope.row.positionType"
              placement="top-start"
            >
              <span class="title">{{ scope.row.positionType }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="recordTime" label="备案时间" min-width="27%" />
        <el-table-column fixed="right" label="操作" width="80px">
          <template v-slot:default="scope">
            <div @click="spdetail(scope.row)">
              <span class="span-op">查看</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <div class="page-wrapper">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[20, 50, 100]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import myAxios from "@/api/request";
import { zdManagerListAjax } from "@/api/zdManager";
export default {
  props: {
    list: {
      type: Object
    }
  },
  setup() {
    const router = useRouter();
    const spdetail = _item => {
      router.push("/zdspDetail/" + _item.id);
    };
    return {
      spdetail
    };
  },
  data() {
    return {
      name: null,
      current: 1,
      size: 20,
      total: 0,
      records: [],
      currentPage: 1,
      status: null,
      positionCate: "",
      options: []
    };
  },
  watch: {
    list(newVal) {
      this.allData = newVal;
      this.total = newVal?.total;
      this.currentPage = newVal?.current;
      this.size = newVal?.size;
      newVal?.records?.forEach(item => {
        if (item.status == 0) {
          item.status = "<span style='color:#FB9C00'>审批中</span>";
        } else if (item.status == 1) {
          item.status = "<span style='color:#00AE30'>备案通过</span>";
        } else {
          item.status = "<span style='color:#FF4224'>备案驳回</span>";
        }
      });
      this.records = newVal?.records;
    }
  },
  mounted() {
    this._zdManagerListAjax();
  },
  methods: {
    _zdManagerListAjax() {
      zdManagerListAjax().then(res => {
        if (res.data2.code === 200) {
          this.options = [
            {
              name: "全部",
              id: 0
            },
            ...res.data
          ];
        }
      });
    },
    refreshData() {
      const reg = new RegExp(" / ", "g");
      const text = this.$refs.cascaderRef
        .getCheckedNodes()[0]
        ?.text?.replace(reg, "-");
      const positionCate = text === "全部" ? "" : text;
      this.$emit("refreshData", {
        current: this.current,
        size: this.size,
        status: this.status,
        name: this.name,
        positionCate
      });
    },
    handleSizeChange(size) {
      this.size = size;
      this.refreshData();
    },
    handleCurrentChange(current) {
      this.current = current;
      this.refreshData();
    },
    deleteItem(item) {
      myAxios({
        url: "/positionRecord",
        params: { id: item },
        method: "DELETE"
      }).then(res => {
        message.info("删除成功");
        this.refreshData();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/cascader.scss";

@media screen and (min-width: 960px) {
  .main {
    width: 862px;
  }
}

@media screen and (min-width: 1200px) {
  .main {
    width: 862px;
  }
}

.main {
  margin: 0 auto;
}
::v-deep .el-button.is-link:focus,
.el-button.is-link:hover {
  color: var(--el-color-primary);
}
.search {
  height: 32px;
  margin-bottom: 20px;
  width: calc(100% - 65px);
  display: flex;
  justify-content: space-between;
  .search-box {
    display: flex;
    align-items: center;
  }
  /deep/.ant-input {
    width: 152px;
    border-radius: 3px;
  }
  /deep/.ant-select {
    width: 152px;
    .ant-select-selector {
      border-radius: 3px;
    }
  }
  /deep/.el-cascader {
    width: 152px;
  }
}
/deep/.el-select {
  width: 152px;
  margin-right: 16px;
}
.search-i {
  width: 240px;
  height: 32px;
}
.m-2 {
  .el-select-dropdown__item.selected {
    color: #286cfb;
  }
}
.span-op {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #286cfb;
  line-height: 22px;
  cursor: pointer;
}
.sbutton {
  width: 60px;
  height: 32px;
  background: #286cfb;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-left: 16px;
}

.span {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 22px;
}

.page-wrapper {
  height: 80px;
  background: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 80px;
}

.tableOp {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #286cfb;
  line-height: 22px;
}
/deep/.el-table .cell .title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
/deep/.cascader-popper-class {
  .el-cascader-node.in-active-path {
    .el-icon {
      color: #286cfb;
    }
  }
  .el-cascader-node:not(.is-disabled):hover {
    background: rgba(40, 108, 251, 0.1);
  }
  .el-cascader-node.is-active {
    .el-icon {
      color: #286cfb;
    }
  }
}
</style>