import myAxios from "./request";
// 创建期刊提交
export function submit(data) {
  return myAxios({
    url: "/journal",
    method: "post",
    data,
  });
}
// 通过主键查询单条数据
export function queryRecords(id) {
  return myAxios({
    url: `/journal/${id}`,
    method: "GET",
  });
}

//审批期刊
export function processJournal(data) {
  return myAxios({
    url: "/journal/processJournal",
    method: "PUT",
    data,
  });
}

//审批期刊
export function list(params) {
  return myAxios({
    url: "/journal/processList",
    method: "GET",
    params,
  });
}

// 根据管理主体返回默认的党支部
export function getDepartmentByManagement(params) {
  return myAxios({
    url: "/journal/getDepartmentByManagement",
    method: "GET",
    params,
  });
}

// 主管单位
export function getQueryManagementList() {
  return myAxios({
    url: "/journal/queryManagementList",
    method: "GET",
  });
}

// 主办部门
export function getQueryPartyBranchList() {
  return myAxios({
    url: "/journal/queryPartyBranchList",
    method: "GET",
  });
}

// 面象对象列表
export function getQueryFaceObjectList() {
  return myAxios({
    url: "/journal/queryFaceObjectList",
    method: "GET",
  });
}
// 获取当前用户信息
export function getQueryUserInfo() {
  return myAxios({
    url: "/journal/queryUserInfo",
    method: "GET",
  });
}

// 获取上个版本期刊
export function lastVersionJournal(params) {
  return myAxios({
    url: "/journalLibrary/queryLastVersionJournal",
    method: "get",
    params,
  });
}

//期刊审批详情
export function details(id) {
  return myAxios({
    url: `/journal/processJournal/${id}`,
    method: "GET",
  });
}
