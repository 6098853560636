<template>
  <div class="header-back">
    <div class="header-back-icon" @click="handleBack"></div>
    <div class="header-back-text">{{title}}</div>
    <div v-if="titleDes" class="header-back-text-des">{{titleDes}}</div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "标题"
    },
    titleDes: {
      type: String,
      default: ""
    }
  },
  setup() {
    const router = useRouter();
    const handleBack = () => {
      router.back();
    };
    return {
      handleBack
    };
  }
});
</script>
<style lang="scss" scoped>
.header-back {
  height: 22px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &-icon {
    width: 16px;
    height: 16px;
    background: url("./images/back.png") no-repeat;
    background-size: contain;
  }

  &-text {
    margin-left: 10px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #2f3238;
    line-height: 22px;
    height: 22px;
    font-weight: 500;
    font-size: 16px;
  }
  &-text-des {
    margin-left: 10px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: rgba(0, 0, 0, 0.4);
    font-weight: 400;
    font-size: 14px;
  }
}
</style>
