<template>
    <div>
        <div id="AC1" class="helpS">

        </div>

        <div id="AC" class="helpbox active noactive helpboxNone">
            <div class="helpWrap">
                <div class="helpLeft">
                    <p id="helpLeft">
                        <img class=" handleBackHelpImg" src="@/assets/img/help/helpArrow.asset.svg" />
                    </p>
                    <p id="helpbtn">评价反馈</p>

                </div>
                <div class="helpRight" id="helpRight">
                    <p>
                        <img src="@/assets/img/help/helpDp.asset.svg" />
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { dcmOpenNewContainer } from '@/utils/Ihaier';

const handleJumpHelp = () => {
    document.getElementById("AC").className = "helpbox noactive";
    const origin = window.location.origin.includes('record.lanbenzi.cn')
    let _url = ''
    if (origin) {
        _url = 'https://ihaier-helpcenter.haier.net/helpevaluate/index?map=%7B%22%E5%B8%AE%E5%8A%A9%E4%B8%AD%E5%BF%83%22:%5B%22in%22,%22bzzx%22%5D%7D&applyCode=S03284&sceneCode=230321523718'
    } else {
        _url = 'https://vipportal-test.haier.net/helpevaluate/index?map=%7B%22%E5%B8%AE%E5%8A%A9%E4%B8%AD%E5%BF%83%22:%5B%22in%22,%22bzzx%22%5D%7D&applyCode=S03284&sceneCode=230321976301'
    }
    dcmOpenNewContainer({ url: _url })

};

const tick = ref();
// window.onload = () => {
// addEventListener("load", function () { 
onMounted(() => {
    // console.log("env----", process.env.NODE_ENV)
    document.getElementById("helpRight").onclick = () => {
        document.getElementById("AC").className = "helpbox active";
    }
    document.getElementById("helpLeft").onclick = () => {
        clearTimeout(tick.value)
        document.getElementById("AC").className = "helpbox noactive";
        setTimeout(() => {
            document.getElementById("AC1").className = "helpS";
            document.getElementById("AC").className = "helpbox active noactive helpboxNone";
        }, 600)
    }
    document.getElementById("helpbtn").onclick = () => {
        clearTimeout(tick.value)
        handleJumpHelp()
    }
    document.getElementById("AC1").onclick = () => {
        document.getElementById("AC1").className = "helpS helpSNone";
        document.getElementById("AC").className = "helpbox active";
        tick.value = setTimeout(() => {
            document.getElementById("AC").className = "helpbox noactive";

        }, 3000)
        setTimeout(() => {
            document.getElementById("AC1").className = "helpS";
            document.getElementById("AC").className = "helpbox active noactive helpboxNone";
        }, 3600)
    }
})
// }


</script>

<style scoped>
.helpS {
    display: block;
    position: fixed;
    left: -8px;
    bottom: 29%;
    z-index: 300;
    zoom: 1;
    width: 38px;
    height: 68px;
    background-image: url(../assets/img/help/3.png);
    background-size: 100% 100%;
    /* background: linear-gradient(180deg, rgba(0, 117, 255, 0.06) 0%, rgba(0, 0, 0, 0.04) 100%); */
}

.helpSNone {
    display: none;
}

.helpboxNone {
    display: none;
}

.helpbox {
    position: fixed;
    left: -100px;
    bottom: 30%;
    z-index: 300;
    zoom: 1;
}

.helpWrap {
    overflow: hidden;
    font-size: 0;
    background: #fff;
    box-shadow: 4px 0 20px rgba(0, 0, 0, 0.16);
    border-radius: 0 22px 22px 0;
    cursor: pointer;
}

.helpLeft {
    float: left;
    padding: 14px 0;
    width: 100px;
    font-size: 0;
}

.helpRight {
    float: left;
    padding: 6px 4px 4px 2px;
}

.helpLeft img,
.helpLeft p {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
}

.helpLeft p {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
}

.helpLeft img {
    margin: 0 6px;
}

.helpRight p {
    display: block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.04);
    text-align: center;
}

.helpRight img {
    width: 24px;
    height: 24px;
    margin-top: 6px;
}

.active {
    animation-name: move_right;
    animation-duration: 0.6s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.noactive {
    animation-name: move_left;
    animation-duration: 0.6s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes move_right {
    from {
        left: -100px;
    }

    to {
        left: 0px;
    }
}

@keyframes move_left {
    from {
        left: 0;
    }

    to {
        left: -100px;
    }
}

.helpbox img {
    display: inline !important;
}

.handleBackHelpImg {
    display: inline !important;
    width: 18px;
    height: 18px;
}

.demo {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    color: #000;
}
</style>