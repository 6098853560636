<template>
  <el-config-provider :locale="zhLocale">

    <router-view v-if="islogin"></router-view>
  </el-config-provider>
</template>
<script>
import { defineComponent, computed, onMounted } from 'vue';
import zhLocale from 'element-plus/es/locale/lang/zh-cn';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import dcmBus from './utils/dcm-hooks.js';
import { isPc, isIHaier, isHb, isInWeb } from './utils/platform';
import 'choose-contacts/dist/style.css'
import { getToken } from './utils/Ihaier.js';

export default defineComponent({
  name: 'App',
  components: {},
  setup() {
    const router = useRouter();
    const islogin = computed(() => store.state.islogin);
    // 我的应用
    const appId = window.origin.includes("-test")
      ? "3dyvMf_ebQeYIF2qNXOB2" // 测试
      : "HNugSV9C1hKQBjScaq9xO"; // 正式
    // const appId = "N6-PAiMZCxb48imOGahCW";

    // iHaier web登录

    const route = useRoute();
    const store = useStore();


    onMounted(() => {
      console.log(2222, '111')
      document.body.style.setProperty("--el-color-primary", "#286cfb");

      if (!isIHaier) {
        dcmBus.init(appId);
        dcmBus.getCookies();
        getToken();
      } else {
        console.log(22222, window)
        getToken();
      }
    });
    return {
      zhLocale,
      islogin,
      route
    };
  }
});
</script>
 

<style>
/* @import url("./assets/css/common.css"); */
@import "./assets/css/common.css";
</style>
