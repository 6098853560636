import axios from 'axios';
import { message } from "ant-design-vue";
import { isIHaier } from '@/utils/platform';
import { iHaierLogin } from '@/utils/Ihaier';
import { getTokenByIhaier } from './ihaier';
import store from '@/store';
export default function myAxios(options) {
  const token = sessionStorage.getItem('token');
  console.log(token, 'token')
  const defaultOptions = {
    baseURL: '/api',
    timeout: 20000,
    headers: {
      Authorization:
        'Bearer ' + token
    }
  };
  const newOptions = { ...options, ...defaultOptions };
  return axios.request(newOptions)
    .then(response => { return checkStatus(response, newOptions) })
    .catch(error => console.log(error));
}



function checkStatus(response, newOptions) {
  console.log("response", response)
  console.log("newOptions1", newOptions)
  if (response && (response.data.code == 203)) {

    store.commit('islogin', false)
    window.location.reload()
    // 刷新token的函数,这需要添加一个开关， 防止重复请求
    return refreshTokenRequst().then(res => {
      console.log('refreshTokenRequst')
      localStorage.setItem('token', res.data.token)
      localStorage.setItem('userInfo', JSON.stringify(res.data))
      localStorage.setItem('isAdmin', res.data.isAdmin)
      console.log('刷新token', res)
    
     
      newOptions.headers = {
        Authorization:
          'Bearer ' + res.data.token

      }
      console.log("newOptions2", newOptions)
      onAccessTokenFetched();
      //  token刷新后，需要执行之前缓存的请求
      return axios.request(newOptions).then(res => {
        console.log(res)
        return { 'data': res.data.data, 'data2': res.data }
      })
    })


  } else {
    if (response.data.code == '500' || response.data.code == '501' || response.data.code == '502') {
      message.error('内部服务器错误，刷新后重试')
    } else if (response.data.code !== 200 && response.data.code !== 405 && response.data.code !== 423 && response.data.code !== 409) {
      message.error(response.data.msg)
   
      store.commit('isloading', false)

    }
    return { 'data': response.data.data, 'data2': response.data }
  }
}
export function refreshTokenRequst() {
  return new Promise((resolve) => {
    if (isIHaier) {
      if (sessionStorage.getItem('token')) {

 
        getTokenByIhaier({ iHaierToken: localStorage.getItem('iHaierToken') }).then(

          (userRes) => {
            resolve(userRes)

          },
         
        );
      } else {
          iHaierLogin().then((res) => {
          console.log('token没过期重新请求', res);
          getTokenByIhaier({ iHaierToken: res.token }).then(

            (userRes) => {
              resolve(userRes)
  
            },
           
          );
      });
      }

      return;
    }
    window.dcm.getCookie({
      onSuccess: (data) => {
        const code = data.SESSION
        console.log('换code次数', data)
        axios.request({
          baseURL: '/api',
          url: '/user/login',
          method: "GET",
          params: { code }
        }).then(response => {
          console.log('请求登录接口', response.data, response)
          resolve(response.data)
        })
      }
    })
  })
}
let subscribers = [];
function onAccessTokenFetched() {
  console.log('执行缓存的调用', subscribers)
  subscribers.forEach((callback) => {
    console.log('执行之后的回调', callback)
    callback();
  })
  subscribers = [];
}



