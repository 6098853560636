<template>
  <div class="watchDaily-wrapper">
    <div class="main">
      <van-empty description="该备案已删除" v-if="code==420" />
      <div v-if="code==200 && !loading">
        <van-nav-bar class="h5title" title="查看备案" />
        <div class="watchDaily-wrapper-body">
          <van-cell style="margin-top:10px ">
            <template #title>
              <div class="mainname">
                <div class="custom-title h5name">{{zdDetail.name}}</div>
                <div class="h5tag">
                  <van-tag type="danger" style=" float: right;" v-if="zdDetail.status==2">审批驳回</van-tag>
                  <van-tag type="success" style=" float: right;" v-if="zdDetail.status==1">审批通过</van-tag>
                  <van-tag type="warning" style=" float: right;" v-if="zdDetail.status==0">审批中</van-tag>
                </div>
              </div>
            </template>
          </van-cell>
          <van-cell-group class="basic">
            <van-cell>
              <template #value>
                <div>
                  <span class="title">备案人</span>
                  <span class="content">{{zdDetail.recorderName}}</span>
                </div>
              </template>
            </van-cell>
            <van-cell>
              <template #value>
                <div>
                  <span class="title">备案时间</span>
                  <span class="content">{{zdDetail.recordTime}}</span>
                </div>
              </template>
            </van-cell>
            <van-cell>
              <template #value>
                <div>
                  <span class="title">备案类型</span>
                  <span class="content">{{zdDetail.positionType}}</span>
                </div>
              </template>
            </van-cell>
            <van-cell>
              <template #value>
                <div>
                  <span class="title">所属平台</span>
                  <span class="span-i">*</span>
                  <span class="content">{{zdDetail.mainBody}}</span>
                </div>
              </template>
            </van-cell>
          </van-cell-group>
          <van-cell-group class="basic" style="margin-top:10px ">
            <van-cell v-for="item in zdDetail.fieldContent" :key="item.id">
              <template v-if="item.type !== 2" #value>
                <div>
                  <span class="title">{{ item.name }}</span>
                  <span v-if="item.isRequired" class="span-i">*</span>
                  <span class="content">{{item.value}}</span>
                </div>
              </template>
              <template v-if="item.type === 2" #value>
                <div>
                  <span class="title">{{ item.name }}</span>
                  <span v-if="item.isRequired" class="span-i">*</span>
                  <div
                    v-for="i in item.value"
                    :key="i.uid"
                    @click="download(i.url,i.name)"
                    class="upload"
                  >
                    <img class="img" src="../../assets/icons/pcindex/bz12.png" />
                    <span>{{i.name}}</span>
                  </div>
                </div>
              </template>
            </van-cell>
          </van-cell-group>
          <van-cell-group class="basic" style="margin-top:10px ">
            <van-cell>
              <template #value>
                <div
                  :key="index"
                  style="display: flex;"
                  v-for="(i,index) in zdDetail.reviewResultVos"
                >
                  <div>
                    <!-- 非最后节点且状态不是驳回的节点样式-->
                    <div
                      style="width: 8px;height: 8px;border: 2px solid #286CFB;border-radius: 50%;margin-top: 9px;"
                      v-if="i.operation!=2&&index!=(zdDetail.reviewResultVos.length-1)"
                    >
                      <div
                        style="width: 2px;height: 42px;background: #286CFB; margin-top: 14px; margin-left: 1px"
                      ></div>
                    </div>
                    <!-- 非最后节点且驳回的节点样式-->
                    <div
                      style="width: 8px;height: 8px;border: 2px solid  #F5454E;border-radius: 50%;margin-top: 9px;"
                      v-if="i.operation==2&&index!=(zdDetail.reviewResultVos.length-1)"
                    ></div>
                    <div
                      style="width: 2px;height: calc(100% - 25px);background: #286CFB; margin-top: 14px; margin-left: 3px"
                      v-if="i.operation==2&&index!=(zdDetail.reviewResultVos.length-1)"
                    ></div>
                    <!-- 最后节点且驳回的节点样式-->
                    <div
                      style="width: 8px;height: 8px;border: 4px solid #F5454E;border-radius: 50%;margin-top: 9px;"
                      v-if="i.operation==2&&index==(zdDetail.reviewResultVos.length-1)"
                    >
                      <div
                        style="width: 2px;height: 42px;background: #C5C5C5; margin-top: 14px; margin-left: 1px"
                      ></div>
                    </div>
                    <!-- 最后节点且不是驳回的节点样式-->
                    <div
                      style="width: 8px;height: 8px;border: 4px solid #286CFB;border-radius: 50%;margin-top: 9px;"
                      v-if="i.operation!=2&&index==(zdDetail.reviewResultVos.length-1)"
                    >
                      <div
                        style="width: 2px;height: 42px;background: #C5C5C5; margin-top: 14px; margin-left: -1px"
                      ></div>
                    </div>
                  </div>
                  <div
                    style=" margin-left: 10px"
                    v-if="i.operation!=2&&index!=(zdDetail.reviewResultVos.length-1)"
                  >
                    <div class="spenpizhuangt">{{i.status}}</div>
                    <p class="spenpitime">{{ i.updatedAt}}</p>
                  </div>
                  <div style=" margin-left: 10px" v-if="i.operation==2">
                    <div class="spenpizhuangt" style="  color: #FF4224;">{{i.status}}</div>
                    <p class="spenpitime">{{ i.updatedAt}}</p>
                  </div>
                  <div
                    style=" margin-left: 10px"
                    v-if="i.operation!=2&&index==(zdDetail.reviewResultVos.length-1)"
                  >
                    <div class="spenpizhuangt">{{i.status}}</div>
                    <p class="spenpitime">{{ i.updatedAt}}</p>
                  </div>
                </div>
              </template>
            </van-cell>
          </van-cell-group>
          <div>
            <van-sticky :offset-bottom="0" position="bottom" v-if="status==0">
              <van-cell-group class="basic" style="margin-top:1px ">
                <van-cell>
                  <template #value>
                    <div
                      style="display:flex;flex-direction:row;justify-content:center;align-items:center;"
                    >
                      <van-button
                        round
                        style="margin-right: 12px;width: 45%"
                        type="default"
                        @click="show=true"
                      >驳回</van-button>
                      <van-button
                        round
                        type="success"
                        style="width: 45% ;background: #286CFB"
                        @click="updateStatus (reviewResultReq,zdDetail.recordTime,1,id)"
                      >通过</van-button>
                    </div>
                  </template>
                </van-cell>
              </van-cell-group>
            </van-sticky>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--审批驳回框-->
  <van-dialog v-model:show="show" title="审批驳回" :showConfirmButton="false">
    <van-field
      style="margin-bottom: 10px"
      v-model="reviewResultReq.reason"
      rows="2"
      label="驳回原因"
      type="textarea"
      maxlength="300"
      placeholder="请输入驳回原因"
      show-word-limit
    />
    <div
      style="display:flex;flex-direction:row;justify-content:center;align-items:center;margin-bottom: 10px;"
    >
      <van-button
        square
        type="default"
        style="margin-right: 12px ;border-radius: 4px;width: 88px;height: 32px; "
        @click="cancel"
      >取消</van-button>
      <van-button
        square
        type="primary"
        style="border-radius: 4px;width: 88px;height: 32px;"
        @click="updateStatus (reviewResultReq,zdDetail.recordTime,2,id)"
      >保存</van-button>
    </div>
  </van-dialog>
</template>
<script>
import { useRoute, useRouter } from "vue-router";
import myAxios from "@/api/request";
import { message } from "ant-design-vue";
import { ref } from "vue";
import { ElMessage } from "element-plus";
import { recordManagerDetailAjax } from "@/api/zdManager";

export default {
  name: "h5zhendi",
  components: {},
  setup(props, { emit }) {
    const show = ref(false);

    const route = useRoute();
    const id = route.params.id;
    const router = useRouter();

    return {
      id,
      show
    };
  },
  methods: {
    async updateStatus(reviewResultReq, recordTime, operation, id) {
      if (reviewResultReq.reason == "" && operation == 2) {
        ElMessage({
          message: "请填写驳回原因",
          type: "warning"
        });
        return;
      }

      reviewResultReq.operation = operation;
      reviewResultReq.recordId = id;
      reviewResultReq.recordTime = recordTime;
      const res = await myAxios({
        url: "/positionRecord/updateStatus",
        data: reviewResultReq,
        method: "PUT"
      });
      console.log(res);
      if (res.data2.code == 200) {
        message.info("审批成功");
        this.$router.push("/hzhendiDetail/" + this.id);
      } else {
        ElMessage.error(res.data2.msg);
      }
    },
    //文件下载
    download(url, name) {
      this.getBlob(url).then(blob => {
        this.saveAs(blob, name);
      });
    },
    saveAs(blob, filename) {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    },
    //通过文件下载url拿到对应的blob对象
    getBlob(url) {
      return new Promise(resolve => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "blob";
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response);
          }
        };

        xhr.send();
      });
    },
    async getDeatil() {
      this.loading = true
      const res = await recordManagerDetailAjax(this.$route.params.id);
      this.code = res.data2.code;
      if (this.code == 200) {
        const fieldContent = res.data.fieldContent?.map(ele => {
          if (ele.type === 2) {
            ele.value = JSON.parse(ele.value);
          }
          return ele;
        });
        this.zdDetail = { ...res.data, fieldContent };
        this.reviewResultVos = res.data.reviewResultVos;
        this.active = this.reviewResultVos.length - 1;
        this.status = this.zdDetail.status;
      }
      this.loading = false
    },
    // async getDeatil() {
    //   const res = await myAxios({
    //     url: "/positionRecord/" + this.id,
    //     method: "get"
    //   });
    //   console.log(res);
    //   this.code = res.data2.code;
    //   if (this.code == 200) {
    //     this.zdDetail = res.data;
    //     this.reviewResultVos = res.data.reviewResultVos;
    //     this.active = this.reviewResultVos.length - 1;
    //     this.status = this.zdDetail.status;
    //   }

    //   console.log(res.data2.code);
    // },
    cancel() {
      this.show = false;
      this.reviewResultReq.reason = "";
    }
  },
  data() {
    return {
      code: 200,
      active: 0,
      status: 0,
      zdDetail: {},
      loading: true,
      reviewResultVos: [],
      reviewResultReq: {
        operation: 0,
        reason: "",
        recordId: 0,
        recordTime: ""
      }
    };
  },
  mounted() {
    this.getDeatil();
  }
};
</script>
<style lang="scss" scoped>
//右侧
.main {
  overflow: auto;

  &-operate {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    height: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 16px;
    margin-bottom: 5px;
    background-color: #ffffff;
  }
  .h5title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 25px;
  }

  .mainname {
    display: flex;

    .h5name {
      width: 80%;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 22px;
    }

    .h5tag {
      width: 20%;
    }
  }

  .basic {
    .upload {
      cursor: pointer;
      .img {
        width: 28px;
        height: 29px;
      }
    }

    .span-i {
      font-size: 14px;
      font-family: Helvetica;
      color: #f5222d;
      line-height: 22px;
    }

    .title {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      width: 20%;
    }

    .content {
      margin-left: 10px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }

    .title {
      width: 25%;
    }
  }
  .watchDaily-wrapper-body {
    overflow-y: auto;
    height: calc(100vh - 46px);
  }
}
::v-deep .van-field__control {
  height: 100px;
}
.spenpizhuangt {
  display: inline-block;
  width: 298px;
  word-wrap: break-word;
  white-space: normal;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;

  line-height: 24px;
}
.spenpitime {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 22px;
}
</style>