<template>
  <div class="zd-manager-container">
    <header-title title="阵地类型管理" />
    <div class="operate">
      <div class="operate-left">
        <el-input class="operate-left-input" v-model="keyword" placeholder="请输入阵地名称" />
        <el-button @click="getData" type="primary">查询</el-button>
      </div>
      <div class="operate-right">
        <el-button @click="handleAddType" type="primary">新增类型</el-button>
        <el-button @click="handleSort" type="primary" class="operate-right-btn">阵地排序</el-button>
      </div>
    </div>
    <div v-loading="loading" class="loading-class">
      <div v-if="list.length">
        <div class="zd-card" v-for="firstItem in list" :key="firstItem.id">
          <div class="zd-card-header">
            <div class="zd-card-header-title">
              <div class="zd-card-header-title-main">{{firstItem.name}}</div>
              <div class="zd-card-header-title-sub">（一级阵地类型）</div>
            </div>
            <div class="zd-card-header-operate">
              <div
                v-if="!firstItem.children || !firstItem.children.length"
                class="zd-card-header-operate-btn"
                @click="handleManage(firstItem)"
              >
                <div class="zd-card-header-operate-btn-text manager-text">管理</div>
                <div class="zd-card-header-operate-btn-manager"></div>
              </div>
              <div class="zd-card-header-operate-btn" @click="handleEdit(firstItem)">
                <div class="zd-card-header-operate-btn-text edit-text">编辑</div>
                <div class="zd-card-header-operate-btn-edit"></div>
              </div>
              <div class="zd-card-header-operate-btn" @click="handleDelete(firstItem)">
                <div class="zd-card-header-operate-btn-text delete-text">删除</div>
                <div class="zd-card-header-operate-btn-delete"></div>
              </div>
            </div>
          </div>
          <div v-if="firstItem.children && firstItem.children.length" class="zd-card-container">
            <div class="second-list list font-light">二级阵地类型</div>
            <div class="third-list list font-light">三级阵地类型</div>
            <div class="list zd-card-container-operate font-light">操作</div>
          </div>
          <div
            class="zd-card-container"
            v-for="secondItem in firstItem.children"
            :key="secondItem.id"
          >
            <div class="second-list list">
              {{
              secondItem.name}}
            </div>
            <template v-if="secondItem.children && secondItem.children.length">
              <div style="flex: 1">
                <div class="third" v-for="thirdItem in secondItem.children" :key="thirdItem.id">
                  <div class="third-list list">{{thirdItem.name}}</div>
                  <div class="list zd-card-container-operate">
                    <div class="zd-card-container-operate-text" @click="handleManage(thirdItem)">管理</div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="third-list list"></div>
              <div class="list zd-card-container-operate">
                <div class="zd-card-container-operate-text" @click="handleManage(secondItem)">管理</div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div v-if="!loading && !list.length" class="empty-box">
        <img class="empty-img" src="../../../assets/icons/empty.png" />
        <span>当前暂无数据</span>
      </div>
    </div>
    <el-dialog
      v-model="centerDialogVisible"
      custom-class="zd-manager-dialog"
      class="zd-manager-dialog"
      :append-to-body="true"
      width="440px"
    >
      <template #header>
        <span>阵地排序</span>
      </template>
      <div>
        <el-tree
          class="drag-tree-container"
          :allow-drop="allowDrop"
          :data="sortList"
          draggable
          node-key="id"
          :props="{
            label: 'name',
            class: (data, node) => {
              return `drag-tree-level${node.level}`
            }
          }"
          :accordion="true"
          @node-drag-end="handleDragEnd"
        >
          <template #default="{ node }">
            <span class="custom-tree-node">
              <span>{{ node.label }}</span>
              <span>
                <img src="./images/list.png" alt />
              </span>
            </span>
          </template>
        </el-tree>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取消</el-button>
          <el-button type="primary" :loading="sortLoading" @click="saveTreeDrag">提交</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import HeaderTitle from "components/pcComponent/headerBack/index";
import { modalMask } from "@/utils/modalMask.js";
import { Modal } from "ant-design-vue";
import { message } from "ant-design-vue";
import {
  zdManagerListAjax,
  zdManagerAddAjax,
  zdManagerDeletelAjax
} from "@/api/zdManager";

export default defineComponent({
  components: {
    HeaderTitle
  },
  setup() {
    const keyword = ref("");
    const list = ref([]);
    const sortList = ref([]);
    const router = useRouter();
    const loading = ref(true);
    const sortLoading = ref(false);
    const btnLoading = ref(false);
    const centerDialogVisible = ref(false);

    /**
     * 获取数据列表
     */
    const getData = () => {
      loading.value = true;
      zdManagerListAjax({ name: keyword.value })
        .then(res => {
          list.value = res.data;
          loading.value = false;
        })
        .catch(() => {
          loading.value = false;
        });
    };

    /**
     * 阵地 - add
     */
    const handleAddType = () => {
      router.push({
        path: "/zdManage/add"
      });
    };

    /**
     * 阵地 - 排序
     */
    const handleSort = () => {
      sortList.value = JSON.parse(JSON.stringify(list.value));
      centerDialogVisible.value = true;
    };

    /**
     * 阵地 - 编辑
     */
    const handleEdit = item => {
      router.push({
        path: "/zdManage/add",
        query: {
          id: item.id
        }
      });
    };

    /**
     * 阵地- 删除
     */
    const handleDelete = item => {
      // 该类型下有内容备案，请删除对应内容备案后删除阵地
      Modal.confirm({
        title: "",
        icon: "",
        content: "是否删除选项",
        cancelText: "取消",
        okText: "确认",
        class: "confirm-modal",
        okButtonProps: {
          loading: btnLoading.value
        },
        onOk() {
          zdManagerDeletelAjax({ id: item.id })
            .then(res => {
              if (res.data2.code === 409) {
                modalMask(res.data2.msg);
                return;
              }
              if (res.data2.code === 200) {
                message.success("删除成功");
                getData();
              }
              btnLoading.value = false;
            })
            .catch(() => {
              btnLoading.value = false;
            });
        }
      });
    };

    /**
     * 字段 - 管理
     */
    const handleManage = item => {
      router.push({
        path: "/zdManage/type",
        query: {
          id: item.id
        }
      });
    };

    const handleDragEnd = (draggingNode, dropNode, dropType, event) => {
      if (dropType === "none") {
        modalMask("无法跨级排序");
      }
    };
    const allowDrop = (draggingNode, dropNode, type) => {
      if (type === "inner" || dropNode.level !== draggingNode.level) {
        return false;
      } else {
        return true;
      }
    };

    const saveTreeDrag = () => {
      sortLoading.value = true;
      const params = {
        newTypeReqList: sortList.value,
        oldTypeReqList: list.value
      };
      zdManagerAddAjax(params)
        .then(res => {
          if (res.data2.code === 200) {
            centerDialogVisible.value = false;
            getData();
            message.success("保存成功");
          }
          sortLoading.value = false;
        })
        .catch(() => {
          sortLoading.value = false;
        });
    };

    onMounted(() => {
      getData();
    });
    return {
      list,
      keyword,
      centerDialogVisible,
      loading,
      sortLoading,
      sortList,
      getData,
      handleAddType,
      handleSort,
      handleEdit,
      handleDelete,
      handleManage,
      handleDragEnd,
      allowDrop,
      saveTreeDrag
    };
  }
});
</script>
<style lang="scss" scoped>
.zd-manager-container {
  .empty-box {
    height: 425px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.4);
    .empty-img {
      width: 224px;
      margin-bottom: 24px;
    }
  }
  .loading-class {
    min-height: 200px;
  }
}
.operate {
  margin: 24px 0;
  height: 32px;
  align-items: center;

  &-left {
    float: left;
    display: flex;
    &-input {
      width: 240px;
      margin-right: 24px;
    }
  }

  &-right {
    float: right;

    &-btn {
      margin-left: 16px;
    }
    .el-button:focus {
      border-color: #286cfb;
      background-color: #286cfb;
    }
  }
}
.zd-card {
  border-radius: 4px;
  border: 1px solid #ebebeb;
  border-bottom-style: none;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;

  &-header {
    position: relative;
    /* height: 46px; */
    line-height: 46px;
    padding: 0 30px;
    border-bottom: 1px solid #ebebeb;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      display: flex;
      align-items: center;
      &-main {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #2f3238;
        max-width: 500px;
        line-height: 24px;
        padding: 10px 0;
      }

      &-sub {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
      }
    }

    &-operate {
      float: right;
      display: flex;

      &-btn {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 14px;

        &-manager {
          width: 14px;
          height: 14px;
          background: url("./images/manager.png") no-repeat;
          background-size: 100% 100%;
        }
        &-delete {
          width: 14px;
          height: 14px;
          background: url("./images/delete.png") no-repeat;
          background-size: 100% 100%;
        }

        &-edit {
          width: 14px;
          height: 14px;
          background: url("./images/edit.png") no-repeat;
          background-size: 100% 100%;
        }

        &-text {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.4);
          margin-right: 2px;
        }
        &:hover {
          .manager-text {
            color: #21c05e;
          }
          .edit-text {
            color: #286cfb;
          }
          .delete-text {
            color: #ff4224;
          }
          .zd-card-header-operate-btn-manager {
            width: 14px;
            height: 14px;
            background: url("./images/manager_hover.png") no-repeat;
            background-size: 100% 100%;
          }
          .zd-card-header-operate-btn-delete {
            width: 14px;
            height: 14px;
            background: url("./images/delete_hover.png") no-repeat;
            background-size: 100% 100%;
          }

          .zd-card-header-operate-btn-edit {
            width: 14px;
            height: 14px;
            background: url("./images/edit_hover.png") no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
  }

  &-container {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    display: flex;
    .second-list {
      width: calc(50% - 50px);
      color: rgba(0, 0, 0, 0.5);
    }

    .third {
      display: flex;
    }

    .third-list {
      flex: 1;
      color: rgba(0, 0, 0, 0.5);
    }

    .font-light {
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.9);
    }

    .list {
      box-sizing: border-box;
      padding: 10px 30px;
      text-align: left;
      border-bottom: 1px solid #ebebeb;
      border-right: 1px solid #ebebeb;
      display: flex;
      align-items: center;
    }

    &-operate {
      width: 100px;
      text-align: center;
      border-right-style: none !important;

      &-text {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #286cfb;
        cursor: pointer;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import "~assets/css/public.scss";
.zd-manager-dialog {
  z-index: 500;
  .el-dialog__header {
    font-size: 16px;
    height: 45px;
    font-weight: bold;
    color: #262626;
    text-align: center;
    border-bottom: 1px solid #f0f0f0;
    padding: 12px;
    margin-right: 0;
    .el-dialog__headerbtn {
      height: 45px;
      top: 0;
    }
  }
  .el-dialog__body {
    max-height: 300px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
  }
  .el-dialog__footer {
    border-top: 1px solid #f0f0f0;
    padding: 12px;
    .el-button {
      width: 88px;
    }
  }

  .drag-tree-container {
    .el-tree-node {
      margin: 7px 0;
      .el-tree-node__content {
        margin: 7px 0;
        height: auto;
        /* display: flex; */
      }
    }
    //测试版缺少el-tree-node__label
    .el-tree-node__label {
      width: 95%;
    }
    .custom-tree-node {
      width: 100%;
      /* display: flex;
      justify-content: space-between;
      align-items: center; */
      span:first-of-type {
        width: 80%;
        white-space: pre-wrap;
      }
      span:nth-of-type(2) {
        float: right;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
    .el-icon.el-tree-node__expand-icon {
      color: rgba(0, 0, 0, 0.6);
    }
    .is-leaf {
      opacity: 0;
    }
    .drag-tree-level1 {
      font-size: 14px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.9);
    }
    .drag-tree-level2 {
      font-size: 14px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.5);
    }
    .drag-tree-level3 {
      font-size: 14px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.4);
    }
  }
}
</style>
