<template>
  <div class="main">
    <div class="mybeian">
      <div class="title">
        <img
          class="fanhuiimg"
          @click="fanhui"
          src="../../assets/icons/pcindex/b3b4.png"
        />
        <span class="span-t1">期刊审核</span>
      </div>
      <div class="nrlist">
        <el-form :model="form">
          <el-row>
            <el-form-item label="">
              <el-input
                v-model="form.name"
                placeholder="请输入期刊名称"
                style="width: 240px"
              />
            </el-form-item>
            <el-form-item label="审核状态:" style="margin-left: 230px">
              <el-select v-model="form.status" style="width: 240px">
                <el-option :value="3" label="全部"></el-option>
                <el-option :value="0" label="审核中"></el-option>
                <el-option :value="1" label="审核通过"></el-option>
                <el-option :value="2" label="审核驳回"></el-option>
              </el-select>
            </el-form-item>
            <el-button type="primary" @click="search" class="search"
              >查询</el-button
            >
          </el-row>
        </el-form>
        <el-table :data="records" border style="width: 862px">
          <el-table-column
            prop="name"
            label="期刊名称"
            min-width="20%"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            prop="status"
            label="审核状态"
            min-width="20%"
            :show-overflow-tooltip="true"
          >
            <template #default="scope">
              <span
                v-if="scope.row.status == 0"
                style="
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #fb9c00;
                "
                >审核中</span
              >
              <span
                v-if="scope.row.status == 1"
                style="
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #21c05e;
                "
                >审核通过</span
              >
              <span
                v-if="scope.row.status == 2"
                style="
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #ff4224;
                "
                >审核驳回</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="management" label="管理主体" min-width="20%">
            <template #default="scope">
              <span>{{ management(scope.row.management) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="recorderName" label="创建人" min-width="22%" />
          <el-table-column prop="recordTime" label="备案时间" min-width="22%" />
          <el-table-column fixed="right" label="操作" width="80px">
            <template v-slot:default="scope">
              <div class="sp-btn" @click="lookUp(scope.row)">
                <span
                  class="span-op"
                  style="
                    color: #286cfb;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    cursor: pointer;
                  "
                  >审批</span
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page-wrapper">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[20, 50, 100]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { management } from "@/assets/common";
import { list } from "@/api/createJournal";
export default {
  data() {
    return {
      form: {
        name: null,
        stauts: 3,
      },
      currentPage: 1,
      size: 20,
      total: 0,
      records: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    management,
    fanhui() {
      this.$router.go(-1);
    },
    getList() {
      let params = {
        current: this.currentPage,
        size: this.size,
        name: this.form.name,
        status: this.form.status === 3 ? null : this.form.status,
      };
      list(params).then((res) => {
        this.records = res.data.records;
        this.total = res.data.total;
      });
    },
    handleSizeChange(val) {
      this.size = val;
      // this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      // this.getList();
    },
    search() {
      this.currentPage = 1;
      this.getList();
    },
    lookUp(row) {
      this.$router.push({
        path: "/createInformation",
        query: { id: row.id, type: 2, recordTime: row.recordTime },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  margin: 0 auto;
  overflow: hidden;
}

@media screen and (min-width: 820px) {
  .main {
    width: 928px;
  }
}

@media screen and (min-width: 960px) {
  .main {
    width: 928px;
  }
}

@media screen and (min-width: 1200px) {
  .main {
    width: 928px;
  }
}

.mybeian {
  width: 928px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 16px;
  padding-top: 24px;
  margin-bottom: 23px;

  .title {
    height: 22px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .fanhuiimg {
      height: 16px;
      width: 16px;
      margin-left: 16px;
      cursor: pointer;
    }

    .span-t1 {
      width: 64px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2f3238;
      line-height: 22px;
      margin-left: 10px;
    }
  }

  .cate {
    width: 928px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .button {
      margin-right: 20px;
      width: 102px;
      height: 32px;
      background: #286cfb;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.9);
      line-height: 22px;
    }
    .cate-i {
      .cate-span {
        color: #286cfb;
        width: 56px;
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        margin-right: 684px;
        line-height: 22px;
      }

      .cate-icon {
        margin-right: 3px;
        width: 16px;
        width: 16px;
      }
    }
  }

  .nrlist {
    width: 862px;
    margin-left: 46px;

    .tabs {
      .tab {
        margin-top: 15px;
        margin-bottom: 5px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 20px;

        ::v-deep .el-tabs__item {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
          line-height: 20px;
        }
      }
    }

    ::v-deep .el-tabs__item.is-active {
      color: #286cfb !important;
    }
    ::v-deep .el-tabs__active-bar {
      background-color: #286cfb;
    }
  }
  .page-wrapper {
    height: 80px;
    background: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  ::v-deep .el-form-item__label {
    height: 32px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    line-height: 32px;
  }
  .search {
    width: 60px;
    height: 32px;
    background: rgba(40, 108, 251, 0.4);
    border-radius: 4px;
    margin-left: 16px;
    border: none;
  }
}
</style>
