<template>
    <div class="main">
        <div class="mybeian">

            <div class="title">
                <img class="fanhuiimg" :style="background"
                     @click="fanhui"
                     src="../../assets/icons/pcindex/b3b4.png"/>
                <span class="span-t1">错敏词词库</span>
            </div>
            <div class="cate">
                <div class="cate-i" @click="cateChange(0)">
                    <span class="cate-span" :class="{color: search.type == 0}"> 官方错敏词</span>
                </div>
                <div class="cate-i" @click="cateChange(1)">
                    <span class="cate-span" :class="{color:  search.type == 1}"> 海尔错敏词</span>
                </div>
            </div>
            <div class="nrlist">
                    <cmckList :type="search.type" :list="dataList" @refreshData="refreshData"/>
                </div>

            </div>
    </div>
</template>
<script>
    import {useRouter} from "vue-router";
    import myAxios from "@/api/request";
    import {ref} from 'vue'
    import cmckList from "../../components/pcComponent/cmckList";
    export default {
        name: 'cmck',
        components: {
            cmckList,

        },
        setup() {
            const router = useRouter()

            const fanhui = () => {
                router.back();
            }
            return {

                fanhui,
            }
        },
        created() {

        },

        methods: {
            cateChange(e) {
                    this.search.type = e;
                    this.getList()
            },
            async getList() {
                const res = await myAxios({
                    url: '/errorWord',
                    params: this.search,
                    method: 'get'
                })
                this.dataList = res.data
            },

            refreshData(msg) {
                    this.search.size = msg.size
                    this.search.current = msg.current
                    this.search.expression = msg.expression
                    this.getList()
            },

        },
        data() {
            return {
                List: [],

           cate: 0,
                total: '400',
                search: {
                    'current': 1,
                    'size': 20,
                     'type': 0,
                    'expression': '',
                },
                'dataList': [],
            }
        },
        mounted() {
            this.getList(this.url,this.zdsearch)
        }
    }
</script>
<style lang="scss" scoped>
    .main {
        margin: 0 auto;
        overflow: hidden
    }

    @media screen and (min-width: 820px) {
        .main {
            width: 928px;
        }
    }

    @media screen and (min-width: 960px) {
        .main {
            width: 928px;
        }
    }

    @media screen and (min-width: 1200px) {
        .main {
            width: 928px;
        }
    }
    /*  必填提示*/
    ::v-deep  .ant-form-item-explain {
        height: 0px;
        min-height: 24px;
        opacity: 1;
    }
    ::v-deep .ant-drawer{
        z-index:1000
    }
    .mybeian {
        width: 928px;
        background: #FFFFFF;
        border-radius: 10px;
        margin-top: 16px;
        padding-top: 24px;
        margin-bottom: 23px;

        .title {
            height: 22px;
            display: flex;
            align-items: center;

            .fanhuiimg {
                height: 16px;
                width: 16px;
                margin-left: 16px;
                cursor: pointer;
            }

            .span-t1 {

                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #2F3238;
                line-height: 22px;
                margin-left: 10px;
            }
        }

        .cate {
            margin-bottom: 15px;

            .color {
                color: #286CFB !important;
            }

            width: 862px;
            height: 22px;
            margin-top: 15px;
            margin-left: 46px;
            display: flex;

            .cate-i {
                cursor: pointer;

                .cate-span {
                    width: 56px;
                    height: 22px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    margin-right: 24px;
                    line-height: 22px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    line-height: 22px;
                }

                .cate-icon {
                    margin-right: 3px;
                    width: 16px;
                    width: 16px;
                }
            }
        }

        .nrlist {
            width: 862px;

            margin-left: 46px;

            .tabs {
                .tab {
                    margin-top: 15px;
                    margin-bottom: 5px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    line-height: 20px;

                    ::v-deep .el-tabs__item {
                        color: rgba(0, 0, 0, 0.6);
                    }
                }
            }



        }
    }

</style>