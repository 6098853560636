<template>
  <div class="field-management-container">
    <header-title title="字段管理" titleDes="(可拖动调整字段顺序)" />
    <div class="add-field">
      <el-button type="primary" @click="addField">新建词条</el-button>
    </div>
    <div class="field-management-table-box">
      <el-table
        :data="tableData"
        style="width: 100%"
        row-key="id"
        border
        header-row-class-name="table-header-box"
        :row-style="{
          'font-size': '14px',
          'font-weight': 400,
          'color': 'rgba(0, 0, 0, 0.5)'
        }"
        :row-class-name="({row, rowIndex}) => {
          if (rowIndex !== 0 && rowIndex !== 1) {
            return `handle-row`
          } else {
            return 'filter-row'
          }
        }"
      >
        <el-table-column prop="name" label="字段名称" />
        <el-table-column
          prop="type"
          label="类型"
          :formatter="(row) => {
            switch(row.type) {
              case 0:
                return '文本'
              case 1:
                return '选项'
              case 2:
                return '附件'
              case 3:
                return '时间'
              default:
                '文本'
            }
          }"
        />
        <el-table-column prop="isRequired" label="必填">
          <template #default="scope">
            <div class="checkbox">
              <img v-show="scope.row.noEdit" src="../../../assets/icons/disabled_checkbox.png" alt />
              <img
                v-show="scope.row.isRequired && !scope.row.noEdit"
                src="../../../assets/icons/checkbox.png"
                alt
              />
              <img
                v-show="!scope.row.isRequired && !scope.row.noEdit"
                src="../../../assets/icons/noChecked.png"
                alt
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template #default="scope">
            <el-button
              v-show="!scope.row.noEdit"
              link
              type="primary"
              size="small"
              @click.prevent="editRow(scope.row, scope.$index)"
            >编辑</el-button>
            <el-button
              v-show="!scope.row.noEdit"
              link
              type="primary"
              size="small"
              @click.prevent="deleteRow(scope.$index)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加字段 -->
    <el-drawer
      v-model="visible"
      custom-class="custom-class"
      :show-close="false"
      :destroy-on-close="true"
    >
      <template #header>
        <div class="header-box">
          <span>标题名称</span>
          <img src="../../../assets/icons/close@2x.png" @click="closeDrawer" />
        </div>
      </template>
      <el-form ref="formRef" label-width="80px" :model="formState" :rules="rules">
        <el-form-item label="字段名称" prop="name">
          <el-input
            v-model="formState.name"
            placeholder="请输入内容"
            show-word-limit
            :maxlength="20"
            autocomplete="off"
            style="margin-right: 20px;"
          />
        </el-form-item>
        <el-form-item label="字段类型" :wrapper-col="{ span: 14, offset: 4 }">
          <el-select
            ref="select"
            v-model="formState.type"
            placeholder="请选择类型"
            popper-class="m-2"
            style="margin-right: 20px;"
            @change="handleChange"
          >
            <el-option label="文本" :value="0"></el-option>
            <el-option label="选项" :value="1"></el-option>
            <el-option label="附件" :value="2"></el-option>
            <el-option label="时间" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="formState.type === 1" label="选项内容" prop="content" key="content">
          <div class="content-item">
            <div class="add-content" @click="addContent">新增</div>
            <ul class="content-box">
              <li v-for="(item, index) in formState.content" :key="item">
                <span>{{ item }}</span>
                <img src="../../../assets/icons/delete@2x.png" @click="delContent(index)" />
              </li>
            </ul>
          </div>
        </el-form-item>
        <el-form-item label="提示文字">
          <el-input
            v-model="formState.description"
            :rows="4"
            placeholder="请输入内容"
            show-word-limit
            :maxlength="150"
            style="margin-right: 20px"
            type="textarea"
          />
        </el-form-item>
        <el-form-item label="是否必填">
          <a-radio-group class="radio-a" v-model:value="formState.isRequired" @change="cateChange">
            <a-radio :value="1">必填</a-radio>
            <a-radio :value="0">非必填</a-radio>
          </a-radio-group>
          <!-- <el-radio-group v-model="formState.isRequired">
            <el-radio :label="1">必填</el-radio>
            <el-radio :label="0">非必填</el-radio>
          </el-radio-group>-->
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="footer-box">
          <el-button type="primary" :loading="loading" @click="submitForm(formRef)">提交</el-button>
          <el-button style="margin-left: 10px" @click="resetForm">取消</el-button>
        </div>
      </template>
    </el-drawer>
    <!-- 选项内容 -->

    <el-dialog
      v-model="centerDialogVisible"
      class="content-dialog"
      custom-class="content-dialog"
      :append-to-body="true"
      width="440px"
    >
      <template #header>
        <span>批量新增</span>
        <span>（每行对应一个选项，多行用回车隔开）</span>
      </template>
      <div>
        <el-form ref="dialogFormRef" :model="contentForm" :rules="contentRules">
          <el-form-item label prop="contentList">
            <el-input
              v-model="contentForm.contentList"
              :rows="10"
              :placeholder="`选项1\n\n选项2\n\n选项3`"
              type="textarea"
            />
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeContent">取消</el-button>
          <el-button type="primary" :loading="contentLoading" @click="saveContent(dialogFormRef)">提交</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { defineComponent, ref, nextTick, onMounted } from "vue";
import { useRoute } from "vue-router";
import HeaderTitle from "components/pcComponent/headerBack/index";
import { Modal } from "ant-design-vue";
import Sortable from "sortablejs";
import { fieldManagerListAjax, fieldManagerAddAjax } from "@/api/zdManager";
import { message } from "ant-design-vue";

export default defineComponent({
  components: {
    HeaderTitle
  },
  setup() {
    const route = useRoute();
    const loading = ref(false);
    const type = ref("");
    const fieldId = ref("");
    const contentLoading = ref(false);
    const btnLoading = ref(false);
    const visible = ref(false);
    const centerDialogVisible = ref(false);
    const formRef = ref();
    const dialogFormRef = ref();
    const editIndex = ref(0);
    const contentForm = ref({
      contentList: ""
    });
    const formState = ref({
      content: [],
      description: "",
      isRequired: 0,
      name: "",
      type: 0
    });
    const initList = [
      {
        content: [],
        description: "",
        isRequired: 1,
        name: "名称",
        id: 1000,
        type: 0,
        noEdit: true
      },
      {
        content: [],
        description: "",
        isRequired: 1,
        name: "所属平台",
        id: 2000,
        type: 0,
        noEdit: true
      }
    ];
    const tableData = ref([]);
    let validatePass = async (_rule, value) => {
      if (!value.length) {
        return Promise.reject("请添加选项内容");
      } else {
        return Promise.resolve();
      }
    };
    let validateContent = async (_rule, value) => {
      if (!value.length) {
        return Promise.reject("请添加选项内容");
      } else {
        return Promise.resolve();
      }
    };
    const rules = {
      name: [{ required: true, message: "请输入字段名称", trigger: "blur" }],
      content: [{ required: true, trigger: "change", validator: validatePass }]
    };
    const contentRules = {
      contentList: [
        { required: true, trigger: "change", validator: validateContent }
      ]
    };
    onMounted(() => {
      _fieldManagerListAjax();
      //table拖动排序
      rowDrop();
    });
    function _fieldManagerListAjax() {
      fieldManagerListAjax(route.query.id).then(res => {
        if (res.data2.code === 200) {
          if (res.data.fields) {
            tableData.value = initList.concat(res.data.fields);
          } else {
            tableData.value = initList;
          }
          fieldId.value = res.data.id;
        }
      });
    }
    function rowDrop() {
      const tbody = document.querySelector(".el-table__body-wrapper tbody");
      Sortable.create(tbody, {
        handle: ".handle-row",
        onEnd({ newIndex, oldIndex }) {
          let newArray = [];
          if (newIndex !== 0 && newIndex !== 1) {
            tableData.value.splice(
              newIndex,
              0,
              tableData.value.splice(oldIndex, 1)[0]
            );
          } else {
            tableData.value.splice(
              2,
              0,
              tableData.value.splice(oldIndex, 1)[0]
            );
          }
          newArray = tableData.value.slice(0);
          tableData.value = [];
          nextTick(() => {
            tableData.value = newArray;
            fieldManagerAddAjax({
              fields: tableData.value.slice(2).map((ele, index) => {
                ele.id = index + 1;
                return ele;
              }),
              id: fieldId.value
            }).then(res => {
              if (res.data2.code === 200) {
                _fieldManagerListAjax();
              }
            });
          });
        }
      });
    }
    const addField = () => {
      type.value = "create";
      formState.value = {
        content: [],
        description: "",
        isRequired: 0,
        name: "",
        type: 0
      };
      visible.value = true;
    };
    const editRow = (row, index) => {
      type.value = "edit";
      editIndex.value = index;
      if (row.type === 1) {
        contentForm.value.contentList = row.content.join("\n");
      }
      formState.value = {
        ...row
      };
      visible.value = true;
    };
    const deleteRow = index => {
      Modal.confirm({
        title: "",
        icon: "",
        content: "是否删除选项",
        cancelText: "取消",
        okText: "确认",
        class: "confirm-modal",
        okButtonProps: {
          loading: btnLoading.value
        },
        onOk() {
          btnLoading.value = true;
          tableData.value.splice(index, 1);
          const params = tableData.value.slice(2).map((ele, index) => {
            ele.id = index + 1;
            return ele;
          });
          fieldManagerAddAjax({
            fields: params,
            id: fieldId.value
          })
            .then(res => {
              if (res.data2.code === 200) {
                message.success("删除成功");
                _fieldManagerListAjax();
              }
              btnLoading.value = false;
            })
            .catch(() => {
              btnLoading.value = false;
            });
        },
        onCancel() {
          console.log("Cancel");
        }
      });
    };
    const handleChange = value => {
      if (value !== 1) {
        contentForm.value.contentList = "";
        formState.value.content = [];
      }
    };
    const resetForm = () => {
      formRef.value.resetFields();
      visible.value = false;
    };
    const submitForm = formEl => {
      if (!formEl) return;
      loading.value = true;
      formEl.validate(valid => {
        if (valid) {
          let params = [];
          if (type.value === "create") {
            params = [
              ...tableData.value.slice(2).map((ele, index) => {
                ele.id = index + 1;
                return ele;
              }),
              {
                ...formState.value,
                id: tableData.value.slice(2).length + 1
              }
            ];
          } else {
            tableData.value[editIndex.value] = {
              ...formState.value,
              id: editIndex.value - 1
            };
            params = tableData.value.slice(2);
          }
          fieldManagerAddAjax({
            fields: params,
            id: fieldId.value
          })
            .then(res => {
              if (res.data2.code === 200) {
                message.success("保存成功");
                visible.value = false;
                _fieldManagerListAjax();
              }
              loading.value = false;
            })
            .catch(() => {
              loading.value = false;
            });
        } else {
          loading.value = false;
          return false;
        }
      });
    };
    const addContent = () => {
      centerDialogVisible.value = true;
      contentForm.value.contentList =
        formState.value.content && formState.value.content.join("\n");
    };
    const delContent = index => {
      formState.value.content.splice(index, 1);
      contentForm.value.contentList = formState.value.content.join("\n");
    };
    const closeDrawer = () => {
      formState.value = {
        content: [],
        description: "",
        isRequired: 0,
        name: "",
        type: 0
      };
      visible.value = false;
    };
    const saveContent = formEl => {
      if (!formEl) return;
      contentLoading.value = true;
      formEl.validate(valid => {
        if (valid) {
          formState.value.content = contentForm.value.contentList
            .split("\n")
            .filter(ele => {
              return ele;
            });
          centerDialogVisible.value = false;
          contentLoading.value = false;
        } else {
          contentLoading.value = false;
          return false;
        }
      });
    };
    const closeContent = () => {
      dialogFormRef.value.resetFields();
      centerDialogVisible.value = false;
    };
    return {
      tableData,
      loading,
      contentLoading,
      visible,
      centerDialogVisible,
      formState,
      rules,
      contentRules,
      formRef,
      dialogFormRef,
      type,
      contentForm,
      addField,
      editRow,
      deleteRow,
      resetForm,
      handleChange,
      submitForm,
      closeDrawer,
      addContent,
      delContent,
      closeContent,
      saveContent
    };
  }
});
</script>
<style lang="scss" scoped>
.field-management-container {
  position: relative;
  .add-field {
    position: absolute;
    right: 0;
    top: -5px;
  }
  .field-management-table-box {
    margin-left: 24px;
    margin-top: 29px;
    /deep/.el-table {
      .table-header-box {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
      }
      .table-row-box {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }
      .el-button {
        color: #286cfb;
        font-size: 14px;
      }
      .filter-row {
        cursor: no-drop;
      }
    }
    .pagination-box {
      float: right;
      margin-top: 24px;
      /deep/.el-pagination {
        .number {
          border: 1px solid #dcdfe6;
          border-radius: 3px;
          color: #606266;
          margin: 0 4px;
        }
        .number.is-active {
          border: 1px solid #286cfb;
          border-radius: 3px;
          color: #286cfb;
        }
      }
    }
  }
  .checkbox {
    img {
      width: 16px;
    }
  }
}
</style>
<style lang="scss">
@import "~assets/css/public.scss";
.custom-class {
  .el-drawer__header {
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 0;
    padding: 16px;
  }
  .el-drawer__body {
    padding-right: 0;
  }
  .header-box {
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: space-between;
    img {
      height: 16px;
      width: 16px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.9);
      cursor: pointer;
    }
  }
  .el-drawer__footer {
    border-top: 1px solid #e7e7e7;
    padding: 16px;
    .el-button:nth-of-type(2) {
      background: #e7e7e7;
      border-color: #e7e7e7;
      color: rgba(0, 0, 0, 0.9);
    }
  }
  .el-form {
    .el-form-item {
      .el-form-item__label {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
      }
      .radio-a {
        float: right;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
        line-height: 22px;
        .ant-radio-checked .ant-radio-inner {
          border-color: #286cfb;
        }
        .ant-radio-inner::after {
          background-color: #286cfb;
        }
        .ant-radio-wrapper {
          span:nth-of-type(2) {
            color: rgba(0, 0, 0, 0.9);
            font-weight: 400;
          }
        }
      }
      .el-textarea__inner {
        padding-bottom: 18px;
      }
      .content-item {
        position: relative;
        .content-box {
          display: flex;
          flex-wrap: wrap;
          float: left;
          li {
            padding: 6px 8px;
            background: #e7e7e7;
            border-radius: 3px;
            font-size: 12px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.9);
            display: flex;
            align-items: center;
            margin-right: 12px;
            margin-top: 12px;
            span {
              margin-right: 20px;
              display: inline-block;
              line-height: 22px;
            }
            img {
              width: 16px;
              height: 16px;
              cursor: pointer;
            }
          }
        }
      }
      .add-content {
        width: 60px;
        height: 32px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 3px;
        border: 1px solid #286cfb;
        font-size: 14px;
        font-weight: 400;
        color: #286cfb;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
.content-dialog {
  z-index: 500;
  .el-dialog__header {
    height: 45px;
    border-bottom: 1px solid #f0f0f0;
    padding: 12px;
    margin-right: 0;
    display: flex;
    align-items: center;
    span:nth-of-type(1) {
      color: #262626;
      font-size: 16px;
      font-weight: bold;
    }
    span:nth-of-type(2) {
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
    }
    .el-dialog__headerbtn {
      height: 45px;
      top: 0;
    }
  }
  .el-dialog__body {
    padding: 20px;
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .el-dialog__footer {
    border-top: 1px solid #f0f0f0;
    padding: 12px;
    .el-button {
      width: 88px;
    }
  }
}
.m-2 {
  .el-select-dropdown__item.selected {
    color: #286cfb;
  }
}
</style>
