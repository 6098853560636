<template>
  <div class="main">
    <div class="mybeian">
      <div class="title">
        <img class="fanhuiimg" @click="fanhui" src="../../assets/icons/pcindex/b3b4.png" />
        <span class="span-t1">备案库</span>
      </div>

      <div class="cate">
        <div class="cate-i" @click="cateChange(0)">
          <img class="cate-icon" src="../../assets/icons/pcindex/20.png" v-if="cate == 0" />
          <img class="cate-icon" src="../../assets/icons/pcindex/7.png" v-if="cate == 1" />
          <span class="cate-span" :class="{color: cate == 0}">阵地备案</span>
        </div>
        <div class="cate-i" @click="cateChange(1)">
          <img class="cate-icon" src="../../assets/icons/pcindex/discount.png" v-if="cate == 0" />
          <img class="cate-icon" src="../../assets/icons/pcindex/discount1.png" v-if="cate == 1" />
          <span class="cate-span" :class="{color: cate == 1}">内容备案</span>
        </div>
      </div>
      <div class="nrlist">
        <div class="tabs">
          <el-tabs v-model="nrCate" class="tab" v-if="cate == 1" @tab-click="changeNrCate">
            <el-tab-pane v-for="( i,index) in nrCateList" :label="i" :name="index" :key="index"></el-tab-pane>
          </el-tabs>
        </div>

        <div>
          <zdbeianList v-if="cate==0" :list="dataList" @refreshData="refreshData" />
          <nrbeianList v-if="cate==1" ref="nrbeianRef" :list="dataList" @refreshData="refreshData" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useRouter } from "vue-router";
import myAxios from "@/api/request";
import { ref } from "vue";
import { useStore } from "vuex";
import zdbeianList from "../../components/pcComponent/zdbeiankList";
import nrbeianList from "../../components/pcComponent/nrbeiankList";
export default {
  name: "mybeian",
  components: {
    zdbeianList,
    nrbeianList
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const zdCate = store.state.tagcate;
    const nrCate = store.state.tagcate;
    const fanhui = () => {
      store.commit("tag", 0);
      store.commit("tagcate", 0);
      router.back();
    };
    return {
      zdCate,
      nrCate,
      fanhui
    };
  },
  created() {},
  methods: {
    cateChange(e) {
      this.$store.commit("tag", e);
      this.$store.commit("tagcate", 0);
      this.zdCate = 0;
      this.nrCate = 0;
      if (e == 0) {
        this.zdsearch = {
          current: 1,
          size: 20,
          status: null,
          isAdminPage: 1,
          // cate: 0,
          name: ""
        };
        this.url = "/positionRecord";
        this.getList(this.url, this.zdsearch);
      } else {
        this.nrsearch = {
          positionCate: "",
          current: 1,
          size: 20,
          isAdminPage: 1,
          cate: 0,
          title: ""
        };
        this.url = "/contentRecord";
        this.getList(this.url, this.nrsearch);
      }
    },
    changeZdCate(tab, event) {
      this.$store.commit("tagcate", tab.props.name);
      // this.zdsearch.cate = tab.props.name;
      this.getList(this.url, this.zdsearch);
    },
    changeNrCate(tab, event) {
      this.nrsearch = {
        positionCate: "",
        current: 1,
        size: 20,
        isAdminPage: 1,
        cate: 0,
        title: ""
      };
      this.$store.commit("tagcate", tab.props.name);
      this.nrsearch.cate = tab.props.name;
      this.getList(this.url, this.nrsearch);
      this.$refs.nrbeianRef.clearCascader();
    },
    async getList(_url, params) {
      //一进页面获取tag，和tagcate的初始值
      this.cate = this.$store.state.tag;
      if (this.cate === 1) {
        params.cate = this.$store.state.tagcate;
      }
      if (this.cate == 1) {
        _url = "/contentRecord";
      } else {
        _url = "/positionRecord";
        params.status = 1;
      }
      const res = await myAxios({
        url: _url,
        params: params,
        method: "get"
      });
      this.dataList = res.data;
    },

    refreshData(msg) {
      this.cate = this.$store.state.tag;
      if (this.cate == 1) {
        this.nrsearch.size = msg.size;
        this.nrsearch.current = msg.current;
        this.nrsearch.title = msg.title;
        this.nrsearch.positionCate = msg.positionCate;
        this.url = "/contentRecord";
        this.getList(this.url, this.nrsearch);
        this.getNrCate();
      } else {
        this.url = "/positionRecord";
        this.zdsearch.size = msg.size;
        this.zdsearch.current = msg.current;
        this.zdsearch.name = msg.name;
        this.zdsearch.positionCate = msg.positionCate;
        this.getList(this.url, this.zdsearch);
      }
    },
    async getNrCate() {
      const res = await myAxios({
        url: "/contentRecord/statistics",
        params: { isAdminPage: 1 },
        method: "get"
      });
      const record = res.data2.data;
      this.nrCateList = [];
      record.forEach(item => {
        const tab = item.cateName + "(" + item.cateNum + ")";
        this.nrCateList.push(tab);
      });
    }
  },
  data() {
    return {
      nrCateList: [],
      url: "/positionRecord",
      cate: 0,
      status: null,
      total: "400",
      zdsearch: {
        current: 1,
        size: 20,
        status: null,
        isAdminPage: 1,
        // cate: 0,
        name: ""
      },
      nrsearch: {
        positionCate: "",
        current: 1,
        size: 20,
        isAdminPage: 1,
        cate: 0,
        title: ""
      },
      dataList: []
    };
  },
  mounted() {
    this.getList(this.url, this.zdsearch), this.getNrCate();
  }
};
</script>
<style lang="scss" scoped>
.main {
  margin: 0 auto;
  overflow: hidden;
}

@media screen and (min-width: 820px) {
  .main {
    width: 928px;
  }
}

@media screen and (min-width: 960px) {
  .main {
    width: 928px;
  }
}

@media screen and (min-width: 1200px) {
  .main {
    width: 928px;
  }
}

.mybeian {
  width: 928px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 16px;
  padding-top: 24px;
  margin-bottom: 23px;

  .title {
    height: 22px;
    display: flex;
    align-items: center;

    .fanhuiimg {
      height: 16px;
      width: 16px;
      margin-left: 16px;
      cursor: pointer;
    }

    .span-t1 {
      width: 64px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2f3238;
      line-height: 22px;
      margin-left: 10px;
    }
  }

  .cate {
    margin-bottom: 15px;

    .color {
      color: #286cfb !important;
    }

    width: 862px;
    height: 22px;
    margin-top: 15px;
    margin-left: 46px;
    display: flex;

    .cate-i {
      cursor: pointer;
      display: flex;
      align-items: center;
      .cate-span {
        width: 56px;
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        margin-right: 24px;
        line-height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
      }

      .cate-icon {
        margin-right: 3px;
        width: 16px;
        width: 16px;
      }
    }
  }

  .nrlist {
    width: 862px;

    margin-left: 46px;

    .tabs {
      .tab {
        margin-top: 15px;
        margin-bottom: 5px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 20px;

        ::v-deep .el-tabs__item {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    ::v-deep .el-tabs__item.is-active {
      color: #286cfb !important;
    }
    ::v-deep .el-tabs__active-bar {
      background-color: #286cfb;
    }
  }
}
</style>