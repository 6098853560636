import myAxios from './request'

export function zdManagerListAjax(params) {
    return myAxios({
        url: '/positionType',
        method: 'get',
        params
    })
}

export function zdManagerAddAjax(data) {
    return myAxios({
        url: '/positionType',
        method: 'post',
        data
    })
}

export function zdManagerDetailAjax(id) {
    return myAxios({
        url: `/positionType/${id}`,
        method: 'get'
    })
}

//删除阵地类型
export function zdManagerDeletelAjax(params) {
    return myAxios({
        url: `/positionType`,
        method: 'delete',
        params
    })
}

export function fieldManagerListAjax(id) {
    return myAxios({
        url: `/positionField/${id}`,
        method: 'get'
    })
}

export function fieldManagerAddAjax(data) {
    return myAxios({
        url: '/positionField',
        method: 'put',
        data
    })
}

export function recordManagerDetailAjax(id) {
    return myAxios({
        url: `/positionRecord/log/${id}`,
        method: 'get'
    })
}

export function nameRenameAjax(params) {
    return myAxios({
        url: `/positionRecord/checkName`,
        method: 'get',
        params
    })
}

export function nrecordDetailAjax(id) {
    return myAxios({
        url: `/positionRecord/${id}`,
        method: 'get'
    })
}

//内容备案的三级阵地
export function nrzdThreeCascaderAjax(params) {
    return myAxios({
        url: `/positionType/searchWithRecord`,
        method: 'get',
        params
    })
}

// 四级阵地
export function nrzdCascaderAjax(params) {
    return myAxios({
        url: `/positionRecord/list`,
        method: 'get',
        params
    })
}

//添加阵地类型的时候校验是否存在备案
export function checkRecordAjax(params) {
    return myAxios({
        url: `/positionType/checkRecord`,
        method: 'get',
        params
    })
}

//删除阵地类型的时候校验其及子类型是否存在备案
export function checkRecordChildAjax(params) {
    return myAxios({
        url: `/positionType/checkRecordWithChildren`,
        method: 'get',
        params
    })
}

//检测阵地类型同层级是否有重名
export function checkNameAjax(params) {
    return myAxios({
        url: `/positionType/checkName`,
        method: 'get',
        params
    })
}
