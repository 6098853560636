<template>
  <div class="h5-form-wrap">
    <!-- <div class="header-top">
      <div class="title">
        创建期刊
      </div>
    </div> -->
    <div class="form-space"></div>
    <van-form @failed="onFailed">
      <van-cell-group inset>
        <!-- 通过 pattern 进行正则校验 -->
        <van-field
          label="期刊名称"
          label-width="80"
          maxlength="20"
          required
          v-model="formData.name"
          name="pattern"
          placeholder="请输入(20字内)"
          :rules="[{ pattern, message: '请输入正确内容' }]"
        />
        <!-- 通过 validator 进行函数校验 -->
        <van-field
          v-model="formData.position"
          rows="4"
          autosize
          required
          label-width="80"
          label="期刊定位"
          type="textarea"
          maxlength="100"
          placeholder="请输入"
          show-word-limit
          :rules="[{ pattern, message: '请输入正确内容' }]"
        />
        <!-- 通过 validator 返回错误提示 -->
        <van-field
          v-model="formData.description"
          :rules="[{ pattern, message: '请输入正确内容' }]"
          rows="4"
          autosize
          required
          label-width="80"
          label="期刊介绍"
          type="textarea"
          maxlength="100"
          placeholder="请输入"
          show-word-limit
        />
        <van-field required name="uploader" label-width="80" label="封面上传">
          <template #input>
            <div>
              <van-uploader
                v-model="fileList"
                :max-count="1"
                label-width="80"
                label="封面上传"
                :before-delete="deleteCover"
                :after-read="handleChange"
              />
              <div class="upload-desc">
                支持jpg/png/jpeg格式文件，大小不超过2M，封面尺寸推荐1160*1500px
              </div>
            </div>
          </template>
        </van-field>
      </van-cell-group>
      <div class="form-space"></div>
      <div>
        <van-field
          :rules="[{ pattern, message: '请输入正确内容' }]"
          required
          v-model="formData.managementOB.text"
          is-link
          readonly
          label="主办单位"
          placeholder="请选择"
          @click="managementListShow = true"
          label-width="80"
        />
        <van-popup v-model:show="managementListShow" round position="bottom">
          <van-cascader
            v-model="formData.managementOB.value"
            title="请选择"
            :options="options"
            @close="managementListShow = false"
            @finish="onManagementFinish"
          />
        </van-popup>
      </div>
      <div>
        <van-field
          required
          v-model="formData.department.text"
          is-link
          readonly
          label="主管部门"
          placeholder="请选择"
          @click="departmentListShow = true"
          label-width="auto"
        />
        <van-popup v-model:show="departmentListShow" round position="bottom">
          <van-picker
            active-color="#1989fa"
            v-model="formData.department.value"
            title="请选择"
            :columns="partyBranch"
            @confirm="onDepartmentFinish"
            @cancel="departmentListShow = false"
            :default-index="defaultIndex"
          />
        </van-popup>
      </div>
      <div>
        <van-field
          required
          @click="selectAdmin"
          label-width="80"
          name="rate"
          label="管理人"
        >
          <template #input>
            <!-- <van-rate v-model="value" /> -->
            <img
              class="icons-add"
              src="../../assets/icons/ADD_hover@2x.png"
              alt=""
            />
          </template>
        </van-field>
        <SelectPerForH5
          @selectUserAdd="selectAdminAdd"
          maxCount="20"
          :list="formData.manager"
          ref="selectAdmin"
        />
      </div>
      <div>
        <van-field
          v-model="formData.target.text"
          required
          is-link
          readonly
          label="面向对象"
          placeholder="请选择"
          @click="targetListShow = true"
          label-width="80"
        />
        <van-popup v-model:show="targetListShow" round position="bottom">
          <van-picker
            v-model="formData.target.value"
            title="请选择"
            :columns="faceObject"
            @confirm="onTargetFinish"
            @cancel="targetListShow = false"
          />
        </van-popup>
      </div>
      <div>
        <van-field
          required
          v-model="formData.target.input"
          label-width="80"
          maxlength="20"
          label="对象描述"
          placeholder="请输入面向对象的详细描述"
        />
      </div>
      <div>
        <van-field
          v-model="formData.range.text"
          is-link
          readonly
          required
          label="可见范围"
          placeholder="请选择"
          @click="visibleRangeShow = true"
          label-width="80"
        />
        <van-popup v-model:show="visibleRangeShow" round position="bottom">
          <van-picker
            v-model="formData.range.value"
            title="请选择"
            :columns="visibleRange"
            @confirm="onVisibleRangeFinish"
            @cancel="visibleRangeShow = false"
          />
        </van-popup>
      </div>
      <div>
        <van-field
          required
          v-if="formData.range.value === 2"
          @click="selectVisible"
          label-width="100"
          name="rate"
          label="添加可见人员"
        >
          <template #input>
            <!-- <van-rate v-model="value" /> -->
            <img
              class="icons-add"
              src="../../assets/icons/ADD_hover@2x.png"
              alt=""
            />
          </template>
        </van-field>
        <SelectDepartH5
          @selectUserAdd="selectVisibleAdd"
          maxCount="500"
          :list="formData.range.input"
          ref="selectVisible"
        />
      </div>
      <div>
        <van-field
          required
          v-model="formData.startDate.text"
          is-link
          readonly
          name="datetimePicker"
          label="创刊日期"
          placeholder="点击选择时间"
          label-width="80"
          @click="showPicker = true"
        />
        <van-popup v-model:show="showPicker" position="bottom">
          <van-datetime-picker
            v-model="formData.startDate.value"
            type="date"
            @confirm="onConfirm"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="closeShowPicker"
            title="选择年月日"
          />
        </van-popup>
      </div>
      <div>
        <van-field
          required
          v-model="formData.publishTime.isRegularText"
          is-link
          readonly
          label="出版周期"
          placeholder="请选择"
          @click="visibleisRegularShow = true"
          label-width="80"
        />
        <van-popup v-model:show="visibleisRegularShow" round position="bottom">
          <!-- <van-picker
            v-model="formData.publishTime.value"
            title="请选择"
            :columns="cycleList"
            @confirm="onCycleFinish"
            @cancel="cycleListShow = false"
          /> -->
          <van-picker
            v-model="formData.range.isRegular"
            title="请选择"
            :columns="visibleisRegular"
            @confirm="onvisibleisRegularFinish"
            @cancel="visibleisRegularShow = false"
          />
        </van-popup>

        <van-field
          v-if="formData.publishTime.isRegular == 1"
          required
          v-model="formData.publishTime.time"
          maxlength="2"
          @update:model-value="numberVer"
          label-width="80"
          placeholder="请输入"
          label="周期频次"
          type="digit"
        />

        <van-field
          v-if="formData.publishTime.isRegular == 1"
          is-link
          required
          readonly
          label-width="80"
          placeholder="请输入"
          label="周期范围"
          v-model="formData.publishTime.text"
          @click="cycleListShow = true"
        />
        <van-popup v-model:show="cycleListShow" round position="bottom">
          <van-picker
            v-model="formData.publishTime.value"
            title="请选择"
            :columns="cycleList"
            @confirm="onCycleFinish"
            @cancel="cycleListShow = false"
          />
        </van-popup>
      </div>
      <!-- 通过 validator 返回错误提示 -->
      <van-field
        v-model="formData.note"
        rows="4"
        autosize
        label-width="80"
        label="备注"
        type="textarea"
        maxlength="300"
        placeholder="期刊介绍"
        show-word-limit
      />
      <div style="margin: 16px; padding-bottom: 30px">
        <van-button round block type="primary" @click="submitData">
          提交
        </van-button>
      </div>
    </van-form>
    <van-overlay :show="confirmShow" @click="knowClick">
      <div class="wrapper" @click.stop>
        <div class="block">
          您已提交期刊创建申请，等待管理员审核处理，审核结果将通过消息助手发送通知，您也可在【我的期刊】中关注该申请的审核状态
          <div class="submit-btn">
            <van-button type="primary" @click="knowClick">知道了</van-button>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { reactive, ref } from "vue";
import { Toast } from "vant";
import upload from "../../api/upload";
import SelectPerForH5 from "@/components/SelectPerForH5.vue";
import SelectDepartH5 from "@/components/SelectDepartH5.vue";
import { message } from "ant-design-vue";
import {
  submit,
  queryRecords,
  getDepartmentByManagement,
  getQueryManagementList,
  getQueryPartyBranchList,
  getQueryFaceObjectList,
  getQueryUserInfo,
} from "@/api/createJournal";
import { ElMessage } from "element-plus";

export default {
  setup() {
    const value1 = ref("");
    const value2 = ref("");
    const value3 = ref("abc");
    const value4 = ref("");
    const digit = ref("");
    const confirmShow = ref(false);
    const pattern = /\S/;
    const fileList = ref([]);
    const formData = reactive({
      name: "",
      position: "",
      description: "",
      managementOB: { text: "", value: null },
      management: "",
      cover: "",
      department: { text: "", value: null },
      target: { text: "", input: "", value: null },
      range: { value: null, input: [], text: "" },
      cycle: { value: "", text: "" },
      startDate: { value: "", text: "" },
      publishTime: {
        text: "",
        time: null,
        value: null,
        isRegular: null,
        isRegularText: "",
      },
      manager: [],
      visible: [],
      num: 1,
      note: "",
      print: "",
      honor: "",
    });
    const fieldNames = {
      text: "label",
      value: "value",
      children: "children",
    };
    // 校验函数返回 true 表示校验通过，false 表示不通过
    const validator = (val) => /1\d{10}/.test(val);
    // 校验函数可以直接返回一段错误提示
    const validatorMessage = (val) => {
      if (val.trim().length < 1) {
        return `请输入正确内容`;
      }
    };

    const show = ref(false);
    const managementListShow = ref(false);
    const departmentListShow = ref(false);
    const targetListShow = ref(false);
    const rangeListShow = ref(false);
    const cycleListShow = ref(false);
    const fieldValue = ref("");
    const cascaderValue = ref("");
    const showPicker = ref(false);
    const visibleRangeShow = ref(false);
    const visibleisRegularShow = ref(false);
    // 选项列表，children 代表子选项，支持多级嵌套
    const options = ref([]);
    const partyBranch = ref([]);
    const faceObject = ref([]);
    const cycleList = [
      { value: 1, text: "年/期" },
      { value: 2, text: "月/期" },
      { value: 3, text: "周/期" },
      { value: 4, text: "天/期" },
    ];
    const visibleRange = [
      { value: 1, text: "全部可见" },
      { value: 2, text: "部分可见" },
    ];
    const visibleisRegular = [
      { value: 1, text: "定期" },
      { value: 0, text: "不定期" },
    ];
    const onManagementFinish = ({ selectedOptions }) => {
      managementListShow.value = false;
      formData.managementOB.text = selectedOptions
        .map((option) => option.text)
        .join("/");
      // formData.managementOB.value  = selectedOptions.map((option) => option.value).join('/');
      formData.management = selectedOptions.map((option) => option.value)[
        selectedOptions.map((option) => option.value).length - 1
      ];
      getManagement();
    };
    const onDepartmentFinish = (res) => {
      departmentListShow.value = false;
      formData.department.text = res[0].text;
      formData.department.value = res[0].value;
    };
    const onTargetFinish = (res) => {
      targetListShow.value = false;
      formData.target.text = res[0].text;
      formData.target.value = res[0].value;
    };
    const onVisibleRangeFinish = (res) => {
      console.log(
        "🚀 ~ file: createJournalH5.vue ~ line 317 ~ onVisibleRangeFinish ~ res",
        res
      );
      visibleRangeShow.value = false;

      formData.range.text = res.text;
      formData.range.value = res.value;
    };
    const onvisibleisRegularFinish = (res) => {
      visibleisRegularShow.value = false;
      formData.publishTime.isRegular = res.value;
      formData.publishTime.isRegularText = res.text;
    };
    const onCycleFinish = (res) => {
      cycleListShow.value = false;
      formData.publishTime.text = res.text;
      formData.publishTime.value = res.value;
    };
    // 全部选项选择完毕后，会触发 finish 事件
    const onFinish = ({ selectedOptions }) => {
      managementListShow.value = false;
      formData.management.value = selectedOptions
        .map((option) => option.text)
        .join("/");
    };
    const onConfirm = (value) => {
      formData.startDate.value = `${value.getYear() + 1900}-${
        value.getMonth() + 1
      }-${value.getDate()}`;
      formData.startDate.text = `${value.getYear() + 1900}-${
        value.getMonth() + 1
      }-${value.getDate()}`;
      // formData.startDate.value =  value
      showPicker.value = false;
    };
    // 校验函数可以返回 Promise，实现异步校验
    const asyncValidator = (val) =>
      new Promise((resolve) => {
        Toast.loading("验证中...");

        setTimeout(() => {
          Toast.clear();
          resolve(val === "1234");
        }, 1000);
      });

    const onFailed = (errorInfo) => {
      console.log("failed", errorInfo);
    };
    const getManagement = () => {
      let params = {
        management: formData.management,
      };
      getDepartmentByManagement(params).then((res) => {
        formData.department.text = res.data.label;
        formData.department.value = res.data.value;
      });
    };
    const getCheckedIndex = (val) => {
      let lists = partyBranch.value ? partyBranch : [];
      let index = null;
      for (let i = 0; i < lists.length; i++) {
        if (val == lists[i].value) {
          index = i;
          break;
        }
      }
      return index;
    };
    // 主管单位列表
    const getQueryManagement = () => {
      getQueryManagementList().then((res) => {
        options.value = copyTransFun(res.data);
        getQueryPartyBranch();
        getQueryFaceObject();
        getQueryUserInfoList();
      });
    };
    // 主管部门列表
    const getQueryPartyBranch = () => {
      getQueryPartyBranchList().then((res) => {
        partyBranch.value = copyTransFun(res.data);
      });
    };
    // 面象对象列表
    const getQueryFaceObject = () => {
      getQueryFaceObjectList().then((res) => {
        faceObject.value = copyTransFun(res.data);
      });
    };
    // 获取当前用户信息
    const getQueryUserInfoList = () => {
      getQueryUserInfo().then((res) => {
        formData.manager = [res.data];
      });
    };
    const copyTransFun = (arr) => {
      arr.forEach((item) => {
        if (item.label) {
          item.text = item.label;
          delete item.label;
        }
        if (item.children) {
          if (item.children.length) {
            copyTransFun(item.children);
          }
        }
      });
      return arr;
    };
    getQueryManagement();

    return {
      formData,
      options,
      fieldNames,
      partyBranch,
      cycleList,
      faceObject,
      showPicker,
      visibleRange,
      managementListShow,
      departmentListShow,
      targetListShow,
      rangeListShow,
      visibleRangeShow,
      onManagementFinish,
      onDepartmentFinish,
      onVisibleRangeFinish,
      onTargetFinish,
      onCycleFinish,
      cycleListShow,
      onConfirm,
      digit,
      fileList,
      minDate: new Date(1990, 0, 1),
      maxDate: new Date(2025, 10, 1),
      confirmShow,
      value1,
      value2,
      value3,
      value4,
      pattern,
      onFailed,
      validator,
      asyncValidator,
      show,
      onFinish,
      fieldValue,
      cascaderValue,
      getManagement,
      visibleisRegular,
      visibleisRegularShow,
      onvisibleisRegularFinish,
      getCheckedIndex,
      defaultIndex: null,
      getQueryManagement,
      getQueryPartyBranch,
      getQueryFaceObject,
      getQueryUserInfoList,
      copyTransFun,
    };
  },
  components: {
    SelectPerForH5,
    SelectDepartH5,
  },
  watch: {
    "formData.department.value"(newVal) {
      this.defaultIndex = this.getCheckedIndex(newVal);
    },
  },
  methods: {
    handleChange(file) {
      let r = this.beforeAvatarUpload(file.file);
      if (!r) {
        this.fileList = [];
        return;
      }
      upload(file.file.name, file.file).then((res) => {
        this.fileList = [
          { url: res.split("?")[0], status: res.status, imageFit: "contain" },
        ];
        this.formData.cover = res.split("?")[0];
      });
    },
    selectAdminAdd(data) {
      this.formData.manager = data;
    },
    selectVisibleAdd(data) {
      this.formData.range.input = data;
    },
    beforeAvatarUpload(rawFile) {
      const imgTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!imgTypes.includes(rawFile.type)) {
        Toast("上传图片需要为jpg,jpeg,png格式!");
        return false;
      } else if (rawFile.size / 1024 / 1024 > 2) {
        Toast("文件大小不能超过 2M!");
        return false;
      }
      return true;
    },
    numberVer(val) {
      if (val === "0") {
        Toast("周期频次不得少于1次");
        this.formData.publishTime.time = 1;
      } else if (val > 10) {
        Toast("周期频次不得多于10次");
        this.formData.publishTime.time = 10;
      }
    },
    deleteCover() {
      this.formData.cover = "";
      this.fileList = [];
      console.log(
        "🚀 ~ file: createJournalH5.vue ~ line 455 ~ deleteCover ~ this.formData.cover",
        this.formData.cover
      );
    },
    selectAdmin() {
      this.$refs.selectAdmin.selectPer();
    },
    selectVisible() {
      this.$refs.selectVisible.selectPer();
    },
    closeShowPicker() {
      this.showPicker = false;
    },
    submitData() {
      const {
        name,
        position,
        description,
        management,
        cover,
        department,
        target,
        range,
        publishTime,
        manager,
        startDate,
        note,
      } = this.formData;
      if (!name || (name && name.trim().length < 1)) {
        ElMessage.error("请输入正确的标题");
        return false;
      } else if (!position || (position && position.trim().length < 1)) {
        ElMessage.error("请输入正确的期刊定位");
        return false;
      } else if (
        !description ||
        (description && description.trim().length < 1)
      ) {
        ElMessage.error("请输入正确的期刊描述");
        return false;
      } else if (cover == "") {
        ElMessage.error("请上传封面");
        return false;
      } else if (management == "") {
        ElMessage.error("请选择主办单位");
        return false;
      } else if (!department.value) {
        ElMessage.error("请选择主管部门");
        return false;
      } else if (!manager || manager.length < 1) {
        ElMessage.error("请选择管理人员");
        return false;
      } else if (!target.value && target.value != 0) {
        ElMessage.error("请选择面向对象");
        return false;
      } else if (target.input == "") {
        ElMessage.error("请输入面向对象描述");
        return false;
      } else if (!range.value) {
        ElMessage.error("请选择可见范围");
        return false;
      } else if (range.value && range.value == 2 && range.input.length < 1) {
        ElMessage.error("请选择可见人员");
        return false;
      } else if (!startDate.text) {
        ElMessage.error("请选择创刊日期");
        return false;
      } else if (publishTime.isRegular !== 0 && publishTime.isRegular !== 1) {
        ElMessage.error("请选择出版周期");
        return false;
      } else if (
        publishTime.isRegular == 1 &&
        publishTime.value != 0 &&
        !publishTime.time
      ) {
        ElMessage.error("请输入周期频次");
        return false;
      } else if (
        publishTime.isRegular == 1 &&
        !publishTime.value &&
        publishTime.value != 0
      ) {
        ElMessage.error("请选择周期范围");
        return false;
      }

      let data = {
        name,
        position,
        description,
        management,
        cover,
        department: department.value,
        target: { value: target.value, input: target.input },
        range: { value: range.value, input: range.input },
        publishTime: {
          time: publishTime.time,
          value: publishTime.value,
          isRegular: publishTime.isRegular,
        },
        manager,
        startDate: startDate.text,
        note,
      };
      // console.log("🚀 ~ file: createJournalH5.vue ~ line 464 ~ submitData ~ data", data)
      submit(data).then((res) => {
        if (res.data2 && res.data2.code == 200) {
          // ElMessage.success("创建成功");
          this.confirmShow = true;
          // window.dcm.closeCurrentContainer()
        }
      });
    },
    knowClick() {
      this.confirmShow = false;
      window.dcm.closeCurrentContainer();
    },
  },
};
</script>
<style lang="less" scoped>
.h5-form-wrap {
  ::v-deep .van-cell-group--inset {
    margin: 0;
  }
}
.upload-desc {
  // height: 16px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  line-height: 16px;
}
.form-space {
  width: 100%;
  height: 10px;
  background: #f3f4f6;
}
.header-top {
  height: 44px;
  background: #fff;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 20;
}
.title {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 44px;
  text-align: center;
}
.icons-add {
  width: 18px;
  height: 18px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 80%;
  padding: 20px;
  // height: 120px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 8px;
  background-color: #fff;
}
.submit-btn {
  text-align: center;
  margin-top: 20px;
}
</style>
