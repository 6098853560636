import { Modal } from "ant-design-vue";
import { createVNode } from "vue";

export const modalMask = content => {
    Modal.warning({
        title: "提示",
        class: "zd-modal-container",
        icon: createVNode(<img src={require("../assets/icons/warn_icon@2x.png")} />),
        content,
        okText: "确认"
    });
};