<template>
  <div>
    <div class="shenpi">
      <!-- <div class="shenpi-t">审批流程</div> -->
      <div :key="index" style="display: flex" v-for="(i, index) in list">
        <div>
          <!-- 非最后节点且状态不是驳回的节点样式-->
          <div style="width: 8px; height: 8px; border: 2px solid #286cfb; border-radius: 50%; margin-top: 9px" v-if="i.operation != 2 && index != list.length - 1">
            <div style="width: 2px; height: 42px; background: #286cfb; margin-top: 10px; margin-left: 1px"></div>
          </div>
          <!-- 非最后节点且驳回的节点样式-->
          <div style="width: 8px; height: 8px; border: 2px solid #f5454e; border-radius: 50%; margin-top: 9px" v-if="i.operation == 2 && index != list.length - 1"></div>
          <div style="width: 2px; height: 42px; background: #286cfb; margin-top: 4px; margin-left: 3px" v-if="i.operation == 2 && index != list.length - 1"></div>
          <!-- 最后节点且驳回的节点样式-->
          <div style="width: 8px; height: 8px; border: 4px solid #f5454e; border-radius: 50%; margin-top: 9px" v-if="i.operation == 2 && index == list.length - 1">
            <div style="width: 2px; height: 42px; background: #c5c5c5; margin-top: 10px; margin-left: -1px"></div>
          </div>
          <!-- 最后节点且不是驳回的节点样式-->
          <div style="width: 8px; height: 8px; border: 4px solid #286cfb; border-radius: 50%; margin-top: 9px" v-if="i.operation != 2 && index == list.length - 1">
            <div style="width: 2px; height: 42px; background: #c5c5c5; margin-top: 10px; margin-left: -1px"></div>
          </div>
        </div>

        <div style="margin-left: 10px" v-if="i.operation != 2 && index != list.length - 1">
          <div class="spenpizhuangt">
            <span>{{ i.status }}</span>
            <span v-if="i.operation == 1 && i.logId" class="back" @click="viewHistorical(i.logId)">
              查看历史版本<el-icon><ArrowRightBold /></el-icon
            ></span>
          </div>
          <p class="spenpitime">{{ i.updatedAt }}</p>
        </div>
        <div style="margin-left: 10px" v-if="i.operation == 2">
          <div class="spenpizhuangt" style="color: #f5454e">{{ i.status }}</div>
          <p class="spenpitime">{{ i.updatedAt }}</p>
        </div>
        <div style="margin-left: 10px" v-if="i.operation != 2 && index == list.length - 1">
          <div class="spenpizhuangt" style="color: #286CFB">{{ i.status }}</div>
          <p class="spenpitime">{{ i.updatedAt }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowRightBold } from "@element-plus/icons-vue";
export default {
  components: {
    ArrowRightBold,
  },
  props: {
    list: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {};
  },
  methods: {
    viewHistorical(logId) {
      this.$emit("viewHistorical", logId);
    },
  },
};
</script>

<style lang="scss" scoped>
.shenpi {
  // margin-top: 29px;
  margin-left: 16px;
  width: 688px;
  padding-bottom: 16px;
  .shenpi-t {
    margin-bottom: 16px;
    width: 64px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
  }

  .content {
    .span-c {
      width: 630px;
      display: block;
      min-height: 24px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.9);
      line-height: 24px;
    }

    .span-time {
      height: 22px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 22px;
    }
  }
}

.submit {
  margin: auto;
  display: flex;
  align-items: center;

  .sbutton {
    margin-bottom: 24px;
    width: 88px;
    height: 32px;
    border-radius: 3px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
  }
}
.spenpizhuangt {
  display: inline-block;
  width: 620px;
  word-wrap: break-word;
  white-space: normal;
  font-size: 14px;
  font-family: PingFangSC-Regular, sans-serif;
  color: rgba(0, 0, 0, 0.5);
  line-height: 24px;
  font-weight: 400;
  .back {
    cursor: pointer;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #21c05e;
    margin-left: 16px;
    .el-icon {
      font-size: 12px;
      top: 1px;
    }
  }
}
.spenpitime {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  line-height: 22px;
}
</style>
