<template>
  <div class="main">
    <div class="zhendi">
      <div class="title">
        <img class="fanhuiimg" @click="fanhui" src="../../assets/icons/pcindex/b3b4.png" />
        <span class="span-t1">阵地备案</span>
      </div>
      <el-form ref="formRef" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="阵地类型" prop="cate">
          <el-cascader
            ref="cascaderRef"
            popper-class="cascader-popper-class"
            v-model="form.cate"
            :options="options"
            :props="{value: 'id', label: 'name'}"
            placeholder="请选择阵地类型"
            @change="handleChange"
          >
            <template #default="{ node, data }">
              <el-tooltip
                v-if="data.name.length > 14"
                class="box-item"
                effect="dark"
                :content="data.name"
                placement="top-start"
              >
                <span class="title">{{ data.name }}</span>
              </el-tooltip>
              <span v-else class="title">{{ data.name }}</span>
            </template>
          </el-cascader>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="所属平台" prop="mainManagement">
          <el-input v-model="form.mainManagement" placeholder="请输入所属平台" />
        </el-form-item>
        <el-form-item
          v-for="(item, index) in form.tableData"
          :key="`${item.timeKey}-${item.id}`"
          :label="item.name"
          :prop="`tableData[${index}].value`"
          :rules="[{
              required: item.isRequired ? true : false, message: '请完善内容'
            }]"
        >
          <template #label>
            <span>{{ item.name }}</span>
            <el-tooltip
              v-if="item.description"
              class="box-item"
              popper-class="tooltip-label"
              effect="light"
              :content="item.description"
              placement="top-start"
            >
              <img class="tooltip-img" src="../../assets/icons/tooltip_icon.png" alt />
            </el-tooltip>
            <div></div>
          </template>
          <el-input
            v-if="item.type === 0"
            v-model="item.value"
            type="textarea"
            maxlength="1000"
            :rows="2"
            placeholder="请输入"
            show-word-limit
          />
          <el-select
            v-else-if="item.type === 1"
            v-model="item.value"
            popper-class="select-popper-class"
            placeholder="请选择"
          >
            <el-option v-for="option in item.content" :key="option" :label="option" :value="option">
              <template #default>
                <el-tooltip
                  v-if="option.length > 28"
                  class="box-item"
                  effect="dark"
                  :content="option"
                  placement="top-start"
                >
                  <span class="title">{{ option }}</span>
                </el-tooltip>
                <span v-else class="title">{{ option }}</span>
              </template>
            </el-option>
          </el-select>
          <div v-else-if="item.type === 2" class="uploadfile" @click="showfaupload(item)">
            <img style="height:28px;width: 29px" src="../../assets/icons/pcindex/10.png" />
            <span
              style="display: flow-root"
              class="span-u"
            >点击可上传附件，支持doc、docx、pdf、xls、xlsx、ppt、pptx、zip类型文件，30M以内</span>
          </div>
          <el-date-picker
            v-else-if="item.type === 3"
            v-model="item.value"
            value-format="YYYY-MM-DD"
            :editable="false"
            type="date"
            placeholder="请选择"
            style="width: 100%"
          />
        </el-form-item>
        <div class="submit-btn">
          <el-button type="primary" :loading="loading" @click="submitForm(formRef)">提交</el-button>
        </div>
      </el-form>
    </div>
    <el-dialog
      v-model="dialogFormVisible"
      custom-class="zduploadfiledialog"
      class="zduploadfiledialog"
      title="上传附件"
      destroy-on-close
    >
      <div class="bhnr">
        <a-upload
          :customRequest="uploadcontentSumbit"
          :accept="acceptType"
          :beforeUpload="beforeUpload"
        >
          <a-button>上传附件</a-button>
          <span class="span-u">支持doc、docx、pdf、xls、xlsx、ppt、pptx、zip类型文件，30M以内</span>
        </a-upload>
        <el-table :data="filelist" style="width: 496px; height: 252px;margin-top:24px;">
          <el-table-column prop="name" label="文件名" min-width="20%" :show-overflow-tooltip="true" />
          <el-table-column prop="size" label="大小" min-width="20%" />
          <el-table-column fixed="right" label="操作" width="150px">
            <template v-slot:default="scope">
              <el-button link type="primary" @click="delFile(scope.row.uid)" class="tableOp">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <template #footer class="footer">
        <span class="dialog-footer">
          <el-button @click="delectVisible ()">取消</el-button>
          <el-button type="primary" @click="updateVisible ()">保存</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { ref, onMounted, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import { message } from "ant-design-vue";
import { ElMessage } from "element-plus";
import upload from "../../api/upload";
import {
  zdManagerListAjax,
  fieldManagerListAjax,
  nrecordDetailAjax,
  nameRenameAjax
} from "@/api/zdManager";
import { addPositionRecord } from "@/api/apis/index";

export default {
  name: "zdBeian",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const loading = ref(false);
    const formRef = ref();
    const cascaderRef = ref();
    const form = ref({
      cate: "",
      name: "",
      mainManagement: "",
      tableData: []
    });
    const dialogFormVisible = ref(false);
    const filelist = ref([]);
    const newfileList = ref({});
    const acceptType = ".doc, .docx, .pdf, .xls, .xlsx, .ppt, .pptx, .zip";
    const options = ref([]);
    let validateContent = async (_rule, value) => {
      if (!value.length) {
        return Promise.reject("请输入名称");
      } else if (!cascaderRef.value.getCheckedNodes()[0]) {
        return Promise.reject("请选择阵地类型");
      } else {
        const reg = new RegExp(" / ", "g");
        const res = await nameRenameAjax({
          name: form.value.name,
          id: route.query.id && Number(route.query.id),
          positionType: cascaderRef.value
            .getCheckedNodes()[0]
            ?.text?.replace(reg, "-")
        });
        if (res.data2.code === 423) {
          return Promise.reject("该名称已被备案");
        } else {
          return Promise.resolve();
        }
      }
    };
    const rules = ref({
      cate: [
        {
          required: true,
          trigger: "blur",
          message: "请选择阵地类型"
        }
      ],
      name: [
        {
          required: true,
          trigger: "blur",
          validator: validateContent
        }
      ],
      mainManagement: [
        {
          required: true,
          trigger: "blur",
          message: "请输入所属平台"
        }
      ]
    });
    onMounted(() => {
      zdManagerListAjax().then(res => {
        if (res.data2.code === 200) {
          options.value = res.data;
        }
      });
      if (route.query.id) {
        _nrecordDetailAjax(route.query.id);
      }
    });
    function _nrecordDetailAjax(id) {
      nrecordDetailAjax(id).then(res => {
        if (res.data2.code === 200) {
          form.value = {
            cate: res.data.positionTypeId,
            name: res.data.name,
            mainManagement: res.data.mainBody,
            tableData: res.data.fieldContent?.map(ele => {
              ele.timeKey = Date.now();
              if (ele.type === 2) {
                ele.value = JSON.parse(ele.value);
              }
              return ele;
            })
          };
        }
      });
    }
    function _fieldManagerListAjax(ids) {
      const id = ids.slice(-1)[0];
      fieldManagerListAjax(id).then(res => {
        if (res.data2.code === 200) {
          form.value.tableData =
            res.data?.fields?.map(ele => {
              ele.value = "";
              ele.timeKey = Date.now();
              return ele;
            }) || [];
        } else {
          form.value.tableData = [];
        }
        nextTick(() => {
          formRef.value.clearValidate();
          if (form.value.name) {
            formRef.value.validateField(["name"]);
          }
        });
      });
    }

    function handleChange(id) {
      _fieldManagerListAjax(id);
    }
    const fanhui = () => {
      router.back();
    };
    const showfaupload = item => {
      dialogFormVisible.value = true;
      filelist.value = (item.value && [].concat(item.value)) || [];
      newfileList.value = item;
    };
    function beforeUpload(file) {
      const ext = "." + file.name.split(".").pop();
      if (!acceptType.includes(ext)) {
        ElMessage.error("文件格式不正确");
        return false;
      }
      if (file.size / 1024 / 1024 > 30) {
        ElMessage({
          message: "文件超过30M",
          type: "warning"
        });
        return false;
      }
    }
    //文稿附件上传
    // function uploadcontentSumbit(data) {
    //   console.log("上传的文件", data);
    //   let size = "";
    //   if (data.file.size > 1024 * 1024) {
    //     size = (data.file.size / (1024 * 1024)).toFixed(2) + "M";
    //   } else {
    //     size = (data.file.size / 1024).toFixed(2) + "kb";
    //   }
    //   filelist.value.push({
    //     format: data.file.format,
    //     name: data.file.name,
    //     size: size,
    //     uid: data.file.uid
    //   });
    //   upload(data.file.name, data.file, filelist.value).then(url => {
    //     filelist.value.forEach(ele => {
    //       if (!ele.url) {
    //         ele.url = url.split("?")[0];
    //       }
    //     });
    //     console.log("文件上唇成功", filelist.value);
    //   });
    // }
    async function uploadcontentSumbit(data) {
      const url = await upload(data.file.name, data.file);
      const a = url.split("?");
      let size = "";
      if (data.file.size > 1024 * 1024) {
        size = (data.file.size / (1024 * 1024)).toFixed(2) + "M";
      } else {
        size = (data.file.size / 1024).toFixed(2) + "kb";
      }
      filelist.value.push({
        format: data.file.format,
        name: data.file.name,
        size: size,
        url: a[0],
        uid: filelist.value.length + 1
      });
    }
    function delFile(_uid) {
      filelist.value = filelist.value.filter(({ uid }) => uid != _uid);
    }
    function updateVisible() {
      dialogFormVisible.value = false;
      form.value.tableData.forEach(ele => {
        if (ele.id === newfileList.value.id) {
          ele.value = filelist.value;
        }
      });
      formRef.value.validate();
      filelist.value = [];
    }
    function delectVisible() {
      filelist.value = [];
      dialogFormVisible.value = false;
    }
    const submitForm = formEl => {
      if (!formEl) return;
      loading.value = true;
      formEl.validate((valid, invalidFields) => {
        if (valid) {
          const reg = new RegExp(" / ", "g");
          const params = {
            fieldContent: form.value.tableData?.map(ele => {
              if (ele.type === 2) {
                ele.value = JSON.stringify(ele.value);
              }
              return ele;
            }),
            id: route.query.id && Number(route.query.id),
            mainBody: form.value.mainManagement,
            name: form.value.name,
            positionType: cascaderRef.value
              .getCheckedNodes()[0]
              .text.replace(reg, "-"),
            positionTypeId: form.value.cate
          };
          console.log("获取的参数", params);
          const method = route.query.id ? "put" : "post";

          addPositionRecord(method, params).then(res => {
            if (res.data2.code === 200) {
              message.success("备案已提交");
              router.back();
            }
          });
          loading.value = false;
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    return {
      formRef,
      form,
      options,
      rules,
      filelist,
      newfileList,
      dialogFormVisible,
      acceptType,
      loading,
      cascaderRef,
      beforeUpload,
      submitForm,
      fanhui,
      showfaupload,
      uploadcontentSumbit,
      delFile,
      updateVisible,
      delectVisible,
      handleChange
    };
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/cascader.scss";

.main {
  margin: 0 auto;
  overflow: hidden;
}

@media screen and (min-width: 820px) {
  .main {
    width: 728px;
  }
}

@media screen and (min-width: 960px) {
  .main {
    width: 728px;
  }
}

@media screen and (min-width: 1200px) {
  .main {
    width: 728px;
  }
}
.zhendi {
  width: 728px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 16px;
  padding-top: 24px;
  padding-bottom: 1px;
  margin-bottom: 23px;
  /deep/.el-form {
    width: 80%;
    margin: 24px auto;
    .el-form-item__label {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.9);
      height: auto;
      line-height: 20px;
      margin-top: 5px;
      .tooltip-img {
        height: 17px;
        width: 17px;
        margin-top: -10px;
      }
    }
    .el-cascader {
      width: 100%;
    }
    .el-select {
      width: 100%;
    }
    .el-textarea__inner {
      padding-bottom: 18px;
    }
    .uploadfile {
      display: flex;
      cursor: pointer;
      width: 400px;
      align-items: center;

      .span-u {
        height: 32px;
        font-size: 11px;
        font-weight: 400;
        color: #999999;
        line-height: 16px;
      }
    }
    .submit-btn {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      .el-button {
        width: 88px;
      }
    }
  }
  .modal {
    width: 560px;
    height: 460px;
    background: #ffffff;
    border-radius: 6px;
  }
  .title {
    height: 22px;
    display: flex;
    align-items: center;

    .fanhuiimg {
      height: 16px;
      width: 16px;
      margin-left: 16px;
      cursor: pointer;
    }

    .span-t1 {
      width: 64px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2f3238;
      line-height: 22px;
      margin-left: 10px;
    }
  }
}
.select-popper-class {
  .title {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 400px;
  }
}
</style>
<style lang="scss">
.zduploadfiledialog {
  height: 460px;
  --el-dialog-width: 560px;

  .bhnr {
    overflow: auto;
    margin-top: 20px;
    margin-left: 32px;

    .span-u {
      height: 32px;
      font-size: 11px;
      font-weight: 400;
      color: #999999;
      line-height: 16px;
      margin-left: 16px;
    }

    .e-sbutton {
      width: 88px;
      height: 32px;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 3px;
      border: 1px solid #dcdcdc;
      margin-bottom: 25px;
    }

    .ant-upload-list {
      display: none;
    }
  }
  .el-dialog__header {
    width: 560px;
    height: 48px;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
  }

  .el-dialog__footer {
    width: 560px;
    text-align: right;
  }

  .el-dialog__body {
    margin-top: 24px;
    height: 320px;
    padding: 0px;
  }

  .el-dialog__title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    line-height: 24px;
  }

  /* .footer { */

  .dialog-footer {
    margin-top: 24px;
    width: 440px;
    text-align: right;
    /* border-top: 1px solid #d9d9d9; */
    .el-button {
      width: 88px;
      height: 32px;
    }
    .el-button--primary {
      background: #286cfb;
    }
  }
  /* } */
}
.tooltip-label {
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.15);
}
.tooltip-label.is-light {
  border: none;
}
.cascader-popper-class {
  .el-cascader-node.in-active-path {
    .el-icon {
      color: #286cfb;
    }
  }
  .el-cascader-node:not(.is-disabled):hover {
    background: rgba(40, 108, 251, 0.1);
  }
  .el-cascader-node.is-active {
    .el-icon {
      color: #286cfb;
    }
  }
}
</style>