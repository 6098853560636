<template>
  <div class="main">

    <div class="content" v-loading="loading">
      <HelpCenter />
      <div class="title">
        <img v-if="false" class="fanhuiimg" @click="fanhui" src="../../assets/icons/pcindex/b3b4.png" />
        <span class="span-t1">我的期刊</span>
      </div>
      <div class="line">
        <el-card class="card box-card" @click="newly">
          <img class="edit" @click="newly" src="../../assets/img/newly.png" />
          <div class="newly">创建期刊</div>
        </el-card>
        <el-card class="box-card" v-for="item in list" :key="item">
          <div class="img">
            <img src="../../assets/img/bottom.png" alt="" class="bottom_png" />
            <el-image :src="item.cover" :preview-src-list="[item.cover]" :initial-index="0" fit="cover"
              class="img_png"></el-image>
            <!-- <img :src="item.cover" alt="" class="img_png" /> -->
          </div>
          <div class="info" @click="createInformation(item)">
            <h3 style="line-height: 20px; margin-bottom: 5px">
              <span
                style="font-size: 14px; font-family: PingFangSC-Medium, PingFang SC; font-weight: 500; color: rgba(0, 0, 0, 0.9); line-height: 20px">
                {{ item.name }}
              </span>
              <span class="dot rebut" v-if="item.status == 2"> 审核驳回</span>
              <span class="dot review" v-if="item.status == 0">审核中</span>
            </h3>
            <p class="text">主办单位：{{ findManagement(item.management) }}</p>
            <p class="text" v-if="item.status == 1">期刊号：{{ item.journalCode }}</p>
            <p class="text manager">
              <span>管理人：</span>
              <span v-for="(k, index) in item.manager" :key="index" style="margin-right: 5px"> {{ k.name }}</span>
            </p>
            <p class="introduce" style="-webkit-box-orient: vertical">
              {{ item.description }}
            </p>
            <h3 class="info_bottom">
              <span style="margin-right: 12px; cursor: pointer" @click.stop="manage(item)"
                v-if="!isIHaier && item.status == 1 && item.userForJournal == 2 && item.xueHaiId"> 创作管理</span>
              <span style="margin-right: 12px; cursor: pointer" @click.stop="details(item)"
                v-if="item.status == 1 && item.xueHaiId">查看期刊</span>
              <span style="cursor: pointer" @click="createInformation(item)">期刊信息</span>
            </h3>
          </div>

          <el-dropdown trigger="click" placement="bottom-end" v-if="item.hasPassed !== 1 || item.userForJournal !== 1">
            <span class="el-dropdown-link">
              <el-icon style="transform: rotate(90deg); color: #8c8c8c">
                <MoreFilled />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="revise(item.id)">
                  <el-icon style="font-size: 14px">
                    <Edit />
                  </el-icon>修改信息</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import { list } from "@/api/myJournals";
import { department } from "@/assets/common";
import { optionsList } from "../createJournal/data";
import { MoreFilled, Edit } from "@element-plus/icons-vue";
import { isIHaier } from '@/utils/platform'
import { dcmOpenNewContainer } from '@/utils/Ihaier';
import HelpCenter from '@/components/HelpCenter.vue';
export default {
  components: {
    MoreFilled,
    Edit,
    HelpCenter
  },
  data() {
    return {
      list: [],
      optionsList: optionsList,
      coverList: [],
      loading: true,
      isIHaier
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    department,
    fanhui() {
      this.$router.go(-1);
    },
    // 列表
    getList() {
      this.loading = true;
      list().then((res) => {
        if (res) {
          console.log(res, 'res')
          this.list = res.data;
          this.loading = false;
        } else {
          this.loading = false;
        }

      });
    },
    // 创建期刊
    newly() {
      this.$router.push({ path: "/createJournal" });
    },
    // 创建信息
    createInformation(item) {
      this.$router.push({
        path: "/createInformation",
        query: { id: item.id, type: 1 },
      });
    },
    // 期刊管理
    manage(item) {
      dcm.jumpToPlatform({
        appId: window.origin.includes("-test") ? "2JOJWvYhSyFQ2DrwNc5qK" : "ZXwz_lm6xROWatzvEDYme", // 正式
        url: window.origin.includes("-test") ? `http://xuehai-pc-test.lanbenzi.cn/#/journals/manage/${item.xueHaiId}` : `http://xuechuang.hilook.com.cn/#/journals/manage/${item.xueHaiId}`, // 正式环境
      });
    },
    // 期刊详情
    details(item) {
      // dcm.jumpToPlatform({
      //   appId: "q4BLZlJPOEirG5Sob4st8",
      //   // appId: "2KrlWqv2Q-ECfzCYOr5Oo", // 正式
      //   url: `http://haixueh5-test.lanbenzi.cn/#/pages/journal/journalDetailPreview?periodical_id=${item.xueHaiId}&allowShare=false`, // 测试环境
      //   // url: `http://xue.hilook.com.cn/#/pages/journal/journalDetailPreview?periodical_id=${item.xueHaiId}&allowShare=false`, // 正式环境
      // });
      if (isIHaier) {
        dcmOpenNewContainer({

          url: window.origin.includes('-test')
            ? `https://haixueh5-test.lanbenzi.cn:8443/#/pages/journal/journalDetail?periodical_id=${item.xueHaiId}&allowShare=false`
            : `https://xue.hilook.com.cn/#/pages/journal/journalDetail?periodical_id=${item.xueHaiId}&allowShare=false`
        });
      } else {
        dcm.openNewContainer({
          title: "",
          url: window.origin.includes("-test")
            ? `http://haixueh5-test.lanbenzi.cn/#/pages/journal/journalDetail?periodical_id=${item.xueHaiId}&allowShare=false`
            : `http://xue.hilook.com.cn/#/pages/journal/journalDetail?periodical_id=${item.xueHaiId}&allowShare=false`,
          hideTitleBar: false, // 是否隐藏 titlebar ，默认展示
        });
      }

    },
    revise(id) {
      this.$router.push({
        path: "/createJournal",
        query: { id: id, type: "edit" },
      });
    },
    findOptionsText(val) {
      let parent, children;
      this.optionsList.forEach((item) => {
        item.children.forEach((ele) => {
          if (ele.value == val) {
            parent = item;
            children = ele;
          }
        });
      });
      return `${parent.text}/${children.text}`;
    },
    findManagement(val) {
      if (val.children && val.children.length > 0) {
        let str = val.label;
        val.children.forEach((item, index) => {
          val.label == item.label ? (str = item.label) : (str += `/${item.label}`);
          if (item.children && item.children.length > 0) {
            this.findManagement(item.children);
          }
        });
        return str;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
p {
  margin-bottom: 0;
}

.main {
  margin: 0 auto;
  overflow: hidden;
  padding: 16px;
}

@media screen and (min-width: 960px) {
  .main {
    width: 960px;
  }
}

@media screen and (min-width: 1200px) {
  .main {
    width: 960px;
  }
}

.content {
  width: 928px;
  height: 700px;
  background: #ffffff;
  border-radius: 10px;
  padding: 17px;
  box-sizing: border-box;
}

.title {
  height: 22px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .fanhuiimg {
    height: 16px;
    width: 16px;
    cursor: pointer;
  }

  .span-t1 {
    width: 64px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2f3238;
    line-height: 22px;
    margin-left: 10px;
  }
}

.line {
  max-height: 600px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;
  padding: 3px 3px; // 盒子阴影

  ::v-deep .box-card {
    width: 48.4%;
    height: 186px;
    // margin-right: 20px;
    margin-bottom: 16px;
    text-align: center;
    line-height: 170px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.06) !important;
    border-radius: 6px;
    border: 1px solid !important;
    border-image: linear-gradient(135deg, rgba(234, 235, 238, 0.6), rgba(208, 211, 216, 0.2)) 1 1 !important;
    cursor: pointer;
  }

  .card {
    position: relative;
    text-align: center;
    margin-right: 23px;
    background: rgba(40, 108, 251, 0.1);
    box-shadow: 0px 2px 8px 2px rgba(40, 108, 251, 0.1) !important;
    border-radius: 6px;
    border: 1px solid rgba(40, 108, 251, 0.15) !important;

    .edit {
      margin-top: 10px;
      margin-left: 50%;
      transform: translate(-50%, 0);
      cursor: pointer;
      width: 172px;
      height: 111px;
    }

    .newly {
      position: absolute;
      bottom: 24px;
      left: 50%;
      transform: translate(-50%, 0);
      cursor: pointer;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 20px;
    }
  }

  .box-card {
    position: relative;
    padding: 14px 10px;

    .el-card__body {
      .img {
        position: relative;
        width: 115px;
        height: 154px;
        margin-right: 12px;
        border-radius: 3px;
        border: 2px solid rgba(0, 0, 0, 0.5);

        .img_png {
          width: 100%;
          height: 100%;
          vertical-align: top;
          // object-fit: scale-down; //scale-down：不变形，可能填充不满
        }

        .bottom_png {
          position: absolute;
          bottom: -11px;
          left: 0;
          width: 100%;
          height: 27px;
          z-index: 10;
        }
      }

      .info {
        flex: 1;
        text-align: left;

        ::v-deep .text {
          line-height: 17px;
          font-size: 12px;
          margin-bottom: 2px;
          color: rgba(0, 0, 0, 0.5);
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
        }

        .dot {
          // width: 4px;
          // height: 4px;
          // background: #ff4224;
          display: inline-block;
          // border-radius: 50%;
          // margin-bottom: 2px;
          font-size: 11px;
          margin-left: 10px;
        }

        .review {
          width: 45px;
          height: 20px;
          background: rgba(251, 156, 0, 0.15);
          border-radius: 3px;
          color: #fb9c00;
          line-height: 20px;
          text-align: center;
        }

        .rebut {
          width: 56px;
          height: 20px;
          background: rgba(255, 66, 36, 0.15);
          border-radius: 3px;
          color: #ff4224;
          line-height: 20px;
          text-align: center;
        }

        .introduce {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          word-wrap: break-word;
          word-break: break-all;
          overflow: hidden;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.4);
          line-height: 18px;
          margin-bottom: 2px;
        }

        .manager {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          margin-bottom: 5px;
        }
      }

      .info_bottom {
        width: 100%;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #286cfb;
        line-height: 16px;
        position: absolute;
        bottom: 4px;
        left: 33%;
      }
    }
  }

  ::v-deep .el-dropdown {
    height: 13px;
  }

  ::v-deep .box-card:nth-of-type(odd) {
    margin-right: 23px;
  }

  ::v-deep .el-card__body {
    display: flex;
    flex-direction: row;
    padding: 0;
  }

  .examine {
    font-size: 11px;
    color: #ff4224;
    line-height: 16px;
    margin-bottom: 4px;
  }
}
</style>
<style>
.el-dropdown-menu__item {
  font-size: 14px !important;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5) !important;
  line-height: 20px;
  padding: 5px 14px !important;
}

.el-dropdown-menu__item:not(.is-disabled):focus {
  background-color: #f7f7f7 !important;
}

.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #f7f7f7 !important;
}

body {
  -webkit-user-drag: none;
}

.el-image-viewer__mask {
  cursor: default;
}

.el-image-viewer__canvas {
  cursor: default;
}
</style>
