<template>
  <div class="main">
    <div class="neirong">
      <div class="title">
        <img
          class="fanhuiimg"
          :style="background"
          @click="fanhui"
          src="../../assets/icons/pcindex/b3b4.png"
        />
        <span class="span-t1">内容备案</span>
      </div>

      <a-form
        ref="formRef"
        class="nrfrom"
        :model="contentRecord"
        name="normal_login"
        @finish="addcontentRecord()"
        @finishFailed="onFinishFailed"
      >
        <div class="div-i">
          <a-form-item class="item-i" name="cate">
            <span class="span-x">*</span>
            <span class="span-i">内容类型</span>
            <a-radio-group
              class="radio-a"
              v-model:value="contentRecord.cate"
              name="radioGroup1"
              @change="cateChange"
            >
              <a-radio :value="0" checked>文稿</a-radio>
              <a-radio :value="1">图片</a-radio>
              <a-radio :value="2">视频</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            class="item-i"
            name="title"
            :rules="[{ required: true, validator: validateTitle, trigger: 'blur' }]"
          >
            <span class="span-x">*</span>
            <span class="span-i">标题</span>
            <!-- <template #extra v-if="editCount > 65">
              <span style="color: red;margin-left: 208px">输入内容不能超过65个字符</span>
            </template>-->
            <div class="edit-title-box">
              <div
                ref="editTitleRef"
                class="edit-title"
                placeholder="请输入标题"
                contenteditable="true"
                v-html="contentRecord.title"
                @input="editTitleCount('editTitleRef')"
                @blur="handleInput('editTitleRef')"
              ></div>
              <span class="edit-count">{{ editCount }} / 65</span>
            </div>
            <!-- <a-input
              v-else
              class="input-i"
              placeholder="请输入标题"
              v-model:value="contentRecord.title"
              show-count
              :maxlength="65"
            />-->
          </a-form-item>
          <div v-if="jz==1">
            <div class="uimg">
              <span class="span-x">*</span>
              <span class="span-i">上传图片</span>
              <div class="edit-i">
                <div class="card">
                  <div class="upload" style=" margin-bottom: 24px;">
                    <a-upload
                      accept="image/jpg, image/jpeg, image/bmp, image/gif, image/png"
                      :customRequest="uploadSumbit"
                    >
                      <a-button>上传图片</a-button>
                      <span class="span-u">支持jpg,jpeg,bmp,gif,png类型文件，单张5M内</span>
                    </a-upload>
                  </div>
                  <div
                    class="upload"
                    style=" margin-bottom: 24px;height: 252px;"
                    v-if="imglist.length==0"
                  >
                    <a-upload-dragger
                      v-if="imglist.length==0"
                      name="file"
                      :multiple="true"
                      :customRequest="uploadSumbit"
                    >
                      <p class="ant-upload-text">点击上方”上传图片”或将文件拖拽到此区域</p>
                    </a-upload-dragger>
                  </div>
                  <div class="imgl" v-if="imglist.length>0" style="overflow: scroll">
                    <a-upload
                      @preview="handlePreview"
                      v-model:file-list="imglist"
                      list-type="picture-card"
                    ></a-upload>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>

          <div v-if="jz==2" style=" margin-bottom: 24px;">
            <div class="uimg">
              <span class="span-x">*</span>
              <span class="span-i">上传视频</span>
              <div class="edit-i">
                <div class="card">
                  <div class="upload" style=" margin-bottom: 24px;">
                    <a-upload
                      :customRequest="uploadvedioSumbit"
                      accept="video/mp4, video/m3u8, video/flv"
                    >
                      <div style=" display: flex;">
                        <a-button>上传视频</a-button>
                        <span
                          class="span-u"
                          style=" width: 297px;"
                        >仅支持仅支持mp4、m3u8、flv格式视频文件，建议视频时间在90分钟内</span>
                      </div>
                    </a-upload>
                  </div>
                  <div
                    class="upload"
                    style=" margin-bottom: 24px;height: 252px;"
                    v-if="videolist.length==0"
                  >
                    <a-upload-dragger
                      v-if="videolist.length==0"
                      name="file"
                      :multiple="true"
                      :customRequest="uploadvedioSumbit"
                    >
                      <p class="ant-upload-text">点击上方”上传视频”或将文件拖拽到此区域</p>
                    </a-upload-dragger>
                  </div>
                  <div class="imgl" v-if="videolist.length>0" style="overflow: scroll">
                    <a-upload
                      @preview="handlevPreview"
                      v-model:file-list="videolist"
                      list-type="picture-card"
                    ></a-upload>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>

          <div v-if="jz==0" style=" margin-bottom: 24px;">
            <a-form-item class="edit" name="content">
              <span class="span-i" style="margin-bottom:270px">文稿内容</span>
              <div class="edit-i">
                <wangEditer @updatecontent="updatecontent" v-model:editor="editor"></wangEditer>
              </div>
            </a-form-item>
            <span class="warn" v-if="warnclass">错敏词工具仅做提醒，具体风险由业务方判断</span>
            <div class="uploadfile" style="margin-top: 10px" @click="showfaupload(1)">
              <img style="height:28px;width: 29px" src="../../assets/icons/pcindex/10.png" />
              <span
                style="display: flow-root"
                class="span-u"
              >点击可上传附件，支持doc、docx、pdf、xls、xlsx、ppt、pptx、zip类型文件，30M以内</span>
            </div>
          </div>
          <a-form-item
            validateOnRuleChange="false"
            class="select cateType"
            name="positionId"
            :rules="[{ required: true, message: '请选择阵地类型!' }]"
          >
            <span class="span-x">*</span>
            <span class="span-i">阵地类型</span>
            <el-cascader
              v-model="contentRecord.positionId"
              ref="cascaderRef"
              style="width: 551px;margin-right: 46px"
              popper-class="cascader-popper-class"
              class="cascader-i"
              :props="{value: 'id', label: 'name', lazy: true, lazyLoad:_nrzdCascaderAjax}"
              placeholder="请选择阵地类型"
            >
              <template #default="{ node, data }">
                <el-tooltip
                  v-if="data.name.length > 14"
                  class="box-item"
                  effect="dark"
                  :content="data.name"
                  placement="top-start"
                >
                  <span class="title">{{ data.name }}</span>
                </el-tooltip>
                <span v-else class="title">{{ data.name }}</span>
              </template>
            </el-cascader>
          </a-form-item>
          <a-form-item class="select" name="publishTime">
            <span v-if="contentRecord.cate" class="span-x">*</span>
            <span class="span-i">发布时间</span>
            <a-space class="select-a">
              <a-date-picker class="select-i" style=" margin-right: 24px;" v-model:value="udate" />
              <a-form-item
                name="time"
                key="time"
                :rules="[{ required: contentRecord.cate !== 0, message: '请选择发布时间!' }]"
              >
                <a-time-picker
                  class="select-i"
                  v-model:value="contentRecord.time"
                  value-format="HH:mm:ss"
                />
              </a-form-item>
            </a-space>
          </a-form-item>
        </div>

        <a-form-item class="button-i" style="margin-top: 40px">
          <a-button class="sbutton" type="primary" html-type="submit">提交</a-button>
        </a-form-item>
      </a-form>
    </div>

    <div class="uploadfiledialog">
      <el-dialog v-model="dialogFormVisible" title="上传附件" destroy-on-close>
        <div class="bhnr">
          <a-upload
            :customRequest="uploadcontentSumbit"
            :accept="acceptType"
            :beforeUpload="beforeUpload"
          >
            <a-button>上传附件</a-button>
            <span class="span-u">支持doc、docx、pdf、xls、xlsx、ppt、pptx、zip类型文件，30M以内</span>
          </a-upload>
          <el-table :data="filelist" style="width: 496px; height: 252px;margin-top:24px;">
            <el-table-column prop="name" label="文件名" min-width="20%" :show-overflow-tooltip="true" />
            <el-table-column prop="size" label="大小" min-width="20%" />
            <el-table-column fixed="right" label="操作" width="150px">
              <template v-slot:default="scope">
                <el-button link type="primary" @click="delet(scope.row.uid)" class="tableOp">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <template #footer class="footer">
          <span class="dialog-footer">
            <el-button style="width: 88px;height: 32px;" @click="delectVisible ()">取消</el-button>
            <el-button
              type="primary"
              style="background: #286CFB;width: 88px;height: 32px;"
              @click="updateVisible ()"
            >保存</el-button>
          </span>
        </template>
      </el-dialog>
      <a-modal
        :visible="previewVisible"
        :title="previewTitle"
        :footer="null"
        @cancel="handleCancel"
      >
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
      <a-modal
        :visible="vpreviewVisible"
        :title="previewTitle"
        :footer="null"
        @cancel="handleCancel"
      >
        <video style="width: 100%;height: 252px;" :src="previewvideo" controls></video>
      </a-modal>
    </div>
  </div>
</template>
<script>
import { ElMessage, ElMessageBox } from "element-plus";

import { useRouter } from "vue-router";
import upload from "../../api/upload";
import myAxios from "@/api/request";
import { ref } from "vue";

import wangEditer from "../../components/wangEditer";
import dayjs from "dayjs";
import { Modal } from "ant-design-vue";
import { createVNode } from "vue";
import { message } from "ant-design-vue";
import { nrzdCascaderAjax, nrzdThreeCascaderAjax } from "@/api/zdManager";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = error => reject(error);
  });
}
export default {
  name: "nrBeian",
  components: {
    wangEditer
  },
  setup() {
    const previewVisible = ref(false);
    const previewImage = ref("");
    const previewvideo = ref("");
    const previewTitle = ref("");
    const vpreviewVisible = ref(false);
    const router = useRouter();
    const fanhui = () => {
      router.back();
    };
    const handlePreview = async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }

      previewImage.value = file.url || file.preview;
      previewVisible.value = true;
      previewTitle.value =
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1);
    };
    const handlevPreview = async file => {
      previewvideo.value = file.vurl;
      vpreviewVisible.value = true;
      previewTitle.value =
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1);
    };
    const handleCancel = () => {
      previewVisible.value = false;
      vpreviewVisible.value = false;
      previewTitle.value = "";
    };

    return {
      handlevPreview,
      vpreviewVisible,
      handleCancel,
      previewVisible,
      previewImage,
      previewTitle,
      previewvideo,
      fanhui,
      handlePreview
    };
  },
  methods: {
    _nrzdCascaderAjax(node, resolve) {
      if (!node.value) {
        nrzdThreeCascaderAjax().then(res => {
          resolve(res.data);
        });
      } else if (!node.data.children || !node.data.children.length) {
        nrzdCascaderAjax({ positionType: node.pathLabels.join("-") }).then(
          res => {
            if (res.data2.code === 200) {
              if (!res.data || !res.data.length) {
                message.warning("该阵地类型没有备案");
                resolve(null);
              } else {
                const data = res.data.map(ele => {
                  ele.leaf = true;
                  return ele;
                });
                resolve(data);
              }
            }
          }
        );
      }
    },

    handleInput(refTitle) {
      this.$nextTick(() => {
        this.contentRecord.title = `${this.$refs[refTitle].innerHTML}`;
        this.$refs.formRef.validateFields(["title"]);
      });
    },
    editTitleCount(refTitle) {
      this.editCount = this.$refs[refTitle].innerText.length;
    },
    changeInput(replaceReg, replaceString) {
      this.contentRecord.title = this.contentRecord.title.replace(
        replaceReg,
        replaceString
      );
    },

    async validateTitle(_rule, value) {
      if (!value.length) {
        return Promise.reject("请输入标题");
      } else if (this.$refs.editTitleRef.innerText.length > 65) {
        return Promise.reject("输入内容不能超过65个字符");
      } else {
        return Promise.resolve();
      }
    },
    updatecontent(msg) {
      this.contentRecord.content = msg.newHtml;
    },
    sizeLimit(size) {
      return size < 5 * 1024 * 1024;
    },
    formatLimit(format) {
      const formatList = [
        "image/jpg",
        "image/jpeg",
        "image/bmp",
        "image/gif",
        "image/png"
      ];
      const formatvList = ["video/mp4", "video/m3u8", " video/flv"];
      const formatfList = [
        "application/doc",
        "application/msword",
        "application/docx",
        "application/pdf",
        "application/xls",
        "application/xlsx",
        "application/ppt",
        "application/pptx",
        "application/zip",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ];
      if (this.contentRecord.cate == 1) {
        const a = formatList.indexOf(format);
        return a;
      } else if (this.contentRecord.cate == 2) {
        const a = formatvList.indexOf(format);
        return a;
      } else {
        const a = formatfList.indexOf(format);
        return a;
      }
    },
    async uploadvedioSumbit(data) {
      const r = this.formatLimit(data.file.type);
      if (r == -1) {
        ElMessage.error("视频格式不正确");
        return;
      }
      this.videolist.push(this.uploading);
      const url = await upload(data.file.name, data.file);
      const a = url.split("?");
      this.videolist.push({
        format: data.file.format,
        name: data.file.name,
        size: data.file.size,
        url: a[0] + "?x-oss-process=video/snapshot,t_10000,m_fast",
        vurl: a[0],
        uid: this.videolist.length + 1
      });
      this.videolist = this.videolist.filter(({ uid }) => uid !== "uploading");
    },
    async uploadSumbit(data) {
      const r = this.formatLimit(data.file.type);
      if (r == -1) {
        ElMessage.error("图片格式不正确");
        return;
      }
      if (!this.sizeLimit(data.file.size)) {
        message.error("文件超过5M");
        return;
      }
      this.imglist.push(this.uploading);
      const url = await upload(data.file.name, data.file);
      const a = url.split("?");

      this.imglist.push({
        format: data.file.type,
        name: data.file.name,
        size: data.file.size,
        url: a[0],
        uid: this.imglist.length + 1
      });
      this.imglist = this.imglist.filter(({ uid }) => uid !== "uploading");
    },
    async addcontentRecord() {
      const reg = new RegExp(" / ", "g");
      if (this.jz == 0) {
        this.contentRecord.title = this.$refs.editTitleRef.innerText;
      }
      if (this.contentRecord.cate == 1) {
        if (this.imglist.length == 0) {
          message.error("请上传图片");
          return;
        }
        this.contentRecord.uploadList = this.imglist;
      } else if (this.contentRecord.cate == 2) {
        if (this.videolist.length == 0) {
          message.error("请上传视频");
          return;
        }
        this.contentRecord.uploadList = this.videolist;
      } else {
        this.contentRecord.uploadList = this.newfileList;
      }
      if (
        this.contentRecord.cate == 0 &&
        this.newfileList.length == 0 &&
        this.contentRecord.content == ""
      ) {
        message.warn("请填写文稿或者上传文稿附件");
        return;
      }
      if (this.udate && this.contentRecord.time) {
        const publishTime = dayjs(this.udate).format("YYYY-MM-DD");

        const time = this.contentRecord.time.substring(
          this.contentRecord.time.length - 8
        );
        this.contentRecord.publishTime = publishTime + " " + time;
      } else {
        if (this.contentRecord.cate !== 0) {
          message.error("请选择发布时间");
          return;
        }
      }
      const resq = await myAxios({
        url: "/contentRecord",
        data: {
          ...this.contentRecord,
          positionId: this.contentRecord.positionId.slice(-1)[0],
          positionType: this.$refs.cascaderRef.inputValue.replace(reg, "-")
        },
        method: "post"
      });
      const data = resq.data2;
      if (data.code == 200) {
        message.success("保存成功");
        this.$router.back();
      } else {
        if (this.contentRecord.cate == 0 && resq.data2.code == 405) {
          const record =
            resq.data2.data.errorList && JSON.parse(resq.data2.data.errorList);
          const nameError =
            resq.data2.data.nameErrorList &&
            JSON.parse(resq.data2.data.nameErrorList);
          const that = this;
          Modal.confirm({
            title: "提示",
            class: "zd-modal-container",
            icon: createVNode('img', {
              src: require("../../assets/icons/warn_icon@2x.png")
            }),
            content: resq.data2.msg,
            okButtonProps: {
              type: "default"
            },
            cancelButtonProps: {
              type: "primary"
            },
            okText: "坚持备案",
            cancelText: "返回修改",
            onOk() {
              console.log("取消");

              if (that.jz == 0) {
                that.contentRecord.title = that.$refs.editTitleRef.innerText;
              }
              that.contentRecord.isIgnoredError = 1;
              myAxios({
                url: "/contentRecord",
                data: {
                  ...that.contentRecord,
                  positionId: that.contentRecord.positionId.slice(-1)[0],
                  positionType: that.$refs.cascaderRef.inputValue.replace(
                    reg,
                    "-"
                  )
                },
                method: "post"
              }).then(resq => {
                if (resq.data2.code == 200) {
                  message.success("保存成功");
                  that.$router.back();
                } else {
                  ElMessage.error(resq.data2.msg);
                }
              });
            },
            cancel() {
              that.warnclass = true;
            }
          });
          // ElMessageBox.confirm(resq.data2.msg, "提示", {
          //   confirmButtonText: "坚持备案",
          //   cancelButtonText: "返回修改",
          //   type: "提示"
          // })
          //   .then(() => {
          //     if (this.jz == 0) {
          //       this.contentRecord.title = this.$refs.editTitleRef.innerText;
          //     }
          //     this.contentRecord.isIgnoredError = 1;
          //     myAxios({
          //       url: "/contentRecord",
          //       data: {
          //         ...this.contentRecord,
          //         positionId: this.contentRecord.positionId.slice(-1)[0],
          //         positionType: this.$refs.cascaderRef.inputValue.replace(
          //           reg,
          //           "-"
          //         )
          //       },
          //       method: "post"
          //     }).then(resq => {
          //       if (resq.data2.code == 200) {
          //         message.success("保存成功");
          //         this.$router.back();
          //       } else {
          //         ElMessage.error(resq.data2.msg);
          //       }
          //     });
          //   })
          //   .catch(() => {
          //     this.warnclass = true;
          //   });
          record?.forEach(item => {
            /*
             * 使用正则表达式进行全文匹配关键词
             * ig : 表示 全文查找 ,忽略大小写
             *  i : 忽略大小写
             *  g : 全文查找
             *
             * 使用字符串的replace方法进行替换
             * stringObject.replace('被替换的值',替换的值)*/
            let replaceReg = new RegExp(item.errorExpression, "ig");
            let replaceString = `<span class='tool' labelTooltip='正确表达：${item.correctExpression}' style='color: #ed4014'>${item.errorExpression}</span>`;

            this.editor.content = this.contentRecord.content = this.contentRecord.content.replace(
              replaceReg,
              replaceString
            );
            this.editor.ischange = 1;
          });
          nameError?.forEach(item => {
            let replaceReg = new RegExp(item.errorExpression, "ig");
            this.changeInput(
              replaceReg,
              "<span class='neirongToolTitle' data-title='正确表达：" +
                item.correctExpression +
                "'  style='color: #ed4014' >" +
                item.errorExpression +
                "</span>"
            );
          });
        } else {
          message.warn(resq.data2.msg);
        }
      }
    },
    async fwbupload(data) {
      const url = await upload(data.file.name, data.file);
      return url;
    },
    cateChange() {
      this.jz = this.contentRecord.cate;
      this.$refs["formRef"].resetFields();
      this.contentRecord.cate = this.jz;
      this.contentRecord.uploadList = [];
      this.contentRecord.positionId = "";
      this.filelist = [];
      this.newfileList = [];
      this.udate = "";
      this.editCount = 0;
    },
    onFinishFailed() {
      // message.info("有项目未填，请填写后提交");
    },
    back() {
      this.$router.back();
    },
    beforeUpload(file) {
      const ext = "." + file.name.split(".").pop();
      if (!this.acceptType.includes(ext)) {
        ElMessage.error("文件格式不正确");
        return false;
      }
      if (file.size / 1024 / 1024 > 30) {
        ElMessage({
          message: "文件超过30M",
          type: "warning"
        });
        return false;
      }
    },
    //文稿附件上传
    async uploadcontentSumbit(data) {
      const url = await upload(data.file.name, data.file);
      const a = url.split("?");
      let size = "";
      if (data.file.size > 1024 * 1024) {
        size = (data.file.size / (1024 * 1024)).toFixed(2) + "M";
      } else {
        size = (data.file.size / 1024).toFixed(2) + "kb";
      }
      this.filelist.push({
        format: data.file.format,
        name: data.file.name,
        size: size,
        url: a[0],
        uid: this.filelist.length + 1
      });
    },
    delet(_uid) {
      this.filelist = this.filelist.filter(({ uid }) => uid != _uid);
    },
    showfaupload() {
      this.dialogFormVisible = true;
      this.filelist = [].concat(this.newfileList);
    },
    updateVisible() {
      this.dialogFormVisible = false;
      this.newfileList = this.filelist;

      this.filelist = [];
    },
    delectVisible() {
      this.filelist = [];
      this.dialogFormVisible = false;
    }
  },
  mounted() {},

  data() {
    return {
      imgurl: "",
      imgVisible: false,
      warnclass: false,
      dialogFormVisible: false,
      udate: "",
      utime: "",
      editCount: 0,
      acceptType: ".doc, .docx, .pdf, .xls, .xlsx, .ppt, .pptx, .zip",
      uploading: {
        uid: "uploading",
        percent: 50,
        name: "image.png",
        status: "uploading",
        url:
          "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
      },
      positionList: [],
      editor: {
        content: "",
        ischange: 0
      },
      // 占位配置
      jz: 0,
      background: "",
      imglist: [],
      videolist: [],
      filelist: [],
      newfileList: [],
      contentRecord: {
        cate: 0,
        content: "",
        id: 0,
        isIgnoredError: 0,
        // positionCate: "",
        positionId: "",
        positionType: "",
        publishTime: "",
        time: "",
        title: "",
        uploadList: []
      },
      options: []
    };
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/cascader.scss";

.main {
  margin: 0 auto;
  overflow: hidden;
}

@media screen and (min-width: 820px) {
  .main {
    width: 728px;
  }
}

@media screen and (min-width: 960px) {
  .main {
    width: 728px;
  }
}

@media screen and (min-width: 1200px) {
  .main {
    width: 728px;
  }
}

.spantitle {
  background: rgba(0, 0, 0, 0.9);
  box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.05),
    0px 8px 10px 1px rgba(0, 0, 0, 0.06), 0px 5px 5px -3px rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar {
  display: none;
}

/*  必填提示*/
::v-deep .ant-form-item-explain {
  height: 0px;
  min-height: 0px;
  opacity: 1;
}
::v-deep .ant-form-item-explain-error {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff4224;
  line-height: 20px;
}
//修改边框颜色
::v-deep
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-form-item-has-error
  :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
.ant-form-item-has-error
  :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background-color: #fff;
  border-color: #ff4224;
}
.neirong {
  width: 728px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 16px;
  padding-top: 24px;
  margin-bottom: 23px;

  .title {
    height: 22px;
    display: flex;
    align-items: center;

    .fanhuiimg {
      height: 16px;
      width: 16px;
      margin-left: 16px;
      cursor: pointer;
    }

    .span-t1 {
      width: 64px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2f3238;
      line-height: 22px;
      margin-left: 10px;
    }
  }

  .nrfrom {
    .button-i {
      margin-bottom: 24px;
      display: flex;
      align-items: center;

      .sbutton {
        margin-right: 320px;
        width: 88px;
        height: 32px;
        background: #286cfb;
        border-radius: 4px;
        margin-bottom: 24px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }

    .div-i {
      margin-top: 45px;
      .edit-title-box {
        height: 32px;
        width: 551px;
        padding: 0 8px 0 12px;
        border: 1px solid #dcdcdc;
        border-radius: 3px;
        margin-right: 46px;
        display: flex;
        align-items: center;
        position: relative;
      }
      .edit-title {
        outline: none;
        font-size: 14px;
        line-height: 30px;
        white-space: nowrap;
        overflow-x: auto;
        width: 100%;
        cursor: text;
        &:empty::before {
          content: attr(placeholder);
          color: #c8c9cc;
        }
        /deep/.neirongToolTitle[data-title]:hover:after {
          content: attr(data-title);
          background: rgba(0, 0, 0, 0.9);
          display: inline-block;
          color: white;
          padding: 0px 10px;
          border-radius: 3px;
          position: absolute;
          top: -30px;
        }
      }

      .edit-count {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        margin-left: 8px;
        width: 70px;
        text-align: right;
      }

      .uvideo {
        display: flex;
        margin-bottom: 24px;
        justify-content: flex-end;

        .card {
          float: right;
          margin-right: 46px;
          height: 308px;
          width: 551px;

          .upload {
            ::v-deep .ant-upload-list {
              display: none;
            }

            ::v-deep .ant-upload {
              background: #ffffff;
              border-radius: 6px;
              align-items: center;
            }

            ::v-deep .ant-upload-btn {
              display: flex;
              display: -webkit-flex;
              justify-content: center;
              align-items: center;
            }

            ::v-deep .ant-upload-text {
              background: #ffffff;
              border-radius: 6px;
              align-items: center;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.4);
              line-height: 22px;
            }

            .span-u {
              margin-left: 16px;
              width: 249px;
              height: 20px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.4);
              line-height: 20px;
            }
          }

          .imgl {
            width: 400px;
            height: 252px;
            border-radius: 6px;

            ::v-deep .ant-upload-list-item {
              padding: 0px;
            }
            /deep/.ant-upload-list-item-actions {
              display: flex;
              align-items: center;
              margin-left: -3px;
              .ant-btn {
                border-left: 1px solid #fff;
              }
            }
          }
        }

        .span-x {
          width: 7px;
          height: 22px;
          font-size: 14px;
          font-family: Helvetica;
          color: #f5222d;
          line-height: 22px;
        }

        .span-i {
          margin-right: 24px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
          line-height: 22px;
        }
      }

      .uimg {
        display: flex;
        margin-bottom: 24px;
        justify-content: flex-end;

        .card {
          float: right;
          margin-right: 46px;
          height: 308px;
          width: 551px;

          .upload {
            ::v-deep .ant-upload-list {
              display: none;
            }

            ::v-deep .ant-upload {
              background: #ffffff;
              border-radius: 6px;
              align-items: center;
            }

            ::v-deep .ant-upload-btn {
              display: flex;
              display: -webkit-flex;
              justify-content: center;
              align-items: center;
            }

            ::v-deep .ant-upload-text {
              background: #ffffff;
              border-radius: 6px;
              align-items: center;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.4);
              line-height: 22px;
              font-size: 14px;
            }

            .span-u {
              margin-left: 16px;
              width: 297px;
              height: 20px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.4);
              line-height: 20px;
            }
          }

          .imgl {
            width: 551px;
            height: 252px;
            border-radius: 6px;
            border: 1px solid #dcdcdc;

            ::v-deep .ant-upload-list-picture-card-container {
              width: 85px;
              height: 85px;
              margin: 12px 0px 0px 12px;
            }

            ::v-deep .ant-upload-list-item {
              padding: 0px;
            }
            /deep/.ant-upload-list-item-actions {
              display: flex;
              align-items: center;
              margin-left: -3px;
              .anticon {
                color: #fff;
              }
              .ant-btn {
                border-left: 2px solid #fff;
                margin-left: 6px;
                padding-left: 6px;
              }
            }
          }
        }

        .span-x {
          width: 7px;
          height: 22px;
          font-size: 14px;
          font-family: Helvetica;
          color: #f5222d;
          line-height: 22px;
        }

        .span-i {
          margin-right: 24px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
          line-height: 22px;
        }
      }

      .edit {
        display: flex;
        justify-content: flex-end;
        height: 336px;
        margin-bottom: 0px;

        .img-i {
          float: right;
          margin-right: 121px;
          width: 400px;
          height: 300px;
          background: rgba(255, 255, 255, 0.9);
          border-radius: 2px;
        }

        .edit-i {
          float: right;
          margin-right: 46px;
          width: 551px;
          height: 340px;
          background: rgba(255, 255, 255, 0.9);
          border-radius: 2px;
        }
        .span-x {
          width: 7px;
          height: 22px;
          font-size: 14px;
          font-family: Helvetica;
          color: #f5222d;
          line-height: 22px;
        }

        .span-i {
          margin-right: 24px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
          line-height: 22px;
        }
      }

      .select {
        margin-bottom: 24px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        ::v-deep .ant-form-item-explain-error {
          color: #ff4d4f;
        }

        ::v-deep .ant-form-item {
          margin-bottom: 0px;
        }

        .cascader-i {
          margin-right: 121px;
        }
        .select-a {
          width: 551px;
          float: right;
          margin-right: 46px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
          line-height: 22px;
          display: flex;

          .select-i {
            white-space: normal;
            width: 264px;
            height: 32px;

            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.9);
            line-height: 22px;
          }
        }

        .radio-a {
          width: 551px;
          float: right;
          margin-right: 46px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
          line-height: 22px;
          /deep/.ant-radio-checked .ant-radio-inner {
            border-color: #286cfb;
          }
          /deep/.ant-radio-inner::after {
            background-color: #286cfb;
          }
          .radio-i {
            white-space: normal;
            width: 551px;
            margin-bottom: 16px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.9);
            line-height: 22px;
            display: block;
          }
        }

        .input-i {
          float: right;
          margin-right: 121px;
          width: 551px;
          height: 32px;
          background: rgba(255, 255, 255, 0.9);
          border-radius: 2px;
          border: 1px solid #dcdcdc;
        }

        .span-x {
          width: 7px;
          height: 22px;
          font-size: 14px;
          font-family: Helvetica;
          color: #f5222d;
          line-height: 22px;
        }

        .span-i {
          margin-right: 24px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
          line-height: 22px;
        }
      }
      .cateType {
        ::v-deep .ant-form-item-explain-error {
          margin-left: 131px;
        }
      }

      .item-i {
        margin-bottom: 24px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        ::v-deep .ant-form-item-explain-error {
          color: #ff4d4f;
        }

        ::v-deep .ant-form-item-explain {
          margin-left: 131px;
        }

        .select-a {
          width: 551px;
          float: right;
          margin-right: 46px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
          line-height: 22px;
          display: flex;

          .select-i {
            white-space: normal;
            width: 264px;
            height: 32px;

            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.9);
            line-height: 22px;
          }
        }

        .radio-a {
          width: 551px;
          float: right;
          margin-right: 46px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
          line-height: 22px;
          /deep/.ant-radio-checked .ant-radio-inner {
            border-color: #286cfb;
          }
          /deep/.ant-radio-inner::after {
            background-color: #286cfb;
          }
          .radio-i {
            white-space: normal;
            width: 551px;
            margin-bottom: 16px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.9);
            line-height: 22px;
            display: block;
          }
        }

        .input-i {
          float: right;
          margin-right: 46px;
          width: 551px;
          height: 32px;
          background: rgba(255, 255, 255, 0.9);
          border-radius: 2px;
          border: 1px solid #dcdcdc;
        }

        .span-x {
          width: 7px;
          height: 22px;
          font-size: 14px;
          font-family: Helvetica;
          color: #f5222d;
          line-height: 22px;
        }

        .span-i {
          margin-right: 24px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
          line-height: 22px;
        }
      }
    }
  }
}

::v-deep .ant-modal-body {
  height: 460px;
}

::v-deep .ant-form-item-control-input-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.warn {
  margin-left: 131px;
  width: 400px;
  height: 20px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff4224;
  line-height: 20px;
  display: inline;
}
.uploadfiledialog {
  .bhnr {
    overflow: auto;
    margin-top: 20px;
    margin-left: 32px;

    .span-u {
      margin-left: 16px;
      width: 249px;
      height: 20px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
      line-height: 20px;
    }

    .e-sbutton {
      width: 88px;
      height: 32px;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 3px;
      border: 1px solid #dcdcdc;
      margin-bottom: 25px;
    }

    ::v-deep .ant-upload-list {
      display: none;
    }
  }

  ::v-deep .el-dialog {
    height: 460px;
    --el-dialog-width: 560px;
  }

  ::v-deep .el-dialog__header {
    width: 560px;
    height: 48px;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
  }

  ::v-deep.el-dialog__footer {
    width: 560px;
    height: 56px;
    padding: var(--el-dialog-padding-primary);
    padding-top: 0px;
    text-align: right;
    box-sizing: border-box;
    border-top: 1px solid #d9d9d9;
  }

  ::v-deep .el-dialog__body {
    margin-top: 24px;
    height: 320px;
    padding: 0px;
  }

  ::v-deep .el-dialog__title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    line-height: 24px;
  }

  .footer {
    margin-top: 24px;
    width: 440px;
    height: 56px;
    padding: var(--el-dialog-padding-primary);
    padding-top: 0px;
    text-align: right;
    box-sizing: border-box;
    border-top: 1px solid #d9d9d9;
  }
}
.uploadfile {
  display: flex;
  cursor: pointer;
  width: 551px;
  margin-bottom: 24px;
  margin-left: 131px;
  align-items: center;

  .span-u {
    width: 100%;
    height: 32px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 32px;
  }
}

::v-deep .w-e-menu {
  width: 31px;
  height: 31px;
}
/deep/.cascader-popper-class {
  .el-cascader-node.in-active-path {
    .el-icon {
      color: #286cfb;
    }
  }
  .el-cascader-node:not(.is-disabled):hover {
    background: rgba(40, 108, 251, 0.1);
  }
  .el-cascader-node.is-active {
    .el-icon {
      color: #286cfb;
    }
  }
}
</style>