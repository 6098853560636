<template>
  <div
    v-if="list.length > 0"
    class="wraper"
    style="width: 100%; height: 100%; overflow: hidden"
  >
    <p class="select-box-title" v-show="type !== 0">
      已选择{{ list.length }}人<span class="delall" @click="del(-1)"
        >一键清除</span
      >
    </p>
    <div class="avator-box" v-for="(item, index) in list" :key="index">
      <img v-if="item.headImg" :src="item.headImg" alt="" />
      <el-avatar v-else> {{ item.username.slice(-1) }} </el-avatar>
      <p class="name">{{ item.name }}</p>
      <el-icon
        class="del"
        :size="16"
        color="#999"
        v-if="type !== 0"
        @click="del(index)"
        ><CircleCloseFilled
      /></el-icon>
    </div>
  </div>
</template>
<script>
import { ref, watch } from "vue";
import { Toast } from "vant";
export default {
  props: {
    list: {
      type: Array,
      default: [],
    },
    maxCount: {
      type: String,
      default: "500",
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },

  methods: {
    selectComponent(alreadyContacts) {
      alreadyContacts = alreadyContacts || [];
      return new Promise((resolve, reject) => {
        dcm.selectComponent({
          title: "选择联系人",
          limitTips: "已经是最大人数",
          maxCount: this.maxCount, // 最大可选人数 1-500
          alreadyContacts: alreadyContacts, //已选人员
          alreadyPlatforms: [], // 已选部门
          forbibContacts: [], // 不可选人员
          mustContacts: [], //必选人员
          forbibPlatforms: [], //不可选部门
          mustPlatforms: [], // 必选部门
          onSuccess: (data) => {
            console.log("dcm.selectComponent onSuccess", data);

            resolve(data);
          },
          onFail: (data) => {
            console.log("dcm.selectComponent onFail", data);
            reject(data);
          },
        });
      });
    },
    selectPer() {
      let ids = this.list.map((item) => item.id);
      this.selectComponent(ids).then((res) => {
        console.log(
          "🚀 ~ file: SelectPerForH5.vue ~ line 66 ~ this.selectComponent ~ res",
          res
        );
        // this.list = res.users;
        this.$emit("selectUserAdd", res.users);
        // this.$emit("getUsers", this.list);
      });
    },
    del(index) {
      if (index == -1) {
        // Toast("确定要清除所有已选用户吗？", "提示", {
        //   confirmButtonText: "确定",
        //   callback: (action) => {
        //     console.log(action);
        //     if (action === "confirm") {
        //       this.$emit("addPer", this.list);
        //       this.$emit("getUsers", this.list);
        //     }
        //   },
        // });
        this.$emit("selectUserAdd", []);
      } else {
        let userList = JSON.parse(JSON.stringify(this.list));
        userList.splice(index, 1);
        this.$emit("selectUserAdd", userList);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.select-box-title {
  font-size: 14px;
  color: #60626c;
  line-height: 20px;
  padding-bottom: 24px;
}
.delall {
  width: 48px;
  height: 17px;
  font-size: 12px;
  color: #286cfb;
  line-height: 17px;
  margin-left: 16px;
  cursor: pointer;
}
.avator-box {
  float: left;
  width: 50px;
  position: relative;
  margin-bottom: 24px;
  margin-right: 20px;
  cursor: pointer;
  ::v-deep(.el-avatar) {
    margin: 0 auto;
    display: block;
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin: 0 auto;
    display: block;
  }
  .del {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.name {
  // width: 36px;
  height: 17px;
  font-size: 12px;
  color: #1f2227;
  line-height: 17px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  margin-top: 5px;
}
.wraper {
  padding: 10px 16px;
}
</style>
