import {createStore} from 'vuex'

const store = createStore({
    state() {
        return {
            isAdmin: 0,
          islogin:false,
            tag:0,
            tagcate:0,
            isloading:false,
        }
    },
    getters() {
    },
    mutations: {

        isAdmin(state,payload){
            state.isAdmin = payload
        },
        isloading(state,payload){
            state.isloading = payload
        },
        islogin(state,payload){
            state.islogin = payload
        },
        tag(state,payload){
            state.tag = payload
        },
        tagcate(state,payload){
            state.tagcate = payload
        },
    }
})
export default store