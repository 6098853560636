<template>
  <div style="width: 100%; height: 100%; overflow: hidden">
    <p class="select-box-title" v-if="type !== 2">
      已选择{{ list.length }}人<span
        class="delall"
        @click="del(-1)"
        v-if="list.length && list.length > 0"
        >一键清除</span
      >
    </p>
    <div class="avator-box" v-for="(item, index) in list" :key="index">
      <img v-if="item.headImg" :src="item.headImg" alt="" />
      <el-avatar v-else> {{ item.username.slice(-1) }} </el-avatar>
      <p class="name">{{ item.name }}</p>
      <el-icon
        class="del"
        :size="16"
        color="#999"
        @click="del(index)"
        v-if="type !== 2"
        ><CircleCloseFilled
      /></el-icon>
    </div>
    <div class="avator-box" @click="selectPer" v-if="type !== 2">
      <img src="../assets/img/icon_image_add.png" alt="" />
      <p class="name">添加</p>
    </div>
  </div>
</template>
<script>
import {isIHaier} from '@/utils/platform'
import { defineComponent  } from "vue";
import { ElMessageBox } from "element-plus";
export default defineComponent({
    components: {  },
    props: {
      list: {
      type: Array,
      default: ()=>[],
    },
    type: {
      type: Number,
      default: 1,
    },
    maxCount: {
      type: Number,
    },
    },
    setup(props, context) {
     
      const selectComponent=(alreadyContacts)=> {
      alreadyContacts = alreadyContacts || [];
      return new Promise((resolve, reject) => {
        dcm.selectComponent({
          title: "选择联系人",
          limitTips: "已经是最大人数",
          maxCount:  props.maxCount, // 最大可选人数 1-500
          alreadyContacts: alreadyContacts, //已选人员
          alreadyPlatforms: [], // 已选部门
          forbibContacts: [], // 不可选人员
          mustContacts: [], //必选人员
          forbibPlatforms: [], //不可选部门
          mustPlatforms: [], // 必选部门
          onSuccess: (data) => {
            console.log("dcm.selectComponent onSuccess", data);

            resolve(data);
          },
          onFail: (data) => {
            console.log("dcm.selectComponent onFail", data);
            reject(data);
          },
        });
      });
    }
    const ihaierSelectComponent = (list=[]) => {
            window.h5sdk.ready(() => {
                window.tt.chooseContact({
                    multi: true,
                    ignore: false,
                    maxNum: 1000,
                    enableChooseDepartment: false, // 是否支持部门
                    choosenIds: list,
                    limitTips: '选择联系人数量已超过上限',
                    externalContact: false,
                    success(res) {
                        console.log(`联系人列表`, res);
                        const { data } = res;
                        // chooseMemberData.value = res.data

                        context.emit('addPer', data);
                        // this.$emit('getUsers', data);
                        // 取消焦点
                    },
                    fail(res) {
                        console.log(`获取联系人失败`, res);
                    }
                });
            });
        };
    const selectPer=() =>{
      if (isIHaier) {
         let ids = props.list.map((item) => item.openId);
                ihaierSelectComponent(ids);

                // ElMessage({
                //     message: '请到海尔人app操作',
                //     type: 'warning'
                // });
                return;
            }
      console.log("调用选人组件", props.list);
      let ids = props.list.map((item) => item.id);
      selectComponent(ids).then((res) => {
        context.emit("addPer", res.users);
       
      });
    }
    const del=(index) =>{
      if (index == -1) {
        ElMessageBox.alert("确定要清除所有已选用户吗？", "提示", {
          confirmButtonText: "确定",
          customClass: "box",
          confirmButtonClass: "ExitConfirmButton",
          callback: (action) => {
            console.log(action);
            if (action === "confirm") {
              context.emit("clearPer","manage");
             
            }
          },
        });
      } else {
        props.list.splice(index, 1);
        context.emit("delEvent", props.list);
     
      }
    }
    return {
      del,
      selectPer
  }
    }
 
 
 
 
  })
 
</script>
<style lang="scss" scoped>
.select-box-title {
  font-size: 14px;
  color: #60626c;
  line-height: 20px;
  // padding-bottom: 24px;
}
.delall {
  width: 48px;
  height: 17px;
  font-size: 12px;
  color: #286cfb;
  line-height: 17px;
  margin-left: 16px;
  cursor: pointer;
}
.avator-box {
  float: left;
  width: 50px;
  position: relative;
  // margin-bottom: 10px;
  margin-right: 20px;
  cursor: pointer;
  ::v-deep(.el-avatar) {
    margin: 0 auto;
    display: block;
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin: 0 auto;
    display: block;
  }
  .del {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.name {
  // width: 36px;
  height: 17px;
  font-size: 12px;
  color: #1f2227;
  line-height: 17px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  margin-top: 5px;
}
</style>
<style>
.ExitConfirmButton {
  border: 1px solid #286cfb !important;
  background: #286cfb !important;
  color: #fff !important;
}
.ExitConfirmButton:focus-visible {
  outline: none !important;
  outline-offset: 0 !important;
}
</style>
