import myAxios from '../request'


export function addcontentRecord(data) {
  return myAxios({
    url: '/contentRecord',
    data,
    method: 'post'
  })
}
export function addPositionRecord(method, data) {
  return myAxios({
    url: '/positionRecord',
    data,
    method
  })
}

export function getPositionRecord(data) {
  return myAxios({
    url: '/positionRecord',
    params: data,
    method: 'get'
  })

}

