<template>
  <div class="main">
    <div class="mybeian">
      <div class="title">
        <img class="fanhuiimg" @click="fanhui" src="../../assets/icons/pcindex/b3b4.png" />
        <span class="span-t1">备案审核</span>
      </div>
      <div class="cate">
        <div class="cate-i">
          <img class="cate-icon" src="../../assets/icons/pcindex/20.png" />
          <span class="cate-span">阵地备案</span>
          <a-button class="button" @click="tobhindex">驳回备案库</a-button>
        </div>
      </div>
      <div class="nrlist">
        <div>
          <zdspList :list="dataList" @refreshData="refreshData" />
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>
<script>
import { useRouter } from "vue-router";
import myAxios from "@/api/request";
import { ref } from "vue";
import { useStore } from "vuex";
import zdspList from "../../components/pcComponent/zdspList";
export default {
  name: "mybeian",
  components: {
    zdspList
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const zdCate = store.state.tagcate;
    const fanhui = () => {
      router.back();
      store.commit("tagcate", 0);
    };
    const tobhindex = () => {
      router.push("zdspbhIndex");
      store.commit("tagcate", 0);
    };
    return {
      zdCate,
      fanhui,
      tobhindex
    };
  },
  created() {},
  methods: {
    changeZdCate(tab, event) {
      this.$store.commit("tagcate", tab.props.name);
      // this.zdsearch.cate = tab.props.name;
      this.getList();
    },

    async getList() {
      // this.zdsearch.cate = this.$store.state.tagcate;
      const res = await myAxios({
        url: "/positionRecord",
        params: this.zdsearch,
        method: "get"
      });
      this.dataList = res.data;
    },
    refreshData(msg) {
      this.zdsearch.size = msg.size;
      this.zdsearch.current = msg.current;
      this.zdsearch.name = msg.name;
      this.zdsearch.positionCate = msg.positionCate;
      this.getList();
    }
  },
  data() {
    return {
      url: "/positionRecord",
      cate: 0,

      total: "400",
      zdsearch: {
        current: 1,
        size: 20,
        status: 0,
        isAdminPage: 1,
        // cate: 0,

        name: ""
      },
      dataList: []
    };
  },
  mounted() {
    this.getList(this.url, this.zdsearch);
  }
};
</script>
<style lang="scss" scoped>
.main {
  margin: 0 auto;
  overflow: hidden;
}

@media screen and (min-width: 820px) {
  .main {
    width: 928px;
  }
}

@media screen and (min-width: 960px) {
  .main {
    width: 928px;
  }
}

@media screen and (min-width: 1200px) {
  .main {
    width: 928px;
  }
}

.mybeian {
  width: 928px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 16px;
  padding-top: 24px;
  margin-bottom: 23px;

  .title {
    height: 22px;
    display: flex;
    align-items: center;

    .fanhuiimg {
      height: 16px;
      width: 16px;
      margin-left: 16px;
      cursor: pointer;
    }

    .span-t1 {
      width: 64px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2f3238;
      line-height: 22px;
      margin-left: 10px;
    }
  }

  .cate {
    width: 928px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .button {
      margin-right: 20px;
      width: 102px;
      height: 32px;
      background: #286cfb;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.9);
      line-height: 22px;
    }
    .cate-i {
      .cate-span {
        color: #286cfb;
        width: 56px;
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        margin-right: 684px;
        line-height: 22px;
      }

      .cate-icon {
        margin-right: 3px;
        width: 16px;
        width: 16px;
      }
    }
  }

  .nrlist {
    width: 862px;

    margin-left: 46px;

    .tabs {
      .tab {
        margin-top: 15px;
        margin-bottom: 5px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 20px;

        ::v-deep .el-tabs__item {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
          line-height: 20px;
        }
      }
    }

    ::v-deep .el-tabs__item.is-active {
      color: #286cfb !important;
    }
    ::v-deep .el-tabs__active-bar {
      background-color: #286cfb;
    }
  }
}
</style>