<template>
  <div style="height: 300px" id="editor"></div>
</template>
<script>
import { onBeforeUnmount, ref, shallowRef, onMounted, watch } from "vue";
import WangEditor from "wangeditor";

export default {
  props: {
    editor: {
      type: Object
    }
  }, // 接收父组件的方法
  emits: ["updatecontent"],
  setup(props, { emit }) {
    // 编辑器实例，必须用 shallowRef

    watch(
      () => props.editor,
      newVal => {
        console.log("newVal", newVal);
        if (newVal.ischange == 1) {
          editor.value.txt.html(newVal.content);
        }
      },
      {
        deep: true
      }
    );
    const editorRef = shallowRef();
    const editor = ref();
    // 内容 HTML
    const valueHtml = ref("<p>hello</p>");

    const token = sessionStorage.getItem("token");
    const initEditor = () => {
      editor.value = new WangEditor("#editor");
      editor.value.config.placeholder = "请输入~";
      editor.value.config.uploadImgShowBase64 = false; // 是否设置base64存储图片

      editor.value.config.uploadImgServer = "/api/contentRecord/attachment"; // 配置服务器端地址
      editor.value.config.uploadImgHeaders = {
        Authorization: "Bearer " + token
      }; // 自定义 header
      editor.value.config.uploadFileName = "file"; // 后端接受上传文件的参数名
      // editor.value.config.uploadImgMaxSize = 2 * 1024 * 1024 // 将图片大小限制为 2M
      editor.value.config.uploadImgMaxLength = 3; // 限制一次最多上传 3 张图片
      editor.value.config.uploadImgTimeout = 3 * 60 * 1000; // 设置超时时间
      editor.value.config.showLinkImg = false; // 隐藏插入网络图片的功能
      editor.value.config.zIndex = 0;
      editor.value.config.onchange = newHtml => {
        emit("updatecontent", {
          newHtml: newHtml
        });
      };

      editor.value.config.menus = [
        "undo", // 撤销
        "redo", // 重复
        "head", // 标题
        "fontSize", // 字号
        "backColor", // 背景颜色
        "bold", // 粗体
        "italic", // 斜体
        "underline", // 下划线
        // 'link', // 插入链接
        "foreColor", // 文字颜色
        "image" // 插入图片
      ];
      editor.value.config.uploadImgHooks = {
        customInsert(insertImgFn, result) {
          console.log(result);
          insertImgFn(result.data);
        }
      };
      // 创建编辑器

      editor.value.create();
    };
    // 模拟 ajax 异步获取内容
    onMounted(() => {
      initEditor();
    });

    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
      const editor = editorRef.value;
      if (editor == null) return;
      editor.destroy();
    });

    return {
      initEditor,
      editorRef,
      valueHtml,
      mode: "default" // 或 'simple'
    };
  },
  data() {}
};
</script>
<style  lang="scss" scoped>
::v-deep .w-e-menu {
  width: 31px !important;
  height: 31px !important;
}

::v-deep .w-e-toolbar {
  height: 36px;
  background-color: #f0f0f0;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  padding: 0 6px;
  flex-wrap: wrap;
  position: relative;
  border: 1px solid #dcdcdc !important;
}
::v-deep .tool {
  position: relative;
}
/deep/.w-e-text-container {
  border-radius: 0 0 4px 4px;
  border: 1px solid #dcdcdc !important;
}
/deep/.placeholder {
  color: #dcdcdc;
}
::v-deep .tool:hover:after {
  position: absolute;
  left: 15px;
  top: 25px;
  padding: 5px;
  background-color: #0e0e0e;
  border-radius: 5px;
  color: #fff;
  /*这里显示的内容为表格中自定义的labelTooltip属性对应的值*/
  content: attr(labelTooltip);
  z-index: 2;
  white-space: nowrap;
  height: 30px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  line-height: 22px;
}
</style>
<style>
</style>