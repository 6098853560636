<template>
  <div
    v-if="list.length > 0"
    class="wraper"
    style="width: 100%; height: 100%; overflow: hidden"
  >
    <p class="select-box-title" v-show="type !== 0">
      已选择{{ departNum }}个链群,已选择{{ userNum }}人<span
        class="delall"
        @click="del(-1)"
        >一键清除</span
      >
    </p>
    <div class="avator-box" v-for="(item, index) in list" :key="index">
      <img v-if="item.headImg" :src="item.headImg" alt="" />
      <el-avatar v-else> {{ item.username.slice(-1) }} </el-avatar>
      <p class="name">{{ item.name ? item.name : item.orgName }}</p>
      <el-icon
        class="del"
        :size="16"
        color="#999"
        v-if="type !== 0"
        @click="del(index)"
        ><CircleCloseFilled
      /></el-icon>
    </div>
  </div>
</template>
<script>
import { ref, watch } from "vue";
import { Toast } from "vant";
import { ElMessageBox, ElMessage } from "element-plus";
export default {
  props: {
    list: {
      type: Array,
      default: [],
    },
    maxCount: {
      type: String,
      default: "500",
    },
  },
  data() {
    return {
      userNum: null,
      version: 0,
      arr_user: [],
      arr_depart: [],
    };
  },
  watch: {
    list: {
      immediate: true,
      deep: true,
      handler(newList) {
        let alreadyContactIds = newList.map((item) => {
          if (item.type == 1) {
            return item.id;
          }
        });
        let alreadyPlatformIds = newList.map((item) => {
          if (item.type == 2) {
            return item.id;
          }
        });
        let userId = alreadyContactIds.filter((item) => item !== undefined);
        let departId = alreadyPlatformIds.filter((item) => item !== undefined);
        this.userNum = userId.length;
        this.departNum = departId.length;
      },
    },
  },
  mounted() {
    console.log(12121313);
    dcm.getVersion({
      onSuccess: (data) => {
        console.log("dcm.selectComponent2 onSuccess", data);
        this.version = this.getStringofNum(data.version);
      },
      onFail: (data) => {
        console.log("dcm.selectComponent2 onFail", data);
      },
    });
  },
  methods: {
    getStringofNum(text) {
      //取出字符串中的数字
      var value = text.replace(/[^0-9]/gi, "");
      return value;
    },
    selectComponent(alreadyContacts, alreadyPlatforms) {
      alreadyContacts = alreadyContacts || [];
      alreadyPlatforms = alreadyPlatforms || [];
      return new Promise((resolve, reject) => {
        dcm.selectComponent2({
          appId: window.origin.includes("-test")
            ? "3dyvMf_ebQeYIF2qNXOB2"
            : "HNugSV9C1hKQBjScaq9xO",
          title: "选择联系人",
          limitTips: "已经是最大人数",
          maxCount: this.maxCount, // 最大可选人数 1-500
          alreadyContacts: alreadyContacts, //已选人员
          alreadyPlatforms: alreadyPlatforms, // 已选部门
          forbibContacts: [], // 不可选人员
          mustContacts: [], //必选人员
          forbibPlatforms: [], //不可选部门
          mustPlatforms: [], // 必选部门
          userMark: [],
          onSuccess: (data) => {
            console.log("dcm.selectComponent2 onSuccess", data);

            resolve(data);
          },
          onFail: (data) => {
            console.log("dcm.selectComponent2 onFail", data);
            reject(data);
          },
        });
      });
    },
    selectPer() {
      console.log(this.version);
      console.log("调用选人组件", this.list);
      if (this.version > 1060) {
        let alreadyContactIds = this.list.map((item) => {
          if (item.type == 1) {
            return item.id;
          }
        });
        let alreadyPlatformIds = this.list.map((item) => {
          if (item.type == 2) {
            return item.id;
          }
        });
        let userId = alreadyContactIds.filter((item) => item !== undefined);
        let departId = alreadyPlatformIds.filter((item) => item !== undefined);
        this.userNum = userId.length;
        this.departNum = departId.length;
        this.selectComponent(userId, departId).then((res) => {
          console.log(
            "🚀 ~ file: SelectPerForH5.vue ~ line 66 ~ this.selectComponent ~ res",
            res
          );
          if (res.selectedUsers && res.selectedUsers.length > 0) {
            this.arr_user = res.selectedUsers.map((item) => {
              return Object.assign(
                {},
                {
                  id: item.id,
                  name: item.name,
                  username: item.username,
                  headImg: item.headImg,
                  type: 1,
                }
              );
            });
          } else {
            this.arr_user = [];
          }
          if (res.departments && res.departments.length > 0) {
            this.arr_depart = res.departments.map((item) => {
              return Object.assign(
                {},
                {
                  id: item.id,
                  orgName: item.orgName,
                  // name: item.orgName,
                  type: 2,
                  headImg: window.origin.includes("-test")
                    ? "https://managedigital-record-test.oss-cn-beijing.aliyuncs.com/a94fb4cf-0478-4869-88a7-3a5e52c9e91e.png" // 测试
                    : "https://managedigital-record.oss-cn-beijing.aliyuncs.com/mmexport1668563212903.png", // 正式
                }
              );
            });
          } else {
            this.arr_depart = [];
          }
          let data = [...this.arr_user, ...this.arr_depart];

          // let data = [...res.selectedUsers, ...res.departments];
          this.$emit("selectUserAdd", data);
          // this.$emit("getUsers", this.list);
        });
      } else {
        ElMessage({
          message: "版本过低，请更新版本",
          type: "warning",
        });
      }
    },
    del(index) {
      if (index == -1) {
        // Toast("确定要清除所有已选用户吗？", "提示", {
        //   confirmButtonText: "确定",
        //   callback: (action) => {
        //     console.log(action);
        //     if (action === "confirm") {
        //       this.$emit("addPer", this.list);
        //       this.$emit("getUsers", this.list);
        //     }
        //   },
        // });
        this.$emit("selectUserAdd", []);
      } else {
        let userList = JSON.parse(JSON.stringify(this.list));
        userList.splice(index, 1);
        this.$emit("selectUserAdd", userList);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.select-box-title {
  font-size: 14px;
  color: #60626c;
  line-height: 20px;
  padding-bottom: 24px;
}
.delall {
  width: 48px;
  height: 17px;
  font-size: 12px;
  color: #286cfb;
  line-height: 17px;
  margin-left: 16px;
  cursor: pointer;
}
.avator-box {
  float: left;
  width: 50px;
  position: relative;
  margin-bottom: 24px;
  margin-right: 20px;
  cursor: pointer;
  ::v-deep(.el-avatar) {
    margin: 0 auto;
    display: block;
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin: 0 auto;
    display: block;
  }
  .del {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.name {
  // width: 36px;
  height: 17px;
  font-size: 12px;
  color: #1f2227;
  line-height: 17px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  margin-top: 5px;
}
.wraper {
  padding: 10px 16px;
}
</style>
