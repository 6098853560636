 
import { cloneDeep } from 'lodash';

 
import store from '@/store';
import axios from 'axios';

import { isInApp, isMobile } from '@/utils/platform';

// const event = mitt<Record<string, any>>();

// export interface IUser {
//     id: number; // id
//     username: string; // 工号
//     name: string; // username
//     headImg: string; // dcm 头像
//     sourceId?: string; // 不知道在哪里用的一个id
//     titleIds?: number[]; // 父节点
//     userid?: number; // 同id
// }

// export interface IDepartment {
//     id: number; // id
//     orgName: string;
//     staffCount?: number; // 组织下人员
//     isCheck?: boolean; // dcm 给的, 看起来也是用不到的数据
// }

// export interface IAllSelectObj {
//     selectCount?: number; // 总人数
//     allUserList: IUser[]; // 所有的用户list
//     departmentList: IDepartment[]; // 选择的部门list
//     singleUser: IUser[]; // 不挂在部门下，单独选择的用户list
// }

// export interface IBtn {
//     btnIcon?: string;
//     btnName: string;
//     index?: number;
//     callback: Function;
// }

let dcmObj; // 主讲端注入的全局对象

if (typeof (window).dcm === 'undefined') {
    console.log('=====未找到dcm注入对象=====');
} else {
    dcmObj = (window).dcm;
}

const dcmBus = {
    event,
    init: (appId ) => {
        if (!isInApp) {
            // store.dispatch('getUserInfo');
        }

        dcmObj.init({
            appId
        });
    },
    getCookies: () => {
        dcmObj.getCookie({
            onSuccess: async (data ) => {
                const code = data.SESSION
                console.log('换code次数', data)
                localStorage.setItem('code', code)
                axios.request({
                    baseURL: '/api',
                    url: '/user/login',
                    method: "GET",
                    params: { code }
                }).then(response => {
                    const token = response.data.data.token
                    const isAdmin = response.data.data.isAdmin
                    console.log('请求登录接口', response.data.data, response)
                    localStorage.setItem('token', token)
                    localStorage.setItem('isAdmin', isAdmin)
                    store.commit('isAdmin', isAdmin)
                    store.commit('islogin', true)
                })
            },
            onFail: () => {
                console.log('=====身份信息获取失败=====');
            }
        });
    },
    
   
};
export default dcmBus;

/**
 *
 * @param data -> dcm 人员选择器吐的数据
 * 数据重组 data => IAllSelectObj
 */
export function formatDcmUserData(data) {
    const allSelectObj = {};
    if (isMobile) {
        allSelectObj.allUserList = data.users;
        // 人员选择器返回的部门list
        allSelectObj.departmentList = data.departments;
        allSelectObj.singleUser = data.selectedUsers;
    } else {
        // 人员选择器返回的所有的人员信息、包括部门下的人员
        allSelectObj.allUserList = data.users;
        // 人员选择器返回的部门list
        allSelectObj.departmentList = data.departments;
        // 部门id list
        const departmentIds = data.departments.map(
            (department) => department.id
        );
        // 过滤出单独选择的人员名单， 不包含部门下的人员名单
        allSelectObj.singleUser = data.users.filter(
            (user) =>
                !user.titleIds?.some((id) => departmentIds.includes(id))
        );
    }

    return allSelectObj;
}

/**
 *
 * @param deleteData 删除某个数据，同时重构整个对象结构
 * @param obj
 */
export function deleteUserInAllSelectObj(
    deleteData,
    obj
) {
    const { id } = deleteData;
    const allSelectObj = {};
    // @ts-ignore
    // user
    if (deleteData.username) {
        // 删除某人员后的数据（仅存在单独选择的人员情况）
        allSelectObj.singleUser = obj.singleUser.filter(
            (user) => user.id !== id
        );
        allSelectObj.allUserList = obj.allUserList.filter(
            (user) => user.id !== id
        );
        allSelectObj.departmentList = cloneDeep(obj.departmentList);
    } else {
        // department，删除部门id, 需要重组所有的数据
        // step 1  departmentList删除该id
        allSelectObj.departmentList = obj.departmentList.filter(
            (department) => department.id !== id
        );
        const departmentIds = obj.departmentList.map(
            (department) => department.id
        );
        // step 2  allUserList 中删除属于departmentId的数据
        allSelectObj.allUserList = obj.allUserList.filter(
            (user) =>
                !user.titleIds?.some((d) => departmentIds.includes(d))
        );
        allSelectObj.singleUser = cloneDeep(obj.singleUser);
    }
    return allSelectObj;
}
