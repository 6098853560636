<template>
  <div class="main">
    <div class="zhendi">
      <div class="title">
        <img class="fanhuiimg" @click="fanhui" src="../../assets/icons/pcindex/b3b4.png" />
        <span class="span-t1">查看备案</span>
      </div>
      <div class="detail">
        <div class="detail-t" v-html="title"></div>
        <div class="detail-i">备案人：{{nrDetail.recorderName}}</div>
        <div class="detail-i">备案时间：{{nrDetail.recordTime}}</div>
        <div class="detail-i">发布时间：{{nrDetail.publishTime}}</div>
        <div class="detail-i">备案类型：{{nrDetail.cate==0?"文稿":nrDetail.cate==1?'图片':'视频'}}</div>
        <div class="detail-i">阵地：{{nrDetail.positionType}}</div>
        <div v-if="nrDetail.cate==0">
          <div class="detail-content">
            文稿内容:
            <br />
            <br />
            <span
              style="display:inline-block;width:80%;word-wrap:break-word;white-space:normal;"
              v-html="nrDetail.content"
            ></span>

            <div v-if="nrDetail.uploadList!=null">
              <div
                v-for="i in nrDetail.uploadList"
                :key="i.uid"
                @click="download(i.url,i.name)"
                class="upload"
              >
                <img class="img" src="../../assets/icons/pcindex/bz12.png" />
                <span>{{i.name}}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="nrDetail.cate==1">
          <div class="detail-img">
            <img
              :src="item.url"
              v-for="(item,index) in nrDetail.uploadList"
              :key="index"
              class="img-i"
            />
          </div>
        </div>

        <div v-if="nrDetail.cate==2">
          <div class="detail-i" style="margin-bottom: 30px; ">视频：</div>
          <div v-for="(i,index) in nrDetail.uploadList " :key="index">
            <video
              style="width: 308px;height: 164px;margin-bottom: 30px;background: black;border-radius: 6px;"
              :src="i.vurl"
              controls
            ></video>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useRouter, useRoute } from "vue-router";
import myAxios from "@/api/request";

export default {
  name: "nrBeian",
  components() {},

  setup() {
    const route = useRoute();
    const id = route.params.id;
    const router = useRouter();

    const fanhui = () => {
      router.back();
    };
    return {
      id,
      fanhui
    };
  },
  methods: {
    //文件下载
    download(url, name) {
      this.getBlob(url).then(blob => {
        this.saveAs(blob, name);
      });
    },
    saveAs(blob, filename) {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    },
    //通过文件下载url拿到对应的blob对象
    getBlob(url) {
      return new Promise(resolve => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "blob";
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response);
          }
        };

        xhr.send();
      });
    },
    async getDeatil() {
      const res = await myAxios({
        url: "/contentRecord/" + this.id,
        method: "get"
      });
      this.nrDetail = res.data;
      this.reviewResultVos = res.data.reviewResultVos;

      const res1 = await myAxios({
        url: "/positionRecord/" + this.nrDetail.positionId,
        method: "get"
      });
      this.title = res.data.title;
      res.data.nameErrorList?.forEach(item => {
        let replaceReg = new RegExp(item.errorExpression, "ig");
        this.title = this.title.replace(
          replaceReg,
          "<span class='neirongToolTitle' data-title='正确表达：" +
            item.correctExpression +
            "'  style='color: #ed4014' >" +
            item.errorExpression +
            "</span>"
        );
      });
      this.nrDetail = res.data;
      this.reviewResultVos = res.data.reviewResultVos;
    }
  },
  data() {
    return {
      nrDetail: {},
      reviewResultVos: [],
      title: ""
    };
  },
  mounted() {
    this.getDeatil();
  }
};
</script>
<style lang="scss" scoped>
.main {
  margin: 0 auto;
  overflow: scroll;
}

@media screen and (min-width: 820px) {
  .main {
    width: 728px;
  }
}

@media screen and (min-width: 960px) {
  .main {
    width: 728px;
  }
}

::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 1200px) {
  .main {
    width: 728px;
  }
}

.zhendi {
  width: 728px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 16px;
  padding-top: 24px;
  margin-bottom: 23px;
  justify-content: flex-end;
  .img {
    .simg {
      float: right;
      width: 225px;
      height: 218px;
      margin-right: 72px;
    }
  }
  .title {
    height: 22px;
    display: flex;
    align-items: center;

    .fanhuiimg {
      height: 16px;
      width: 16px;
      margin-left: 16px;
      cursor: pointer;
    }

    .span-t1 {
      width: 64px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2f3238;
      line-height: 22px;
      margin-left: 10px;
    }
  }

  .detail {
    margin-left: 41px;
    margin-top: 25px;
    width: 657px;

    .detail-content {
      margin-left: 5px;
      margin-top: 16px;
      padding-bottom: 16px;

      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      .detail-content-i {
        width: 636px;
      }
      .upload {
        cursor: pointer;
        margin-top: 15px;
      }
    }
    .detail-t {
      width: 636px;
      margin-left: 5px;
      min-height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
      position: relative;
      /deep/.neirongToolTitle[data-title]:hover:after {
        content: attr(data-title);
        background: rgba(0, 0, 0, 0.9);
        display: inline-block;
        color: white;
        padding: 6px;
        border-radius: 3px;
        position: absolute;
        top: 20px;
      }
    }
    .detail-img {
      font-size: 16px;
      margin-top: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      .img-i {
        border-radius: 5px;
        margin-bottom: 20px;
        margin-right: 20px;
        width: 308px;
        height: 164px;
      }
    }

    .detail-i-m {
      .span-i {
        width: 5px;
        height: 22px;
        font-size: 14px;
        font-family: Helvetica;
        color: #f5222d;
        line-height: 22px;
      }

      margin-top: 16px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }

    .detail-i {
      margin-left: 5px;
      margin-top: 16px;
      min-height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
  }

  .hr {
    margin-top: 30px;
    margin-left: 20px;
    width: 688px;
    border: 1px solid #dedede;
  }
  .shenpi {
    margin-top: 29px;
    margin-left: 46px;
    width: 688px;

    .shenpi-t {
      margin-bottom: 16px;
      width: 64px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
    }
    .content {
      .span-c {
        display: block;
        height: 24px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
        line-height: 24px;
      }
      .span-time {
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 22px;
      }
    }
  }
}

::v-deep .el-timeline-item__tail {
  top: 20px;
  position: absolute;
  left: 4px;
  height: calc(100% - 25px);
  border-left: 2px solid #4a75cd;
  .el-timeline-item:last-child {
    display: block;
  }
}

::v-deep .el-timeline-item__node--normal {
  top: 3px;
  left: 0.5px;
  width: 8px;
  height: 8px;
}
::v-deep .el-timeline-item:last-child {
  display: block;
}
::v-deep .tool {
  position: relative;
}
::v-deep .tool:hover:after {
  position: absolute;
  left: 15px;
  top: 25px;
  padding: 5px;
  background-color: #0e0e0e;
  border-radius: 5px;
  color: #fff;
  /*这里显示的内容为表格中自定义的labelTooltip属性对应的值*/
  content: attr(labelTooltip);
  z-index: 2;
  white-space: nowrap;
  height: 30px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  line-height: 22px;
}
</style>