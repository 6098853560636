import { createRouter, createWebHistory } from "vue-router";
import yishi from "@/views/index";
import MyBeian from "@/views/myBeian/index";
import ZdBeian from "@/views/zdBeian/index";
import NrBeian from "@/views/nrBeian/index";
import hzhendiDetail from "@/views/h5/zhendiDetail";
import hzhendisp from "@/views/h5/zhendisp";
import zdDetail from "@/views/myBeian/zdDetail";
import nrDetail from "@/views/myBeian/nrDetail";

import ZdspIndex from "@/views/manager/zdspindex";
import ZdspbhIndex from "@/views/manager/zdspbhindex";
import ZdspDetail from "@/views/manager/zdspDetail";
import Bakindex from "@/views/manager/bakindex";
import journalsindex from "@/views/manager/journalsindex";
import Cmckindex from "@/views/manager/cmckindex";
import ZdManage from "@/views/zdManage/index"; // 阵地管理
import ZdManageIndex from "@/views/zdManage/zd/index"; // 阵地管理
import ZdManageType from "@/views/zdManage/type/index"; // 阵地管理 - 字段管理
import ZdAdd from "@/views/zdManage/zd-add/index"; // 阵地管理 - 新增/编辑阵地
import myJournals from "@/views/myJournals/index"; // 我的期刊
import createJournal from "@/views/createJournal/index"; // 创建期刊
import createJournalH5 from "@/views/createJournal/createJournalH5";
import createInformation from "@/views/createInformation/index"; // 创建信息
import historicalJournals from "@/views/historicalJournals/index";

const routes = [
  {
    path: "/",
    component: yishi,
  },
  {
    path: "/zdManage",
    name: "zdManage",
    component: ZdManage,
    redirect: "/zdManage/index",
    children: [
      {
        path: "/zdManage/index",
        name: "zdManageIndex",
        component: ZdManageIndex,
      },
      {
        path: "/zdManage/type",
        name: "zdManageType",
        component: ZdManageType,
      },
      {
        path: "/zdManage/add",
        name: "zdAdd",
        component: ZdAdd,
      },
    ],
  },
  {
    path: "/myBeian",
    name: "/myBeian",
    component: MyBeian,
  },
  {
    path: "/zdBeian",
    name: "/zdBeian",
    component: ZdBeian,
  },
  {
    path: "/journalsindex",
    name: "/journalsindex",
    component: journalsindex,
  },
  {
    path: "/historicalJournals",
    name: "/historicalJournals",
    component: historicalJournals,
  },
  {
    path: "/hzhendiDetail/:id",
    name: "/hzhendiDetail",
    component: hzhendiDetail,
  },
  {
    path: "/hzhendisp/:id",
    name: "/hzhendisp",
    component: hzhendisp,
  },
  {
    path: "/nrBeian",
    name: "/nrBeian",
    component: NrBeian,
  },
  {
    path: "/zdDeatail/:id",
    name: "zdDeatail",
    component: zdDetail,
  },
  {
    path: "/nrDeatail/:id",
    name: "nrDeatail",
    component: nrDetail,
  },
  {
    path: "/zdspIndex",
    name: "zdspIndex",
    component: ZdspIndex,
  },
  {
    path: "/zdspbhIndex",
    name: "zdspbhIndex",
    component: ZdspbhIndex,
  },
  {
    path: "/zdspDetail/:id",
    name: "zdspDetail",
    component: ZdspDetail,
  },
  {
    path: "/zdspDetail/:id",
    name: "zdspDetail",
    component: ZdspDetail,
  },
  {
    path: "/zdspDetail/:id",
    name: "zdspDetail",
    component: ZdspDetail,
  },
  {
    path: "/bakindex",
    name: "/bakindex",
    component: Bakindex,
  },
  {
    path: "/cmckindex",
    name: "/cmckindex",
    component: Cmckindex,
  },
  {
    path: "/myJournals",
    name: "/myJournals",
    component: myJournals,
  },

  {
    path: "/createJournal",
    name: "/createJournal",
    component: createJournal,
  },
  {
    path: "/createJournalH5",
    name: "/createJournalH5",
    component: createJournalH5,
  },
  {
    path: "/createInformation",
    name: "/createInformation",
    component: createInformation,
  },
];
const router = createRouter({
  history: createWebHistory(),
  base: "/yishi",
  routes,
});

export default router;
